import React from "react";
import googlePlay from '../../../assets/icons/google-play-white-icon.svg';
import appleStore from '../../../assets/icons/apple-store-white-icon.svg';
import img28 from '../../../assets/images/28.webp';
import {
  Content,
  Context,
  AreaTitle,
  Title,
  Description,
  AreaButtons,
  Button,
  Img
} from './styles';

const DownloadApp = () => {

  const openLink = (url: string) => {
    window.open(url, '_blank');
  }

  return (
    <Content id="section-11">
      <Context>
        <AreaTitle>
          <Title>Você também pode encontrar oportunidades fantásticas na Oceano!</Title>
          <Description>BAIXE O APP</Description>
          <AreaButtons>
            <Button
              image={googlePlay}
              onClick={() => openLink('https://play.google.com/store/apps/details?id=oceano.mobile1233')}
            />
            <Button
              image={appleStore}
              onClick={() => openLink('https://apps.apple.com/br/app/oceano/id1629763551')}
            />
          </AreaButtons>
        </AreaTitle>
        <Img src={img28} alt="google-play-apple-store" />
      </Context>
    </Content>
  );
};

export default DownloadApp;
