import React from 'react';
import { Content, Text } from './styles';

interface BadgeProps {
  color: string;
  backgroundColor: string;
  text: string;
}

const Badge = ({ text, color, backgroundColor }: BadgeProps) => {
  return (
    <Content color={backgroundColor}>
      <Text color={color}>{text}</Text>
    </Content>
  );
};

export default Badge;
