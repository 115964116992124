import React, { useEffect, useState } from "react";
import './validation.scss';
import LabelW400H10 from "../label/LabelW400H10";

interface props {
    showValidations: boolean;
    validations: Array<boolean>;
}

const ValidationPassword = ({ showValidations, validations }: props) => {
    const [colors, colorsSet] = useState<Array<string>>(["red", "red", "red", "red", "red", "red"]);
    
    useEffect(() => {
        let x: Array<string> = new Array<string>();
        for (let i = 0; i < validations.length; i++) {
            x[i] = validations[i] ? "blue" : "red";
        }
        colorsSet(x);
    }, [validations]);

    return (
        <>
            {showValidations ? (
                <div className="validationPassword">
                    <LabelW400H10 text={"Deve conter pelo menos 1 caractere maiúsculo;"} color={colors[0]} hasIcon={true} />
                    <LabelW400H10 text={"Deve conter pelo menos 1 caractere minúsculo;"} color={colors[1]} hasIcon={true} />
                    <LabelW400H10 text={"Deve conter pelo menos 1 caractere especial;"} color={colors[2]} hasIcon={true} />
                    <LabelW400H10 text={"Deve conter pelo menos 1 caractere numérico;"} color={colors[3]} hasIcon={true} />
                    <LabelW400H10 text={"Deve conter pelo menos 8 caracteres;"} color={colors[4]} hasIcon={true} />
                    <LabelW400H10 text={"As senhas devem ser iguais."} color={colors[5]} hasIcon={true} />
                </div>

            ) : null}
        </>
    );
}

export default ValidationPassword;