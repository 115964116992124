import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 51.69rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8%;
  background: linear-gradient(to right, #f1eff5 90%, #2c1dfe 10%);

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 903px) {
    height: 70rem;
    margin-top: 0;
    background: #f1eff5;
  }

  @media only screen and (max-width: 714px) {
    height: 78rem;
  }

  @media only screen and (max-width: 500px) {
    height: 61rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 903px) {
    flex-direction: column;
    gap: 5rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  @media only screen and (max-width: 903px) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const ImageContainer = styled.div`
  width: 29.875rem;
  height: 32.875rem;
  overflow: hidden;

  @media only screen and (max-width: 1096px) {
    width: 21.875rem;
    height: 24.875rem;
  }

  @media only screen and (max-width: 903px) {
    width: 29.875rem;
    height: 32.875rem;
  }

  @media only screen and (max-width: 500px) {
    width: 20.875rem;
    height: 22.875rem;
  }

  @media only screen and (max-width: 349px) {
    width: 17.875rem;
    height: 19.875rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
`;
