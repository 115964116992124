import React from "react";

import './policy.scss'

const PolicyText = () => {
    return (
        <div className="policyText">
            <div className="informations">
                <p className="title">INFORMAÇÕES GERAIS</p>
                <p className="content">Esta Política de Privacidade tem como objetivo informar a você, Usuário do Site (
                    <a href="https://www.plataformaoceano.com.br" target="_blank" className="link">www.plataformaoceano.com.br</a>
                    ) e do Aplicativo da Oceano (“Plataforma” ou “Plataforma Oceano”), desenvolvida e provida por PLATAFORMA OCEANO LTDA, inscrita no CNPJ sob o nº 44.576.640/0001-10, com sede na Rod. José Carlos Daux, nº 4150, Salas 01 e 02, Bairro do Saco Grande, na cidade de Florianópolis, Estado de Santa Catarina, CEP 88.032-005 (“Oceano”), acerca de quais informações são coletadas quando da sua utilização da Plataforma, além de como elas são tratadas, em que situações são compartilhadas e quando são excluídas, conforme exigido pela Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).</p>

                <p className="content">A Oceano tem o compromisso com a transparência, a privacidade e a segurança dos dados de seus Usuários durante todo o processo de interação com nossa Plataforma. Os dados cadastrais dos Usuários não são divulgados para terceiros, exceto quando necessários para o processo de entrega, para cobrança, pela legislação vigente ou participação em promoções solicitadas pelos Usuários. A aceitação a esta Política de Privacidade se dará no ato do seu clique no botão “Aceito”, de modo que o Usuário concorda e permite o acesso às suas informações a partir de seu cadastro na Plataforma, manifestando consentimento livre, expresso e informado</p>
                <p className="content emphasis">O USUÁRIO, AO FORNECER SEUS DADOS PESSOAIS, DECLARA CONHECER E ACEITAR ESTA POLÍTICA DE PRIVACIDADE. CASO NÃO CONCORDE COM ALGUMA CLÁUSULA DESTA POLÍTICA OU DOS TERMOS DE USO, NÃO DEVERÁ UTILIZAR QUAISQUER DE NOSSOS SERVIÇOS.</p>
                <p className="content">Esta Política de Privacidade deve ser lida em conjunto com o nosso <a href="https://www.plataformaoceano.com.br/terms" target="_blank" className="link">Termo de Uso</a>
                    . Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a esta Política de Privacidade, entre em contato conosco de segunda à sexta, das 09h às 18h, pelo nosso número/WhatsApp: (11) 4580-2353 ou pelo nosso e-mail: dados@plataformaoceano.com.br.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">1. A QUEM ESSA POLÍTICA DE PRIVACIDADE SE APLICA?</p>
                <p className="content">1.1. Aplica-se a todos os Usuários da Oceano, que de alguma forma tenham dados pessoais tratados por nós.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">2. QUE TIPO DE INFORMAÇÕES PESSOAIS COLETAMOS E UTILIZAMOS?</p>
                <p className="content">2.1. A Oceano coleta e armazena os seguintes tipos de informações:</p>
                <ul className="contentList">
                    <li>
                        <p className="content"><span className="underline">Quando é realizado o cadastro</span> junto à Oceano, demonstrando interesse na contratação dos serviços.</p>
                    </li>
                    <li>
                        <p className="content"><span className="underline">Informações que coletamos diretamente ou indiretamente do Usuário:</span> dentre as informações que podem ser solicitadas estão: (i) para o Prestador de Serviço: nome completo, nome artístico, data de nascimento, sexo, número do celular, e-mail; (ii) para o Contratante e Agências: Razão social, nome fantasia, CNPJ, endereço completo da sede, ramo de atuação, nome completo do representante legal, e-mail, número do celular/WhatsApp. Além de todos os outros dados detalhados nos <a href="https://www.plataformaoceano.com.br/terms" target="_blank" className="link">Termos de Uso</a> da Oceano.</p>
                    </li>
                    <li>
                        <p className="content"><span className="underline">Informações coletadas automaticamente pela Oceano:</span> coletamos e armazenamos determinadas informações sempre que um Usuário interage conosco em algum canal digital. Por exemplo, utilizamos cookies e obtemos algumas informações de navegação quando você acessa a nossa Plataforma; quando você clica em anúncios, e-mails de parceiros, informações de uso, log e dispositivo, informações por tecnologias de rastreamento e outros conteúdos fornecidos por nós em outros sites.</p>
                    </li>
                </ul>
                <p className="content">2.2. O Usuário pode, a qualquer momento, bloquear algumas destas tecnologias para coleta automática de dados através do seu navegador. No entanto, caso essa configuração seja implementada, é possível que algumas das funções pela Plataforma deixem de funcionar corretamente.</p>
                <p className="content">2.3. O Usuário desde já se encontra ciente acerca das informações coletadas por meio da Plataforma e expressa consentimento, livre, expresso e informado com relação à coleta de tais informações.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">3. POR QUE COLETAMOS SEUS DADOS PESSOAIS?</p>
                <p className="content">3.1. A Oceano coleta os dados pessoais do Usuário com a finalidade comercial de negociação, entrega dos serviços, e, atendimento ao Usuário.</p>
                <p className="content">3.1.1. A Oceano considera todas as informações coletadas por meio da Plataforma como confidenciais. Portanto, somente as utilizará da forma descrita e pelo Usuário autorizado.</p>
                <p className="content">3.2. Todas as informações cadastradas e coletadas pela Oceano são utilizadas para a prestação de serviços da Oceano, para melhorar a experiência do Usuário.</p>
                <p className="content">3.3. Coletamos esses dados pessoais para atender, prestar, desenvolver e melhorar os serviços que oferecemos para as seguintes finalidades:</p>
                <ul className="contentList">
                    <li>
                        <p className="content">disponibilizar aos Usuários um ambiente eletrônico por meio do qual os Usuários podem obter mais informações sobre os serviços da Oceano;</p>
                    </li>
                    <p className="content">viabilizar a formalização da compra de um serviço adquirido pela Plataforma Oceano;</p>
                    <li>
                        <p className="content">oferecer funcionalidades, analisar desempenhos, corrigir erros e melhorar a usabilidade e a efetividade dos serviços da Oceano;</p>
                    </li>
                    <p className="content">disponibilizar anúncio de um serviço que o Usuário buscou em nossa Plataforma;</p>
                    <li>
                        <p className="content">fornecer, operar, manter e melhorar os serviços da Oceano;</p>
                    </li>
                    <li>
                        <p className="content">enviar avisos técnicos, atualizações, alertas de segurança e mensagens administrativas e de suporte;</p>
                    </li>
                    <li>
                        <p className="content">fornecer e entregar os serviços e recursos solicitados, processar e concluir transações e enviar informações relacionadas, incluindo confirmações dos serviços;</p>
                    </li>
                    <li>
                        <p className="content">responder aos seus comentários, perguntas e solicitações e fornecer atendimento e suporte ao Usuário;</p>
                    </li>
                    <li>
                        <p className="content">comunicar com você sobre serviços, recursos, pesquisas, boletins informativos, ofertas, promoções, eventos e fornecer outras notícias ou informações sobre a Oceano e nossos parceiros selecionados;</p>
                    </li>
                    <li>
                        <p className="content">monitorar e analisar tendências, uso e atividades relacionadas à Oceano, para fins de marketing ou publicidade;</p>
                    </li>
                    <li>
                        <p className="content">investigar e prevenir transações fraudulentas, acesso não autorizado à Plataforma e outras atividades ilegais;</p>
                    </li>
                    <li>
                        <p className="content">personalizar e melhorar o Serviço e fornecer conteúdo e/ou recursos que correspondam aos seus interesses e preferências ou de outra forma personalizar sua experiência junto à Oceano.</p>
                    </li>
                </ul>
                <p className="content">3.5. A Oceano não dividirá essas informações espontaneamente com o governo ou órgãos públicos, a não ser que seja obrigada a fazê-lo em virtude de lei ou regulamentação aplicável.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">4. QUAIS SÃO OS DIREITOS DO TITULAR DE DADOS?</p>
                <p className="content">4.1. A Oceano terá 15 (quinze) dias para responder às solicitações dos titulares (Usuários). Os pedidos serão analisados conforme previsto em legislação vigente e, por questões legais, algumas solicitações podem não ser atendidas.</p>
                <p className="content">4.2. Os titulares (Usuários) dos dados terão determinados direitos em relação aos seus dados pessoais. Tais direitos incluem, mas não se limitam a:</p>
                <ul className="contentList">
                    <li>
                        <p className="content">Receber informações claras e completas sobre o tratamento de seus dados pessoais, incluindo sobre as hipóteses de compartilhamento de dados pessoais;</p>
                    </li>
                    <li>
                        <p className="content">Solicitar o acesso a seus dados pessoais e/ou confirmação da existência de tratamento de dados;</p>
                    </li>
                    <li>
                        <p className="content">Solicitar que retifiquemos quaisquer dados pessoais imperiosos, incompletos e desatualizados;</p>
                    </li>
                    <li>
                        <p className="content">Se opor às atividades de tratamento, solicitar a anonimização e eliminação de dados pessoais, em circunstâncias específicas;</p>
                    </li>
                    <li>
                        <p className="content">Solicitar a portabilidade de seus dados pessoais;</p>
                    </li>
                    <li>
                        <p className="content">Revogar o consentimento a qualquer momento, quando a Oceano tratar seus dados com base no consentimento;</p>
                    </li>
                    <li>
                        <p className="content">Solicitar a revisão de decisões automatizadas que possam afetar seus interesses.</p>
                    </li>
                </ul>
            </div>
            <div className="informations items">
                <p className="content emphasis">5. COMO EXERCER OS SEUS DIREITOS?</p>
                <p className="content">5.1. Os direitos mencionados acima serão garantidos e poderão ser exercidos pelos Usuários a qualquer tempo. Toda e qualquer solicitação, reclamação, pedido de informação ou exercício de direitos do Titular de Dados poderá ser realizada de segunda à sexta, das 09h às 18h, pelo nosso número/WhatsApp: (11) 4580-2353 ou pelo nosso e-mail: dados@plataformaoceano.com.br.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">6. OS DADOS PESSOAIS SÃO TRATADOS DE ACORDO COM AS BASES LEGAIS PREVISTAS EM LEI, SENDO ELAS:</p>
                <ul className="contentList">
                    <li>
                        <p className="content">Cumprimento das obrigações legais e regulatórias;</p>
                    </li>
                    <li>
                        <p className="content">Exercício regular de direitos;</p>
                    </li>
                    <li>
                        <p className="content">Execução dos contratos firmados com seus clientes;</p>
                    </li>
                    <li>
                        <p className="content">Interesses legítimos da Oceano, sem ferir os direitos e liberdades fundamentais, respeitando a legítima expectativa do nosso Usuário;</p>
                    </li>
                    <li>
                        <p className="content">Consentimento do nosso Usuário.</p>
                    </li>
                </ul>
            </div>
            <div className="informations items">
                <p className="content emphasis">7. POR QUANTO TEMPO GUARDAMOS OS DADOS PESSOAIS?</p>
                <p className="content">7.1. A Oceano encerra o tratamento de dados pessoais do Usuário assim que verificado o alcance da finalidade para o qual foi coletado ou quando os dados deixam de ser necessários ou pertinentes ao alcance da finalidade específica.</p>
                <p className="content">7.2. Se houver alguma justificativa legal ou regulatória, os dados poderão continuar armazenados ainda que a finalidade para a qual foram coletados ou tenham sido tratados tenha se exaurido.</p>
                <p className="content">7.3. Uma vez finalizado o tratamento, observadas as disposições desta seção, os dados são apagados ou anonimizados.</p>
                <p className="content">7.4. A Oceano empreenderá os melhores esforços para atender a todos os pedidos de exclusão, no menor espaço de tempo possível. Tal exclusão, no entanto, removerá também o cadastro do Usuário da Plataforma, que não conseguirá mais acessá-lo.</p>
                <p className="content">7.5. A pedido do Usuário, a Oceano eliminará ou anonimizará os seus dados pessoais de modo que não o identifiquem, exceto se for legalmente permitido ou obrigatório manter determinados dados pessoais.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">COM QUEM COMPARTILHAMOS SEUS DADOS?</p>
                <p className="content">8.1. <span className="underline">Prestadores de Serviços:</span> Podemos empregar empresas e indivíduos terceirizados para administrar e prestar serviços em nosso nome (como empresas que fornecem suporte ao cliente, empresas que contratamos para hospedar, gerenciar, manter e desenvolver nosso aplicativo, sistemas de TI). Esses terceiros podem usar suas informações apenas como indicado pela Oceano e de maneira consistente com esta Política de Privacidade, e são proibidos de usar ou divulgar suas informações para qualquer outra finalidade. Adicionalmente, dados como 	“IP” (Internet Protocol ou Protocolo de Internet) e informações de acesso são enviadas para os serviços de servidor da Plataforma.</p>
                <p className="content">8.2. <span className="underline">Consultores Profissionais:</span> A Oceano poderá divulgar seus dados pessoais para consultores profissionais, como advogados, auditores e seguradoras, sempre que necessário, no decorrer dos serviços profissionais que eles prestem a nós.</p>
                <p className="content">8.3. <span className="underline">Conformidade com as leis e o cumprimento das leis; proteção e segurança:</span> A Oceano poderá divulgar informações sobre o Usuário a autoridades governamentais ou policiais (incluindo autoridades fiscais) ou a entidades privadas, conforme exigido por lei, e divulgar e usar as informações que consideramos necessárias ou adequadas para (a) cumprir as leis aplicáveis, solicitações legais e processos legais, como responder a intimações ou solicitações de autoridades governamentais; (b) cumprir os termos e condições que regem nossos serviços; (c) proteger nossos direitos, privacidade, segurança ou propriedade, e/ou do Usuário ou de outros; e (d) proteger, investigar e impedir atividades fraudulentas, prejudiciais, não autorizadas, antiéticas ou ilegais.</p>
                <p className="content">8.4. <span className="underline">Transferência de Negócios:</span> A Oceano poderá vender ou transferir alguns ou todos os seus negócios ou ativos, incluindo seus dados pessoais, em relação a uma transação comercial (ou transação comercial potencial), como uma fusão, consolidação, aquisição, reorganização ou venda de ativos ou no caso de falência, em cujo caso faremos esforços consideráveis para exigir que o destinatário honre esta Política de Privacidade.</p>
                <p className="content">8.5. <span className="underline">Compartilhamento de Dados Sensíveis.</span> A Oceano pode compartilhar informações que coleta, inclusive seus dados sensíveis, com parceiros comerciais. O compartilhamento das informações ocorrerá de forma anônima, sempre que for possível, visando preservar a privacidade do Usuário. Por meio deste documento, o Usuário autoriza expressamente tais compartilhamentos.</p>
                <p className="content">8.6. Atualmente, a Oceano compartilha informações com as referidas empresas para as seguintes finalidades:</p>


                <ul className="contentList">
                    <li>
                        <p className="content"><span className="content emphasis">KONATUS SOLUÇÕES INTELIGENTES LTDA</span>, inscrita no CNPJ sob o nº 10.501.202/0001-70, com sede na Av. São João, nº 2375, sala 2301, São José dos Campos/SP: empresa responsável pelo desenvolvimento do software.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">WENZ INOVAÇÃO EM TECNOLOGIA LTDA-ME</span>, inscrita no CNPJ sob o nº 21.169.992/0001-55, com sede na Rua Engenheiro Pegado, nº 945, Vila Carrão, São Paulo/SP: empresa de suporte e infraestrutura.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">PAGAR.ME PAGAMENTOS S.A</span>, inscrita no CNPJ sob o nº 18.727.053/0001-74, com sede na Rua Fidêncio Ramos, nº 308 Torre A, conj 91 , Vila Olimpia, São Paulo/SP: empresa responsável pelo Getway de pagamentos.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">WEDEV SOLUTIONS</span>, inscrita no CNPJ sob o n. 45.422.718/0001-04, com sede na Rua Bulevar Villa Lobos, 41 – Apto 33D – Jardim Aquarius, cidade de São José dos Campos/SP: empresa responsável pelo desenvolvimento do software.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">ENDURANCE GROUP BRASIL HOP DE SITES LTDA</span>, inscrita no CNPJ 15.754.475/0001-40, com sede na Rua Lauro Linhares, 589, Atico, Bairro de Trindade, Florianópolis/SC: empresa responsável pela hospedagem do site da Plataforma Oceano.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">AMAZON AWS SERVIÇOS BRASIL LTDA</span> – inscrito no CNPJ: 23.412.247/0001-10, com sede na Av. Presidente Juscelino Kubitschek 2041, andar 18 e 19, Itaim Bibi, São Paulo/SP, empresa responsável pela hospedagem da plataforma.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">GITLAB Inc</span>, 268 Bush Street #350, San Francisco, CA 94104-3503, EUA, empresa responsável pela hospedagem de código da plataforma.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">WORDPRESS</span>, 60 29 th Street # 343, San Francisco, CA 94110, EUA, gestão de conteúdo da internet.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">GOOGLE</span>, localizado em 1600 Amphitheatre Parkway, Mountain View California 94043, EUA, empresa responsável pela hospedagem do aplicativo.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">APPLE SERVICES LATAM LLC</span>, localizada em 1 Alhambra Plaza, Suite 700 Coral Gables, Flórida, empresa responsável pela hospedagem do aplicativo.</p>
                    </li>
                    <li>
                        <p className="content"><span className="content emphasis">SOFTCON CONTABILIDADE E CONS SS EPP</span>, inscrita no CNPJ: 08.001.014/0001-31, com sede na Rua Idalina Pereira dos Santos, 67, sala 1205, 1206 Bairro de Agronomica, Florianópolis/SC: empresa responsável pela escrituração contábil, fiscal e folha de pagamentos mensais.</p>
                    </li>
                </ul>
            </div>
            <div className="informations items">
                <p className="content emphasis">9. CRIANÇAS PODEM COMPRAR NA OCEANO?</p>
                <p className="content">9.1. A Oceano foi idealizada para Usuários maiores de idade.</p>
                <p className="content">9.2. A utilização da Plataforma só será permitida para Usuários pessoas físicas e jurídicas, maiores de 18 (dezoito) anos e plenamente capaz no ambiente civil. Caso o Usuário não esteja de acordo com o exigido, não deverá prosseguir com a criação da Conta de Acesso ou utilizar seus serviços sem a presença de um responsável legal, sob pena de ser caracterizada má-fé.</p>
                <p className="content">9.2.1. Todos aqueles que não possuírem plena capacidade civil - menores de 18 anos, pródigos, ébrios habituais ou viciados em tóxicos e pessoas que não puderem exprimir sua vontade, por motivo transitório ou permanente - deverão ser devidamente assistidos por seus representantes legais, que se responsabilizarão pelo cumprimento das presentes regras.</p>
                <p className="content">9.3. A Oceano não coleta ou solicita intencionalmente informações pessoais de menores e o Usuário declara e garante que não está fornecendo nenhuma informação sobre um menor.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">10. ONDE SÃO ARMAZENADAS AS INFORMAÇÕES DOS DADOS PESSOAIS E QUAIS SÃOS AS FINALIDADES?</p>
                <p className="content">10.1. Todos os dados pessoais dos Usuários coletados por nós são armazenados no banco de dados da Plataforma, sendo hospedados na <span className="content emphasis">AMAZON AWS SERVIÇOS BRASIL LTDA</span> – inscrita no CNPJ: 23.412.247/0001-10, localizada na Av. Presidente Juscelino Kubitschek 2041, andar 18 e 19, Itaim Bibi, São Paulo/SP.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">11. COOKIES E TECNOLOGIAS DE RASTREAMENTO.</p>
                <p className="content">11.1. Cookies são pequenos arquivos de dados que são colocados no seu computador ou em outros dispositivos (como 'smartphones' ou 'tablets') enquanto você navega na Plataforma.</p>
                <p className="content">11.2. Utilizamos cookies, pixels e outras tecnologias (coletivamente, "cookies") para reconhecer seu navegador ou dispositivo, aprender mais sobre seus interesses, bem como fornecer recursos e serviços essenciais, além de melhorar sua experiência em nossa Plataforma para:</p>
                <ul className="contentList">
                    <li>
                        <p className="content">acompanhar suas preferências para enviar somente anúncios de seu interesse. Você pode definir suas preferências por meio de sua conta;</p>
                    </li>
                    <li>
                        <p className="content">acompanhar os itens armazenados das suas transações;</p>
                    </li>
                    <li>
                        <p className="content">realização de pesquisas e diagnósticos para melhorar o conteúdo e serviços;</p>
                    </li>
                    <li>
                        <p className="content">impedir atividades fraudulentas;</p>
                    </li>
                    <li>
                        <p className="content">melhorar a segurança.</p>
                    </li>
                </ul>
                <p className="content">11.3 Lembramos que você pode, a qualquer momento, ativar em seu navegador mecanismos para informá-lo quando os cookies estiverem acionados ou, ainda, impedir que sejam ativados, através das preferências do seu navegador, mas com isso você pode não conseguir concluir o cadastro.</p>
                <p className="content">11.4. A Oceano também pode utilizar de tecnologias como beacons, tags e scripts para analisar tendências, administrar a Plataforma, rastrear os movimentos dos Usuários na Plataforma e coletar informações demográficas sobre nossa base de Usuários como um todo.</p>
                <p className="content">11.5. A Oceano pode receber relatórios com base no uso dessas tecnologias por essas empresas de forma individual e agregada.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">12. SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS.</p>
                <p className="content">12.1. A Oceano observa todas as normas aplicáveis às medidas de segurança da informação pessoal e emprega os padrões mais avançados em matéria de proteção da confidencialidade destas informações. Ainda assim, é necessário considerar que a segurança perfeita não existe na Internet. Portanto, a Oceano não será responsável por interceptações ilegais ou violação de seus sistemas ou bases de dados por parte de pessoas não autorizadas e tampouco se responsabiliza pela indevida utilização da informação obtida por esses meios.</p>
                <p className="content">12.2. Todos os dados pessoais informados à nossa Plataforma são armazenados em um banco de dados reservado e com acesso restrito a pessoas habilitadas, que são obrigadas, por contrato, a manter a confidencialidade das informações e não as utilizar inadequadamente.</p>
                <p className="content">12.3. A Oceano utiliza o HTTPS, para garantir que os dados pessoais transmitidos entre o dispositivo do Usuário e a Plataforma Oceano sejam completamente criptografadas.</p>
                <p className="content">12.4. A Oceano utiliza o certificado SSL da Let's Encrypt, RSA 4096, ISRG Root X1, para garantir que os dados pessoais transmitidos entre o dispositivo do Usuário e a Plataforma sejam completamente criptografadas.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">13. TRANSFERÊNCIA INTERNACIONAL DE DADOS.</p>
                <p className="content">13.1. A Oceano poderá armazenar seus dados em servidores de computação em nuvens localizados fora do Brasil, observando todos os requisitos estabelecidos pela lei 13.709/18 (Lei Geral de Proteção de Dados - LGPD) e sempre busca as melhores e mais modernas práticas de segurança da informação.</p>
                <p className="content">13.2. Assim, ao concordar com esta Política de Privacidade, o Usuário concorda também que os dados pessoais podem estar sujeitos a transferências internacionais e se declara ciente de todas as garantias aplicáveis ao tratamento no exterior, conforme esta Política de Privacidade.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">14. COMO ENTRAR EM CONTATO COM O ENCARREGADO DA PROTEÇÃO DE DADOS PESSOAIS?</p>
                <p className="content">14.1. O encarregado da proteção de dados é o responsável escolhido pela Oceano para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD). Qualquer dúvida poderá ser encaminhada para: Alessandra Galo, no e-mail alessandra.galo@plataformaoceano.com.br</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">15. ALTERAÇÕES DESTA POLÍTICA DE PRIVACIDADE.</p>
                <p className="content">15.1. A presente versão desta Política de Privacidade foi atualizada pela última vez em <span className="content emphasis">11 de Maio de 2022</span>.</p>
                <p className="content">15.2. A Oceano se reserva o direito de modificar, a qualquer momento esta Política de Privacidade, especialmente para adaptar às evoluções da Plataforma Oceano, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes ou em virtude de alterações na legislação. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na Plataforma.</p>
                <p className="content">15.3. Sempre que ocorrer qualquer modificação nesta Política de Privacidade, a Oceano notificará na Plataforma ao Usuário, que a Política foi modificada. Devendo o Usuário concordar com ela para continuar acessando a Plataforma.</p>
                <p className="content">15.4. Ao utilizar o serviço após eventuais modificações, o Usuário demonstra sua concordância com a nova Política. Caso discorde de alguma das modificações, o Usuário deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">16. DO DIREITO APLICÁVEL E DO FORO.</p>
                <p className="content">16.1. Essa Política de Privacidade é regida pelas leis da República Federativa do Brasil.</p>
                <p className="content">16.2. Todas as controvérsias desta Política de Privacidade serão solucionadas pelo foro da Comarca de São Paulo/SP - Brasil, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
            </div>
            <div className="informations items">
                <div>
                    <p className="content emphasis">DADOS DE CONTATO</p>
                    <p className="content emphasis">PLATAFORMA OCEANO LTDA</p>
                    <p className="content">CNPJ sob o nº 44.576.640/0001-10</p>
                    <p className="content">Escritório localizado na Av. Ibirapuera, n. 2907, Sala 1211 - Bairro Moema, São Paulo/SP.</p>
                    <p className="content">Telefone/WhatsApp: (11) 4580-2353</p>
                    <p className="content">E-mail: dados@plataformaoceano.com.br</p>
                    <p className="content">Horários de Atendimento: Segunda à Sexta: Das 09h às 18h.</p>
                </div>
            </div>
        </div>
    );
}

export default PolicyText;