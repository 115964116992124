import React from "react";

import './policy.scss'

interface props {
    callback: any;
}

const TermText = ({ callback }: props) => {
    const change = (page: number) => {
        window.scrollTo(0, 0);
        callback(page);
    }
    return (
        <div className="policyText">
            <div className="informations">
                <p className="title">CONTRATANTE</p>
                <p className="content">Bem-vindo(a) à Plataforma Oceano!</p>
                <p className="content">Ficamos felizes por você estar aqui e esperamos que você aproveite tudo o que temos para oferecer.</p>
                <p className="content">A seguir apresentamos a você os Termos de Uso (“Termos” ou “Termos de Uso” ou “Instrumento”), documento que relaciona as principais regras a serem observadas por todos que acessam e utilizam as funcionalidades do Site (<a href="https://www.plataformaoceano.com.br" target="_blank" className="link">“Plataforma Oceano”</a>).</p>
                <p className="content emphasis">Leia estes Termos de Uso com atenção, pois são um contrato vinculativo entre você e a Plataforma Oceano.</p>
                <p className="content">Por este instrumento e por estarem de acordo com os termos que se seguem, tendo por Partes, de um lado, <span className="content emphasis">PLATAFORMA OCEANO LTDA</span>, inscrita no CNPJ sob o nº 44.576.640/0001-10, com sede na Rod. José Carlos Daux, nº 4150, Salas 01 e 02, Bairro do Saco Grande, Florianópolis/SC, CEP 88.032-005, chamada de “Oceano”, de outro lado, o “Contratante”, “Profissional” e/ou “Agência” (“Usuário”) , que acessam, utilizam e/ou se cadastram na Plataforma.</p>
                <p className="content">Esta Plataforma e todo o seu conteúdo é controlado e operado pela Oceano, sendo, portanto, todos os direitos reservados.</p>
                <p className="content">Caso queira nos dar algum feedback sobre a nossa Plataforma, em caso de dúvidas ou qualquer assunto relacionado a este Termo de Uso, entre em contato conosco pelo nosso número/WhatsApp (11) 4580-2353 ou pelo nosso e-mail dados@plataformaoceano.com.br, nos horários de segunda à sexta, das 09h às 18h.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">1. ACEITAÇÃO</p>
                <p className="content">1.1. Qualquer pessoa, física ou jurídica, que acessa, utiliza e se cadastra na Plataforma Oceano deverá aceitar estes Termos de Uso e todas as demais políticas e princípios da Oceano. O Usuário deverá ler, certificar-se de que entendeu estes Termos de Uso e aceitar todas as condições estabelecidas nele antes de se cadastrar na Plataforma Oceano ou de utilizá-la.</p>
                <p className="content emphasis">1.2. A aceitação destes Termos de Uso é indispensável à utilização da Plataforma. Cada Usuário se responsabiliza integralmente por todos e quaisquer atos que praticar na Plataforma Oceano, bem como as informações que prestar ou deixar de prestar. Caso o Usuário não concorde com qualquer das cláusulas dos termos e condições abaixo estabelecidos, não deve utilizar a Plataforma Oceano.</p>
                <p className="content">1.3. Se você, na qualidade de Usuário, não concordar com os Termos de Uso, você não está autorizado a acessar ou utilizar os Serviços oferecidos pela Oceano. A utilização dos Serviços está expressamente condicionada ao consentimento do Usuário às regras dos Termos de Uso.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">2. OBJETO</p>
                <p className="content">2.1. Estes Termos de Uso definem as regras a serem seguidas pelo Usuário que acessa e/ou utiliza a Plataforma Oceano.</p>
                <p className="content">2.2. Os termos aqui descritos não anulam nem revogam outros termos disponíveis ao Usuário em qualquer aplicação da Oceano e são complementares às condições específicas de eventual outra contratação entre o Usuário e a Oceano.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">3. SERVIÇOS</p>
                <p className="content">3.1. A Plataforma Oceano é um Marketplace de Serviços, que busca integrar o mundo da moda e da comunicação com trabalhos e contratações entre modelos fashion, modelos comerciais, atores, locutores, apresentadores, influenciadores digitais,  e outros profissionais do segmento, tais como: produtoras de vídeos, produtoras de castings, agências de publicidade e clientes diretos, além de agentes , agências de modelos e talentos, produtores, e bookers que se cadastram na Plataforma Oceano para cadastrar seus profissionais em trabalhos publicados na nossa Plataforma, além da possibilidade de publicar determinados trabalhos para sua própria empresa.</p>
                <p className="content">3.2. A Plataforma Oceano oferece os seguintes serviços (“<span className="underline">Serviços</span>”):</p>
                <p className="content">3.2.1. <span className="underline">Intermediação de Serviços</span>. A Plataforma Oceano realiza o serviço de intermediação, visando viabilizar o contato direto entre os Usuários da Plataforma, através da conexão e divulgação das informações de um perfil ao outro.</p>
                <p className="content">3.2.2. <span className="underline">Espaço para Anúncios do Contratante</span>. A Plataforma Oceano disponibiliza um espaço virtual na Plataforma para que o Contratante (produtoras de vídeos, produtoras de castings, agências de publicidade e clientes diretos), agências (agentes, bookers, produtores) utilizem a Plataforma Oceano para anunciarem oportunidade de contratação para a sua Campanha, especificando o perfil compatível com o anúncio ofertado, além de horário, duração, local da prestação do serviço, remuneração e qualquer outra especificação necessária para a realização do serviço.</p>
                <p className="content">3.2.3. <span className="underline">Espaço para <button className="changeTerm" onClick={() => change(3)}>Perfil do Profissional</button></span>. A Plataforma Oceano disponibiliza um espaço virtual na Plataforma para que os Prestadores de Serviços (também chamados de “<span className="underline">Profissionais</span>”) utilizem a Plataforma para cadastrarem seu perfil, descrevendo suas habilidades profissionais e suas áreas de atuação no mercado, cujo detalhamento o conectará às oportunidades de trabalho dentro do seu perfil.</p>
                <p className="content">3.2.4. <span className="underline">Espaço para Agência, <button className="changeTerm" onClick={() => change(2)}>Produtores, Bookers e Agentes</button></span>. A Plataforma Oceano disponibiliza um espaço virtual na Plataforma para que a agência, produtor, booker e/ou agente (“Agência”) cadastre o seu Casting de Profissionais. A Agência pode candidatar seus Profissionais para trabalhos disponíveis na Plataforma Oceano e/ou contratar Profissionais cadastrados na Plataforma para trabalhos disponíveis na sua própria <span className="underline">Agência</span>.</p>
                <p className="content">3.3. Na qualidade de Marketplace de Serviços, a Oceano é intermediadora e não impõe ou interfere em qualquer negociação sobre condição, valor, qualidade, forma ou prazo na contratação entre os Usuários, tampouco garante a qualidade, ou entrega dos serviços contratados entre eles.</p>
                <p className="content">3.4. A Plataforma Oceano não se responsabiliza acerca dos trâmites da contratação entre os Usuários, seja ela de curta ou longa duração.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis"></p>
                <p className="content">4. INFORMAÇÕES GERAIS SOBRE A PLATAFORMA OCEANO</p>
                <p className="content">4.1. A Plataforma Oceano é um ambiente digital que permite a intermediação entre os Usuários, oferecendo uma experiência de navegação única.</p>
                <p className="content">4.2. Para acessar a Plataforma Oceano de forma segura e utilizar suas funcionalidades de forma integral, o Usuário deverá dispor de dispositivos e equipamentos compatíveis, serviço de conexão à Internet com antivírus e firewall habilitados, softwares devidamente atualizados, além da adoção de medidas de segurança cibernética mínimas, como o uso de senha forte.</p>
                <p className="content">4.3. A Plataforma e suas funcionalidades são apresentadas ao Usuário na maneira como estão disponíveis, podendo passar por constantes aprimoramentos e atualizações, de forma que a Oceano se compromete a:</p>
                <ol className="contentList" type="I">
                    <li>
                        <p className="content">Preservar o bom funcionamento da Plataforma, com o uso de links funcionais e layout que respeita a usabilidade e navegabilidade, sempre que possível;</p>
                    </li>
                    <li>
                        <p className="content">Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas; e</p>
                    </li>
                    <li>
                        <p className="content">Garantir, por meio do estado da técnica disponível, o sigilo dos dados, somente acessíveis por pessoas ou empresas autorizadas (estas últimas obrigatoriamente parceiros de negócios da Oceano) pela Oceano, além de si próprio.</p>
                    </li>
                </ol>
            </div>
            <div className="informations items">
                <p className="content emphasis">5. CADASTRO</p>
                <p className="content">5.1. Para utilizar os Serviços oferecidos pela Plataforma Oceano é necessário que o Contratante (produtoras de vídeos, produtoras de castings, clientes diretos, agências de publicidade) realize um cadastro e aceite as regras expressas nestes Termos de Uso, disponível em: <a href="https://www.plataformaoceano.com.br" target="_blank" className="link">www.plataformaoceano.com.br</a>.</p>
                <p className="content">5.2. Ao se cadastrar na Plataforma, o Contratante cria automaticamente uma conta em seu nome (“<span className="underline">Conta de Acesso</span>”). No ato do cadastro o Contratante responderá um questionário, no qual serão fornecidas as seguintes informações:</p>
                <p className="content">5.2.1. <span className="underline">Cadastro do Contratante</span>. Para se cadastrar na Plataforma Oceano o Contratante deve fornecer os seguintes dados: nome fantasia da empresa; razão social, CNPJ, nome completo do representante legal, endereço completo da sede, ramo de atuação, telefone e e-mail. Vai precisar criar uma senha e deve confirmá-la.</p>
                <p className="content">5.2.1.1. Para o cadastro do anúncio do trabalho, o Contratante terá que fornecer no mínimo as seguintes informações:</p>
                <ul className="contentList">
                    <li>
                        <p className="content">Dados descritivos sobre o tipo do trabalho, qual o segmento, título do trabalho, descrição, fotos de referência, verba, seu tipo de verba, data limite de aplicação, período de veiculação (inicial e final), qual o local da veiculação, mídias/meio onde será veiculado o trabalho, limite da aplicação, local de veiculação, se o trabalho requer exclusividade, data e horário, endereço do casting se necessário, endereço do trabalho caso seja presencial, exceto o nome da empresa da Campanha, marcas, nome de produtos, etc., se é necessário o envio de fotos dos candidatos durante a etapa de edição;</p>
                    </li>
                    <li>
                        <p className="content">Dados necessários para o perfil do Profissional para o trabalho:</p>
                        <p className="content">- Características físicas do Profissional: sexo; idade; altura; peso; busto/tórax; cintura; quadril; manequim (tamanho); sutiã (tamanho); calçados (tamanho); terno (tamanho); camisa (tamanho); pescoço (tamanho); calcinha/cueca (tamanho); cor do cabelo; tipos de cabelo; comprimento do cabelo; cor da pelo; cor dos olhos; formas físicas; cicatrizes; sardas; tatuagens; vitiligo; piercing; pelos; silicone; estrias; deficiência aparente; hemangioma; e; prótese mecânica.</p>
                        <p className="content">- Habilidades do Profissional: Canto; instrumentos musicais; dança; esportes; aptidões; idiomas e nível de fluência (básico, intermediário ou avançado).</p>
                    </li>
                </ul>
                <p className="content">5.3. A Conta de Acesso e a senha criada para acesso à Plataforma pelo Contratante são únicas, pessoais e intransferíveis. Da mesma forma, os dados, informados durante o cadastro somente poderão ser associados a um único Usuário. Atualmente, na nossa Plataforma só será aceito uma conta para cada usuário, ou seja, não disponibilizamos mais de um acesso por usuário.</p>
                <p className="content">5.3.1. O Usuário é responsável pela guarda e sigilo da sua própria senha. Caso venha a esquecer sua senha, uma nova senha poderá ser criada para acesso à Plataforma mediante solicitação informando o endereço de e-mail utilizado no cadastro inicial do Usuário na Plataforma. Uma senha antiga não poderá ser recuperada por solicitação do Usuário.</p>
                <p className="content">5.4. A utilização da Plataforma é permitida para Usuários pessoa jurídica e pessoas físicas maiores de 18 (dezoito) anos e plenamente capaz no ambiente civil. Caso o Usuário não esteja de acordo com o exigido, não deverá prosseguir com a criação da Conta de Acesso ou utilizar seus serviços, sob pena de ser caracterizada má-fé.</p>
                <p className="content">5.5. Ao realizar o cadastro, o Usuário:</p>
                <ol className="contentList" type="I">
                    <li>
                        <p className="content">deverá oferecer informações verdadeiras sobre si ou da pessoa legalmente autorizada a aceitar este Termo de Uso, sempre que solicitado pela Oceano.  Caso as informações enviadas não sejam verdadeiras, a Oceano se reserva no direito de cancelar o cadastro do Usuário a qualquer momento, sem a necessidade de aviso prévio.</p>
                    </li>
                    <li>
                        <p className="content">declara que as informações fornecidas no momento do cadastro são corretas, completas e verdadeiras e compromete-se a sempre manter tais informações atualizadas, responsabilizando-se por qualquer prejuízo decorrente da falsidade dessas informações. Em qualquer caso, o Usuário responderá em âmbito cível e criminal, pela veracidade, exatidão e autenticidade dos dados informados, bem como será responsabilizado pelos atos ou omissões dos Usuário por ele designados.</p>
                    </li>
                    <li>
                        <p className="content">A Oceano, em hipótese alguma, será responsável pela veracidade das informações que o Usuário disponibilizar. Todas as informações estão sujeitas às medidas de segurança que impeçam o acesso, o uso e a divulgação não autorizados. Para saber mais sobre a forma que a Oceano coleta e processa suas informações, por favor, acesse a nossa <a href="/policy" className="link">Política de Privacidade.</a></p>
                    </li>
                </ol>
                <p className="content">5.6. A Oceano se reserva o direito de verificar, a qualquer momento, a veracidade das informações fornecidas pelo Usuário, podendo solicitar, a seu exclusivo critério, esclarecimentos e eventual apresentação de documentação suplementar que julgar necessária para a comprovação das informações prestadas, bem como a validação do cadastro, podendo, inclusive, se recusar a validar qualquer cadastro sem qualquer justificava. Caso o Usuário se recuse a prestar os esclarecimentos ou apresentar os documentos adicionais solicitados pela Oceano, sua conta será cancelada e esses Termos de Uso serão automaticamente rescindidos.</p>
                <p className="content">5.7. Caso a Oceano detecte alguma Conta de Acesso criada a partir de informações falsas, incompletas, equivocadas, errôneas, enganosas, ou, ainda, não possa detectar a identidade do Usuário, essa conta poderá ser suspensa, a critério da Oceano, até que a situação se regularize, de forma que o acesso do Usuário à Plataforma e aos Serviços ficarão bloqueados enquanto durar tal suspensão. A Oceano enviará comunicação sobre este bloqueio em até 24 (vinte e quatro) horas após tal medida, através do e-mail cadastrado.</p>
                <p className="content emphasis">5.7.1. A OCEANO NÃO SERÁ RESPONSÁVEL POR ACESSOS À PLATAFORMA REALIZADOS POR TERCEIROS COM AS INFORMAÇÕES OU SENHA DO USUÁRIO, BEM COMO POR QUALQUER DANO DIRETO OU INDIRETO, RESULTADO DO MAU USO OU DA INABILIDADE DO USO DO PELO USUÁRIO OU POR QUAISQUER TERCEIROS. TAL RESPONSABILIDADE É APLICÁVEL APENAS AO USUÁRIO.</p>
                <p className="content">5.8. A Oceano deverá ser prontamente contatada em caso de perda, extravio ou suspeita de utilização indevida da Conta de Acesso, para que as medidas adequadas sejam adotadas. Este contato deverá ocorrer através do e-mail: dados@plataformaoceano.com.br</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">6. CANCELAMENTO E SUSPENSÃO DO CADASTRO</p>
                <p className="content">6.1. O Usuário tem o direito de solicitar o cancelamento do seu cadastro na Plataforma a qualquer momento. Toda e qualquer solicitação de cancelamento, atualmente será feita através de solicitação por e-mail: dados@plataformaoceano.com.br</p>
                <p className="content">6.1.1. O Usuário está ciente e concorda que o cancelamento do cadastro implica no cancelamento de seu acesso à Plataforma e da utilização dos Serviços oferecidos.</p>
                <p className="content">6.1.2 A solicitação de cancelamento é irreversível e importa na imediata exclusão de todas as informações disponibilizadas na conta do Usuário, conforme o caso, incluindo histórico de navegação e dados pessoais, mas não se limitando a esses, exceto as informações legais necessárias que a Oceano seja obrigada a guardar por força de Lei ou por força dos contratos assinados junto aos seus parceiros de negócio.</p>
                <p className="content">6.1.3 A Oceano não se responsabiliza por qualquer dano ao Usuário oriundo do cancelamento do cadastro.</p>
                <p className="content">6.2. A Oceano também se reserva o direito de suspender ou cancelar, a qualquer momento, o cadastro do Usuário, bem como seu acesso e uso da Plataforma, em caso de suspeita de fraude, falsidade no conteúdo das informações, obtenção de benefício ou vantagem de forma ilícita, má utilização ou uso inadequado dos serviços ou para fins ilícitos, bem como pelo não cumprimento de quaisquer condições previstas neste Termos de Uso ou na legislação aplicável.</p>
                <p className="content">6.3. Nesses casos, não será devida qualquer indenização ao Usuário, e a Oceano poderá promover a competente ação de regresso (ação judicial com o fim de pedir a restituição dos custos assumidos pela Oceano), se necessário, bem como tomar quaisquer outras medidas necessárias para perseguir e resguardar seus interesses.</p>
                <p className="content">6.4. A Oceano reserva-se o direito de, a seu critério, a qualquer tempo e sem qualquer comunicação prévia ao Usuário, descontinuar de forma definitiva ou temporária os Serviços disponibilizados por meio da Plataforma. Nesses casos, nenhuma indenização será devida ao Usuário, a não ser as já previstas neste Termo.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">7. PROPRIEDADE INTELECTUAL</p>
                <p className="content">7.1. Todos os direitos de propriedade intelectual relativos à Plataforma, bem como todas as suas funcionalidades, são de propriedade exclusiva da Oceano, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos análogos produzidos direta ou indiretamente pela Oceano (“<span className="underline">Conteúdo da Oceano</span>”).</p>
                <p className="content">7.1.1. Inclui-se nesse dispositivo o uso da marca “Plataforma Oceano”, nome empresarial ou nome de domínio, além das telas da Plataforma, assim como os programas, banco de dados, redes, arquivos que permitem que o Usuário acesse sua conta, todos de propriedade da Oceano e protegidos pelas leis e tratados internacionais de direito autoral e propriedade industrial.</p>
                <p className="content">7.1.2. O Conteúdo da Oceano é protegido pelas leis de direitos autorais e de propriedade industrial. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo da Oceano, para qualquer finalidade, sem o consentimento prévio e expresso da Oceano. Qualquer uso não autorizado do Conteúdo da Plataforma Oceano será considerado como violação de seus direitos autorais e de propriedade industrial da Oceano.</p>
                <p className="content">7.1.3. É vedado ao Usuário incluir na Plataforma Oceano dados que possam alterar seu conteúdo original, modificando o seu conteúdo ou sua aparência. Caso isso aconteça, cabe ao Usuário a comunicação imediata, através do e-mail dados@plataformaoceano.com.br. Não formalizada a ocorrência, a Oceano tomará as providencias cabíveis, além da exclusão do usuário.</p>
                <p className="content">7.2. Todos os feedbacks, opiniões, sugestões de melhoria ou outras ideias fornecidas pelo Usuário à Oceano, não conferirão qualquer titularidade sobre os direitos de propriedade intelectual ao Usuário, mesmo que as sugestões sejam eventualmente implementadas. Assim, todas as melhorias, opiniões, sugestões, ideias, comentário, feedbacks são desde já cedidos pelo Usuário à Oceano, de forma gratuita, irrevogável, irretratável, total, perpétua, sem que seja devido ao Usuário qualquer remuneração, reconhecimento, pagamento ou indenização.</p>
                <p className="content">7.3. A Oceano reserva-se o direito de, a seu critério e a qualquer tempo, alterar ou remover funcionalidades da Plataforma que não estejam alinhadas com seus interesses, bem como adicionar novas funcionalidades que tragam benefícios à utilização da Plataforma pelo Usuário, sem qualquer comunicação prévia e sem que seja devido ao Usuário qualquer indenização.</p>
                <p className="content">7.4. A Oceano poderá, a seu exclusivo critério, durante a vigência destes Termos de Uso, atualizar a Plataforma com o objetivo de preservar ou aperfeiçoar suas características funcionais.</p>
                <p className="content">7.4.1. Nesse caso, suspensões temporárias nos Serviços poderão acontecer, sem que seja devida qualquer indenização ao Usuário. Todo e qualquer desenvolvimento, arranjo, melhoria ou alteração da Plataforma ou de suas funcionalidades, realizados pela Oceano, ainda que por sugestão ou solicitação do Usuário, serão considerados de titularidade da Oceano de forma que esta poderá empregá-los livremente e adaptá-los, aperfeiçoá-los, transformá-los, distribuí-los, comercializá-los, levá-los a registro, cedê-los e licenciá-los, a qualquer título e a seu exclusivo critério, sem limitação.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">8. RESPONSABILIDADES DA OCEANO</p>
                <p className="content">8.1. Tendo em vista as características inerentes ao ambiente da internet, o Usuário reconhece que a Oceano não se responsabiliza pelas falhas na Plataforma decorrentes de circunstâncias alheias à sua vontade e controle, sejam ou não ocasionadas por caso fortuito ou força maior, como por exemplo, intervenções de hackers e softwares maliciosos; falhas técnicas de qualquer tipo, incluindo, falhas no acesso ou na navegação na Plataforma decorrentes de falhas na internet em geral, quedas de energia, mau funcionamento eletrônico e/ou físico de qualquer rede, interrupções ou suspensões de conexão e falhas de software e/ou hardware do Usuário; paralisações programadas para manutenção, atualização e ajustes de configuração da Plataforma,  qualquer falha humana de qualquer outro tipo, que possa ocorrer durante o processamento das informações. O Usuário exime a Oceano de qualquer responsabilidade proveniente de tais fatos e/ou atos.</p>
                <p className="content">8.2. A Oceano oferecerá suporte ao Usuário com relação aos Serviços, o que implica no esclarecimento de dúvidas com relação ao uso da Plataforma por meio da Central de Atendimento, de segunda à sexta, das 09h às 18h e através dos canais disponibilizados pela Oceano.</p>
                <p className="content">8.3. A Oceano reserva-se o direito de modificar, suspender ou descontinuar temporariamente as funcionalidades disponibilizadas na Plataforma para realizar a manutenção, atualização e ajustes de configuração.</p>
                <p className="content">8.4. A Oceano não se responsabiliza pelo recebimento de e-mails falsos e mensagens, enviados por terceiros sem qualquer relação direta ou indireta com a Oceano e/ou empresas do grupo.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">9. RESPONSABILIDADE DO USUÁRIO</p>
                <p className="content">9.1. O Usuário fica ciente e concorda que, na utilização da Plataforma, é terminantemente proibido:</p>
                <ol className="contentList" type="I">
                    <li>
                        <p className="content">distribuir, modificar, vender, alugar ou de qualquer forma explorar economicamente a Plataforma, os dados e informações a ele relacionados, bem como utilizá-los para finalidade que não seja para uso próprio;</p>
                    </li>
                    <li>
                        <p className="content">fornecer à Oceano, por ocasião do cadastro na Plataforma, informações falsas, inexatas, desatualizadas ou incompletas, bem como assumir intencionalmente a personalidade de outra pessoa, física ou jurídica;</p>
                    </li>
                    <li>
                        <p className="content">disseminar ou instalar vírus ou qualquer outro código, arquivo ou software com o propósito de interromper, destruir, acessar indevidamente, limitar ou interferir no funcionamento ou segurança do serviço oferecido pela Oceano, bem como nas informações, dados e equipamentos da Oceano, de seus usuários ou de terceiros, ou, ainda, para qualquer outra finalidade ilícita; e</p>
                    </li>
                    <li>
                        <p className="content">praticar qualquer ato contrário à legislação em vigor.</p>
                    </li>
                </ol>
                <p className="content">9.2. Independente da realização do cadastro, o Usuário é exclusivamente responsável pelo uso da Plataforma e deverá respeitar as regras destes Termos de Uso, bem como a legislação aplicável ao uso da Plataforma.</p>
                <p className="content">9.3. São responsabilidades do Usuário:</p>
                <ol className="contentList" type="I">
                    <li>
                        <p className="content">Utilizar a Plataforma de forma adequada e diligente, em conformidade com os presentes Termos de Uso, com a lei, a moral, os bons costumes e a ordem pública;</p>
                    </li>
                    <li>
                        <p className="content">Manter seguro o ambiente de seus dispositivos de acesso à Plataforma, valendo-se de ferramentas específicas para tanto, tais como antivírus, firewall, entre outras, de modo a contribuir para a prevenção de riscos eletrônicos;</p>
                    </li>
                    <li>
                        <p className="content">Utilizar navegadores e sistemas operacionais atualizados e eficientes para a plena utilização da Plataforma; e</p>
                    </li>
                    <li>
                        <p className="content">Equipar-se e responsabilizar-se pelos dispositivos de hardware necessários para o acesso à Plataforma, bem como pelo acesso desses à internet; e</p>
                    </li>
                    <li>
                        <p className="content">É de total responsabilidade do Usuário manter em sigilo suas senhas, sendo elas pessoais e intransferíveis. Dessa maneira, a Oceano não será, em nenhum cenário, responsável por quaisquer prejuízos causados ao Usuário ou a terceiros pela divulgação e utilização indevida e não autorizada das senhas.</p>
                    </li>
                </ol>
                <p className="content">9.4. Qualquer dano causado pelo Usuário à Oceano ou a terceiros em virtude do não cumprimento das obrigações aqui dispostas ou da não veracidade das garantias aqui declaradas serão reparados exclusivamente pelo Usuário causador do dano, não havendo que se falar em subsidiariedade da obrigação, tampouco em solidariedade da Oceano.</p>
                <p className="content">9.5. No caso de infração às regras acima, o Usuário poderá ser suspenso ou banido, sem prejuízo da responsabilidade legal quando aplicável, respondendo inteiramente por sua conduta.</p>
                <p className="content">9.6. <span className="underline">Da responsabilidade assumida pelos terceiros que recebem informação pessoal da Oceano</span>. Em virtude de acordos celebrados, a Oceano poderá disponibilizar e/ ou receber as Informações Pessoais de terceiros.</p>
                <p className="content">9.6.1. O Usuário declara e garante que, ao receber e/ ou disponibilizar Informações Pessoais na relação estabelecida com a Oceano, deverá atuar de acordo com a legislação em vigor e cumprir as seguintes obrigações:</p>
                <ol className="contentList" type="I">
                    <li>
                        <p className="content">Tratar a Informação Pessoal exclusivamente em razão do objeto do acordo celebrado e conforme as indicações da Oceano, aplicando todas as medidas de segurança que forem necessárias, sejam elas técnicas e/ ou operacionais.</p>
                    </li>
                    <li>
                        <p className="content">Notificar imediatamente a Oceano quando da ocorrência de qualquer incidente que tenha potencial de afetar a segurança da Informação Pessoal, incluindo, mas sem se limitar a (a) recebimento de qualquer solicitação de uma autoridade que tiver como objeto a divulgação de Informação Pessoal; e/ ou (b) ocorrência de qualquer incidente de segurança que afete a Informação Pessoal.</p>
                    </li>
                    <li>
                        <p className="content">Notificar a Oceano ao receber qualquer solicitação ou queixa por parte dos titulares de dados a respeito da Informação Pessoal, abstendo-se de contestar o titular sem a prévia aprovação, por escrito, da Oceano.</p>
                    </li>
                    <li>
                        <p className="content">Excluir e/ ou anonimizar imediatamente a Informação Pessoal em quaisquer dos seguintes casos, salvo se existir algum impedimento legal para tanto: (a) quando tenha terminado a relação jurídica com a Oceano ou (b) por instruções expressas e por escrito da Oceano.</p>
                    </li>
                    <li>
                        <p className="content">Manter a Oceano indene de qualquer demanda, denuncia, reclamação e/ou procedimento, interposto contra o terceiro pelo titular dos dados pessoais e/ou qualquer autoridade administrativa ou judicial, com o motivo de não cumprimento por ele de suas obrigações em matéria de proteção dos dados pessoais ou de qualquer outra obrigação aqui contemplada.</p>
                    </li>
                    <li>
                        <p className="content">Permitir que a Oceano solicite toda a documentação necessária para o cumprimento do dever de segurança contemplado nesta cláusula.</p>
                    </li>
                </ol>
            </div>
            <div className="informations items">
                <p className="content emphasis">10. SERVIÇO DE ATENDIMENTO AO USUÁRIO</p>
                <p className="content">10.1. O Usuário poderá entrar em contato direto com a Oceano através do nosso número/WhatsApp (11) 4580-2353 ou pelo e-mail dados@plataformaoceano.com.br.</p>
                <p className="content">10.2. Todas as comunicações realizadas pela Oceano com o Usuário serão feitas pelo endereço de e-mail informado pelo Usuário no momento do cadastro ou, ainda, serão realizadas através dos canais de comunicação disponibilizados pela Oceano na própria Plataforma.</p>
                <p className="content">10.2.1. O Usuário obriga-se a informar imediatamente quaisquer mudanças de seu endereço de e-mail, a fim de que possa receber regularmente comunicações importantes enviadas pela Oceano. A Oceano não poderá ser responsabilizada pela falta ou erro em suas comunicações nos casos em que o e-mail do Usuário esteja desatualizado.</p>
                <p className="content">10.2.2. É dever do usuário deixar os sistemas AntiSpam de seu e-mail configurados de modo que não interfiram no recebimento dos comunicados enviados pela Oceano. A responsabilidade pelo recebimento e visualização dos comunicados é exclusiva do Usuário.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">11. DOS LINKS EXTERNOS</p>
                <p className="content">11.1. A Plataforma pode conter links para portais e aplicativos de terceiros que não pertencem e não são controlados pela Oceano. Apesar das verificações prévias e regulares realizadas pela Oceano, esta não endossa ou garante ou possui qualquer ligação com os proprietários desses portais ou aplicativos detidos por terceiros, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões expressas em qualquer desses portais e aplicativos de terceiros com os quais o Usuário interaja através da Plataforma. A Oceano recomenda que o Usuário leia os Termos de Uso e as Políticas de Privacidade de cada site, plataforma e aplicativo de terceiros ou serviço que o Usuário vier a visitar ou utilizar, pois a Oceano se isenta de qualquer responsabilidade sobre o conteúdo encontrado nestes sites e serviços.</p>
                <p className="content">11.2. Não será autorizada a inclusão de páginas que divulguem quaisquer tipos de informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.</p>
                <p className="content">11.3. A Plataforma se reserva o direito de retirar a qualquer momento um link levando ao seu próprio serviço, se a página de origem não estiver conforme os seus termos e políticas.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">12. PAGAMENTOS</p>
                <p className="content">12.1. O cadastro na Plataforma Oceano é gratuito para os Usuários, seja ele Prestador de Serviço ou o Contratante.</p>
                <p className="content">12.2. Após a publicação do trabalho e escolha pelo Contratante do Profissional que melhor atende os requisitos para vaga, haverá um comunicado por meio da Plataforma Oceano do match entre o Contratante e o Profissional.</p>
                <p className="content">12.2.1. O Profissional ou o seu representante (no caso de ser algum Profissional de Agência), após a notificação de que foi selecionado para o trabalho, deverá confirmar em até 24h (vinte e quatro horas) o seu aceite de fato para o trabalho.</p>
                <p className="content">12.2.2. Após a confirmação do Profissional ou de seu representante para a realização do trabalho, o Contratante deverá efetuar o pagamento, via boleto ou PIX gerado pela Pagar.Me, no prazo de até 48h (quarenta e oito horas) para a Plataforma Oceano, sob pena de ocorrer o cancelamento do trabalho.</p>
                <p className="content">12.2.3. Realizado e confirmado o valor do pagamento a Plataforma emitirá uma Nota Fiscal para o Contratante.</p>
                <p className="content">12.3. O Contratante deverá informar na Plataforma, na aba: “Meus trabalhos”, se o trabalho foi executado. Abaixo seguem as regras para o estorno ou não do pagamento do valor do trabalho.</p>
                <p className="content">12.3.1. Caso o Contratante não confirme a execução do trabalho em até 48h (quarenta e oito horas ), da data prevista para a execução do trabalho, a Plataforma entenderá que o trabalho foi executado e fará o pagamento para os envolvidos (agências, agentes, produtores, profissionais).</p>
                <p className="content">12.3.1.1. Ocorrendo esta situação, sem que o Contratante confirme ou não a execução do trabalho, no prazo determinado no item 12.3.1. acima, não poderá o Contratante solicitar mais o cancelamento do trabalho, nem haverá estorno do valor pago por ele à Plataforma Oceano.</p>
                <p className="content">12.3.2. Caso o Contratante solicite o cancelamento do trabalho após ter realizado o pagamento e antes da data prevista para a execução do trabalho, sem motivo de força maior, será estornado 75% (setenta e cinco por cento) do valor pago pelo Contratante à Plataforma Oceano.</p>
                <p className="content">12.3.3. Caso o Profissional não apareça no trabalho, o Contratante deverá entrar em contato imediatamente com a plataforma por meio do e-mail atendimento@plataformaoceano.com.br ou pelo telefone: (11) 4580-2353. Nesta situação o estorno do valor pago será de forma integral ao Contratante.12.4. O Usuário concorda em pagar à Oceano os valores correspondentes a qualquer serviço acima estabelecido.</p>
                <p className="content">12.5. A Oceano se reserva o direito de modificar, aumentar ou excluir os valores vigentes a qualquer momento ou durante promoções e outras formas transitórias de alteração dos preços praticados.</p>
                <p className="content">12.6. A Oceano se reserva o direito de tomar as medidas judiciais e extrajudiciais pertinentes para receber os valores devidos.</p>
                <p className="content">12.6.1. Inicialmente os pagamentos da cláusula 12.2. acima serão realizados por meio da empresa PAGAR.ME, que repassará os valores para a Oceano, e a Oceano será responsável pela realização dos Splits do pagamento para cada uma da Partes envolvidas.</p>
                <p className="content">12.7. Processamento de Pagamento. O Usuário declara e reconhece que o processamento de pagamentos depende do cumprimento de alguns procedimentos, como análise de risco e compensação bancária, a depender do meio utilizado.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">13. ALTERAÇÃO DESTES TERMOS DE USO</p>
                <p className="content">13.1. A Oceano está sempre fazendo atualizações na plataforma para melhorar a prestação dos Serviços. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo, a fim de refletir os ajustes realizados.</p>
                <p className="content">13.2. Sempre que ocorrer qualquer modificação nestes Termos de Uso, a Oceano notificará na Plataforma ao Usuário, que os Termos foram modificados. Devendo o Usuário concordar com eles para continuar acessando a Plataforma.</p>
                <p className="content">13.2.1. O Usuário concorda que fornecer um aviso de alteração na Plataforma é uma forma adequada de comunicação e que após verificar o aviso irá revisar os Termos para ter certeza de que está ciente com as alterações realizadas e de acordo.</p>
                <p className="content">13.2.2. Caso o Usuário não concorde com os novos Termos de Uso, ele poderá rejeitá-los, mas isso significa que o uso da Plataforma não será mais permitido. Se de qualquer maneira o Usuário utilizar a Plataforma mesmo após a alteração destes Termos de Uso, isso significa que este concorda com todas as modificações.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">14. OUTRAS DISPOSIÇÕES</p>
                <p className="content">14.1. A Oceano poderá, a qualquer momento, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso a qualquer pessoa, física ou jurídica, mediante simples notificação prévia ao Usuário, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela Oceano, conforme o caso.</p>
                <p className="content">14.2. <span className="underline">Uso de Imagem</span>. Dado o match entre a vaga de trabalho e o Profissional, todos os documentos necessários para a composição jurídica da relação se dará entre o Contratante/Agência e o Profissional, seja ele, um possível contrato de trabalho, os termos de autorização de uso de imagem/voz, são de exclusiva responsabilidade entre os mesmos, isentando a Plataforma Oceano de qualquer responsabilidade e até penalidades quanto ao conteúdo dos documentos formalizados e/ou quanto à ausência deles.</p>
                <p className="content">14.2.1 Em caso de casting na modalidade presencial, é vedada a contratação direta entre cliente (Contratante ou Agência) e o profissional, com ou sem intermediação de Agência – mas desde que por fora da Plataforma, as Partes envolvidas terão suas publicações e perfil excluídos da Plataforma.</p>
                <p className="content">14.3. Estes Termos de Uso não criam qualquer outra modalidade de vínculo entre o Usuário e a Oceano, inclusive, sem limitação, sociedade, mandato, parceria, associação, joint-venture, consórcio, grupo econômico, vínculo empregatício ou similar. A Oceano permanecerá uma entidade independente e autônoma.</p>
                <p className="content">14.4. Ocasionalmente, pode haver informações em nossa Plataforma que contenham erros tipográficos, imprecisões ou omissões. A Oceano se reserva no direito de corrigir quaisquer erros, imprecisões ou omissões e de alterar ou atualizar informações se alguma informação na Plataforma estiver imprecisa a qualquer momento sem aviso prévio.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">15. DAS DISPUTAS</p>
                <p className="content">15.1. Esses Termos de Uso são regidos pelas leis da República Federativa do Brasil.</p>
                <p className="content">15.2. Todas as controvérsias deste Termos de Uso serão solucionadas pelo foro da Comarca de São Paulo/SP - Brasil, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
            </div>
            <div className="informations items">
                <p className="content emphasis">DADOS DE CONTATO</p>
                <div>
                    <p className="content"><span className="content emphasis">PLATAFORMA OCEANO LTDA</span> - CNPJ sob o nº 44.576.640/0001-10.</p>
                    <p className="content">Endereço para correspondência na Avenida Ibirapuera, nº 2907, Conj. 1211, Moema/SP, CEP: 04029-902.</p>
                    <p className="content">Telefone/WhatsApp: (11) 4580-2353</p>
                    <p className="content">E-mail: dados@plataformaoceano.com.br</p>
                    <p className="content">Horários de Atendimento: Segunda à Sexta: Das 09h às 18h.</p>
                </div>
            </div>
        </div>
    );
}

export default TermText;