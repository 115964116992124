export const colors = {
  primaryColors: {
    BLUE: {
      default: '#2C1DFE',
      extendedColors: {
        BDARK: '#05031A',
        B900: '#090633',
        B800: '#120C66',
        B700: '#1A1198',
        B600: '#2317CB',
        B400: '#564AFE',
        B300: '#8077FE',
        B200: '#ABA5FF',
        B100: '#D5D2FF',
        BLIGHT: '#EEEDFF'
      }
    },

    GREEN: {
      default: '#79F2C0',
      extendedColors: {
        GDARK: '#11291F',
        G900: '#063D26',
        G800: '#1D6145',
        G700: '#4BAA82',
        G600: '#62CEA1',
        G400: '#94F5CD',
        G300: '#AFF7D9',
        G200: '#C9FAE6',
        G100: '#F2FEF9',
        GLIGHT: '#F2FEF9'
      }
    }
  },

  secondaryColors: {
    GREEN: {
      default: '#36B340',
      extendedColors: {
        GRDARK: '#061207',
        GR900: '#061207',
        GR800: '#16481A',
        GR700: '#206B26',
        GR600: '#2B8F33',
        GR500: '#79F2C0',
        GR400: '#5EC266',
        GR300: '#86D18C',
        GR200: '#AFE1B3',
        GR100: '#D7F0D9',
        GRLIGHT: '#EFF9F0'
      }
    },

    YELLOW: {
      default: '#FFD503',
      extendedColors: {
        YDARK: '#1A1601',
        Y900: '#332B01',
        Y800: '#665501',
        Y700: '#998002',
        Y600: '#CCAA02',
        Y400: '#FFDD35',
        Y300: '#FFE668',
        Y200: '#FFEE9A',
        Y100: '#FFF7CD',
        YLIGHT: '#FFFCEB'
      }
    },

    RED: {
      default: '#FE2C1D',
      extendedColors: {
        GDARK: '#330906',
        R900: '#400B07',
        R800: '#66120C',
        R700: '#981A11',
        R600: '#CB2317',
        R500: '#FE2C1D',
        R400: '#FE564A',
        R300: '#FE8077',
        R200: '#FFABA5',
        R100: '#FFD5D2',
        RLIGHT: '#FFEEED'
      }
    },

    BLUE: {
      default: '#1D7EFE',
      extendedColors: {
        BLDARK: '#030D1A',
        BL900: '#061933',
        BL800: '#0C3266',
        BL700: '#114C98',
        BL600: '#1765CB',
        BL400: '#4A98FE',
        BL300: '#77B2FE',
        BL200: '#A5CBFF',
        BL100: '#D2E5FF',
        blLIGHT: '#EDF5FF'
      }
    },

    PURPLE: {
      default: '#9C1DFE',
      extendedColors: {
        PDARK: '#10031A',
        P900: '#1F0633',
        P800: '#3E0C66',
        P700: '#5E1198',
        P600: '#7D17CB',
        P400: '#B04AFE',
        P300: '#C477FE',
        P200: '#D7A5FF',
        P100: '#EBD2FF',
        PLIGHT: '#EBD2FF'
      }
    }
  },

  neutralsColors: {
    N900: '#0F0E17',
    N800: '#08070D',
    N700: '#262539',
    N600: '#2E2C4C',
    N500: '#322F55',
    N400: '#322F55',
    N300: '#484365',
    N200: '#544E6E',
    N100: '#605977',
    N90: '#6C6481',
    N80: '#78718B',
    N70: '#867E97',
    N60: '#A49DB1',
    N50: '#B4AFBF',
    N40: '#C6C2CE',
    N30: '#D9D6DF',
    N20: '#EAE9EF',
    N15: '#F1EFF5',
    N10: '#F8F8F8',
    N5: '#FDFDFD'
  }
}

export const fonts = {
  D1: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 120%;
  margin: 64px 0;
  color: ${colors.neutralsColors.N800};`,

  D2: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  margin: 52px 0;
  color: ${colors.neutralsColors.N800};`,

  H1: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 120%;
  margin: 42px 0;`,

  H2: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  padding: 32px 0;`,

  H3: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  margin: 26px 0;`,

  H4: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin: 20px 0;`,

  H5: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  margin: 16px 0;`,

  H6: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin: 14px 0;`,

  H7: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  margin: 10px 0;`,

  B1: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: ${colors.neutralsColors.N500};
  margin: 16px 0;`,

  B2: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${colors.neutralsColors.N500};
  margin: 14px 0;`,

  B3: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: ${colors.neutralsColors.N500};
  margin: 14px 0;`,

  B4: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  color: ${colors.neutralsColors.N500};
  margin: 14px 0;`,

  B5: `font-family: 'Roboto', 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  color: ${colors.neutralsColors.N500};
  margin: 20px 0;`,

  LINKS: {
    LINK: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.default};
    margin-top: 8px;`,

    HOVER: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.extendedColors.B400};
    margin-top: 8px;`,

    PRESSED: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.extendedColors.B600};
    margin-top: 8px;`,

    DISABLED: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.extendedColors.B100};
    margin-top: 8px;`
  },

  BUTTONS: {
    DEFAULT: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.extendedColors.B800};
    margin-top: 8px;`,

    HOVER: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.default};
    margin-top: 8px;`,

    PRESSED: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.extendedColors.B600};
    margin-top: 8px;`,

    DISABLED: `font-family: 'Roboto', 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.primaryColors.BLUE.extendedColors.B600};
    margin-top: 8px;`
  }
}

export const effects = {
  shadows: {
    level0: 'box-shadow: inset 0px 1px 2px rgba(61, 57, 93, 0.15);',
    level1: 'box-shadow: 0px 0px 1px rgba(61, 57, 93, 0.05), 0px 1px 2px rgba(61, 57, 93, 0.1) !important;',
    level2: 'box-shadow: 0px -1px 4px -1px rgba(0, 0, 0, 0.025), 0px 4px 6px -2px rgba(61, 57, 93, 0.15);',
    level3: 'box-shadow: 0px -2px 4px -2px rgba(61, 57, 93, 0.05), 0px 8px 8px -2px rgba(61, 57, 93, 0.1);',
    level4: 'box-shadow: 0px -2px 4px -2px rgba(61, 57, 93, 0.05), 0px 10px 16px -4px rgba(61, 57, 93, 0.15);',
    level5: 'box-shadow: 0px -4px 4px -2px rgba(61, 57, 93, 0.025), 0px 16px 24px -4px rgba(61, 57, 93, 0.15);'
  },

  blurs: 'backdrop-filter: blur(6px);'
}
