import React, { useState } from "react";
import TextButton from "../../../components/TextButton";
import arrowRightIcon from "../../../assets/icons/circle-arrow-right.svg";
import {
  Container,
  Content,
  Context,
  AreaDescription,
  AreaText,
  Footer,
  Title,
  Description,
  BackgroundImage,
} from "./styles";
import Register from "../../../components/Register";

const Advertise = () => {
  const [toggleRegisterModal, setToggleRegisterModal] =
    useState<boolean>(false);

  return (
    <Container>
      <Content>
        <Context>
          <AreaDescription>
            <AreaText>
              <Title>
                Encontre o talento da sua campanha em alguns cliques
              </Title>
              <Description>
                Quer ter acesso a modelos, influenciadores digitais,
                apresentadores e atores de todo o Brasil? Somos a solução
                perfeita para você!
              </Description>
            </AreaText>
          </AreaDescription>
          <Footer>
            <AreaText>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
              <TextButton
                text="QUERO ENCONTRAR TALENTOS"
                icon={arrowRightIcon}
                color="#EEEDFF"
              />

            </a>
            </AreaText>
          </Footer>
        </Context>
        <BackgroundImage />
      </Content>
      <Register
        toggleModal={toggleRegisterModal}
        setToggleModal={setToggleRegisterModal}
      />
    </Container>
  );
};

export default Advertise;
