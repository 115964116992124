import React from "react";
import './form.scss';
import LabelW700H26 from "../label/labelW700H26";
import LabelW400H14 from "../label/labelW400H14";
import InputEmail from "../Input/inputEmail";

interface props {
    emailSet: any;
}

const ForgetPasswordForm = ({ emailSet }: props) => {
    return (
        <div className="forgetPasswordForm">
            <LabelW700H26 text={"Esqueci a Senha"} />
            <LabelW400H14 text={"Confirme seu e-mail cadastrado para cadastrar uma nova senha."} />
            <InputEmail emailSet={emailSet} />
        </div>
    );
}

export default ForgetPasswordForm;