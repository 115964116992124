import TextButton from '../../../components/TextButton';
import arrowRightIcon from '../../../assets/icons/circle-arrow-right.svg'
import {
  Container,
  Content,
  Context,
  AreaDescription,
  AreaText,
  Footer,
  Title,
  Description
} from './styles';

const Advertise = () => {


  return (
    <Container>
      <Content>
        <Context>
          <AreaDescription>
            <AreaText>
              <Title>Anuncie no Rádio, TV e outras mídias</Title>
              <Description>Aumente a visibilidade do seu negócio ao inserir a presença da sua marca em plataformas de mídia que oferecem um alcance mais amplo no Brasil.</Description>
            </AreaText>
          </AreaDescription>
          <Footer>
            <AreaText>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
              <TextButton
                text='Comece agora'
                icon={arrowRightIcon}
                color='#EEEDFF'
              />
              </a>
            </AreaText>
          </Footer>
        </Context>
      </Content>
    </Container>
  );
};

export default Advertise
