import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleX,
} from "@fortawesome/pro-solid-svg-icons";
import React, { useState } from "react";

interface InputProps {
  value: string;
  onChange: (event: any) => void;
  type: "email" | "password";
  placeholder: string;
  valid: boolean;
  error: boolean;
}
const Input = ({
  value,
  onChange,
  type,
  placeholder,
  valid,
  error,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(type === "email");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const themeStyles = {
    textColor: "#0F0E17",
    infoColor: "rgba(0, 0, 0, 0.6)",
    disabledInfoColor: "#B4AFBF",
    buttonBackground:
      "linear-gradient(179.82deg, #6A5FFC 0.16%, #2C1DFE 51.04%, #0F00DA 99.84%)",
    buttonBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 4px 8px rgba(5, 3, 26, 0.1)",
    buttonHoverBackground:
      "linear-gradient(179.82deg, #6A5FFC 0.16%, #2C1DFE 51.04%, #1100FF 99.84%)",
    buttonHoverBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 8px 16px -4px rgba(5, 3, 26, 0.16)",
    buttonActiveBackground: "#2C1DFE",
    buttonActiveBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 2px 4px rgba(5, 3, 26, 0.08)",
    buttonColor: "white",
    inputBackground: "white",
    inputTextColor: "#0F0E17",
  };
  const style = {
    backgroundColor: themeStyles.inputBackground,
    width: "100%",
    height: "48px",
    borderRadius: "4px",
    fieldset: { borderColor: "#2C1DFE" },
    "& .MuiOutlinedInput-root": {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor:
          value.length > 0 && (!valid || error) ? "#FE2C1D" : "#2C1DFE",
      },
      "&.Mui-focused fieldset": {
        borderColor:
          value.length > 0 && (!valid || error) ? "#FE2C1D" : "#2C1DFE",
      },
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor:
          value.length > 0 && (!valid || error) ? "#FE2C1D" : "#2C1DFE",
      },
      "&:hover fieldset": {
        borderColor:
          value.length > 0 && (!valid || error) ? "#FE2C1D" : "#2C1DFE",
      },
    },
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      value={value}
      name="email"
      onChange={onChange}
      error={!valid}
      label={value ? placeholder : ""}
      placeholder={placeholder}
      sx={style}
      variant="outlined"
      InputLabelProps={{
        style: { color: "#B4AFBF", fontWeight: "300" },
      }}
      inputProps={{
        style: {
          WebkitTextFillColor: themeStyles.inputTextColor,
        },
      }}
      InputProps={{
        style: {
          width: "100%",
          height: "48px",
          borderWidth: "1px",
          borderColor: "#2C1DFE",
          color: themeStyles.inputTextColor,
          fontSize: "16px",
          fontWeight: "300",
        },
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon
              icon={type === "email" ? faEnvelope : faLock}
              style={{
                color: "#2C1DFE",
                width: "18px",
                height: "18px",
              }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {type === "password" && (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  tabIndex={-1}
                >
                  {showPassword ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      style={{
                        color: "#2C1DFE",
                        width: "18px",
                        height: "16px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{
                        color: "#2C1DFE",
                        width: "18px",
                        height: "16px",
                      }}
                    />
                  )}
                </IconButton>
              )}
              {error && value.length === 0 && (
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  style={{
                    color: "#FFD503",
                    width: "12px",
                    height: "12px",
                  }}
                />
              )}
              {value.length > 0 && valid && !error && (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{
                    color: "#79F2C0",
                    width: "12px",
                    height: "12px",
                  }}
                />
              )}
              {value.length > 0 && (!valid || error) && (
                <FontAwesomeIcon
                  icon={faCircleX}
                  style={{
                    color: "#FE2C1D",
                    width: "12px",
                    height: "12px",
                  }}
                />
              )}
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Input;
