import styled from "styled-components";
import wavesBackground from "../../../assets/icons/waves-background-personalities.svg";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 37.13rem;
  /* padding-left: 120px;
  padding-right: 120px; */
  background-image: url(${wavesBackground});
  background-color: #fdfdfd;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  align-items: center;
  justify-content: center;

 

  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.sm}) {
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 3rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;

  span {
    color: #9c1dfe;
  }

  @media only screen and (${devices.lg}) {
    font-size: 2rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 43.25rem;
  gap: 2.5rem;
  a {
    width: 100%;
    text-decoration:none;
  }

  @media only screen and (${devices.md}) {
    width: 100%;
    max-width: 19rem;
  }
`;

export const AreaIcons = styled.div`
  display: grid;
  width: 13.75rem;
  height: 15.5625rem;

  @media only screen and (${devices.sm}) {
    display: none;
  }
`;

export const AreaCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const CircleOne = styled(AreaCircle)`
  width: 5.5rem;
  height: 5.5rem;
  background-color: #edf5ff;
  border-radius: 100%;
  position: absolute;
  justify-content: center;
`;

export const CircleTwo = styled(AreaCircle)`
  display: flex;
  width: 4rem;
  height: 4rem;
  background-color: #2c1dfe;
  border-radius: 100%;
  position: absolute;
  justify-content: center;
  animation: expandAndContract 1.5s infinite alternate;

  @keyframes expandAndContract {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.4);
    }
  }
`;

export const Icon = styled.img`
  z-index: 1;
`;
