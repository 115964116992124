import React, { useEffect, useState } from "react";

import './aboutUsContent.scss';
import LabelTitle from "../label/LabelTitle";
import LabelContent from "../label/labelContent";

import aboutUsContent from '../../data/data.json';
import Badge from "../Badge";

interface about {
  id: number;
  text: string;
}

const AboutUsContent = () => {
  const [aboutUsContentData, aboutUsContentDataSet] = useState<Array<about>>([{ id: 0, text: '' }]);

  useEffect(() => {
    aboutUsContentDataSet(aboutUsContent.aboutUsContent);
  }, []);

  return (
    <div className="aboutUsContent">
      <Badge text="SOBRE NÓS" color="#114C98" backgroundColor="#EDF5FF" />
      <div className="aboutUsGrid">
        <div className="contentLeft">
          <LabelTitle text={"Marketplace de"} span={" Marketing"} isSameLane={true} text2={""} />
        </div>
        <div className="contentRight">
          {
            aboutUsContentData.map((obj) =>
              <LabelContent text={obj.text} />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default AboutUsContent;
