/* eslint-disable jsx-a11y/aria-role */
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useRequest } from "../../hooks/useRequest";

import { Box, Button, Link } from "@mui/material";

import { Typography } from "@mui/material";
import { SimpleDialogContext } from "../SimpleDialog";
import { ForgotPasswordDialogContext } from "../ForgotPasswordDialog";
import { faCircleCheck, faCircleX } from "@fortawesome/pro-solid-svg-icons";
import Input from "./Input";
import DialogContent from "@mui/material/DialogContent";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useWindowDimensions from "../../hooks/useWindowsDimensions";

type header = {
  ContentType: string;
  Authorization?: string;
};

type headersType = {
  headers: header;
};

export const headers: headersType = {
  headers: {
    ContentType: "application/json",
  },
};

interface RegisterProps {
  toggleModal: boolean;
  setToggleModal: React.Dispatch<SetStateAction<boolean>>;
}

// const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/
const regexUpperCase = /^(?=.*[A-Z]).+$/;
const regexLowercase = /^(?=.*[a-z]).+$/;
const regexNumber = /^(?=.*[0-9]).+$/;
const caracterExpecial = new RegExp("[?!@#$%^ˆ&*()]");

const Register = ({ toggleModal, setToggleModal }: RegisterProps) => {
  const useRequestObj = useRequest();
  const navigate = useNavigate();

  const { actions: simpleDialogActions } = useContext(SimpleDialogContext);
  const { actions: forgetPasswordDialogActions } = useContext(
    ForgotPasswordDialogContext
  );
  const [processingRegister, setProcessingRegister] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkExistingUser = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsLoading(true);
    if (processingRegister) {
      return;
    }
    setProcessingRegister(true);

    let error = false;
    if (email.length === 0) {
      setEmailInputError(true);
      error = true;
    }
    if (password.length === 0) {
      setPasswordInputError(true);
      error = true;
    }
    if (confirmationPassword.length === 0) {
      setConfirmationPasswordInputError(true);
      error = true;
    }
    if (error) {
      return;
    }

    if (
      !isEmailFieldValid ||
      !validPassword ||
      password !== confirmationPassword
    ) {
      return;
    }

    const body = {
      item: {
        email: email.toLowerCase(),
        password: password,
        confirmPassword: confirmationPassword,
      },
    };
    try {
      const existingUserData = await useRequestObj?.request(
        `v1/User/ExistingUser`,
        "post",
        body,
        headers
      );
      if (existingUserData.data.success) {
        if (
          existingUserData.data.item?.hiringCNPJ ||
          existingUserData.data.item?.administratorName
        ) {
          setToggleModal(false);
          simpleDialogActions.open(
            "Email já cadastrado!",
            'E-mail já cadastrado anteriormente, caso você tenha esquecido a sua senha, clique no botão "Esqueci a Senha" para recuperar.',
            {
              showCancel: true,
              cancelText: "Cancelar",
              okText: "Esqueci a Senha",
              showOk: true,
            },
            (success) => {
              success && forgetPasswordDialogActions.open();
            }
          );
          return;
        }
        if (existingUserData.data.item?.professionalCPF) {
          setToggleModal(false);
          simpleDialogActions.open(
            "Conta de Profissional",
            "Ops, identificamos que sua conta é uma conta profissional. Para continuar, efetue o login pelo nosso App!",
            {
              showCancel: true,
              cancelText: "Cancelar",
              okText: "Continuar",
              showOk: true,
            },
            (success) => {
              success &&
                window.location.replace(
                  "https://plataformaoceano.com.br/profissional"
                );
            }
          );
        }

        if (
          existingUserData.data.item?.userID == null ||
          existingUserData.data.item?.userID == 0
        ) {
          const userSaveData = await useRequestObj?.request(
            `v1/User/UserSave`,
            "post",
            body,
            headers
          );
          if (userSaveData.data.success) {
            const user = {
              userID: userSaveData.data.item?.userID,
              email: email.toLowerCase(),
              password: password,
              isTermsSaved: existingUserData.data.item?.isAceptedTerms ? 1 : 0,
              hiringId: existingUserData.data.item?.hiringID,
            };
            navigate("/register", { state: user });
          }
        } else {
          const user = {
            userID: existingUserData.data.item?.userID,
            email: email.toLowerCase(),
            password: password,
            isTermsSaved: existingUserData.data.item?.isAceptedTerms ? 1 : 0,
            hiringId: existingUserData.data.item?.hiringID,
            companyType: existingUserData.data.item?.companyType,
          };
          navigate("/register", { state: user });
        }
      } else {
        setToggleModal(false);
        simpleDialogActions.open(
          "Email já cadastrado!",
          'E-mail já cadastrado anteriormente, caso você tenha esquecido a sua senha, clique no botão "Esqueci a Senha" para recuperar.',
          {
            showCancel: true,
            cancelText: "Cancelar",
            okText: "Esqueci a Senha",
            showOk: true,
          },
          (success) => {
            success && forgetPasswordDialogActions.open();
          }
        );
        return;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      setProcessingRegister(false);
    }
  };

  useEffect(() => {
    useRequestObj?.error && alert("Algo inesperado aconteceu!");
  }, [useRequestObj?.error]);

  const [email, setEmail] = useState("");
  const [isEmailFieldValid, setIsEmailFieldValid] = useState(true);
  const [emailInputError, setEmailInputError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [passwordInputError, setPasswordInputError] = useState(false);
  const [confirmationPasswordInputError, setConfirmationPasswordInputError] =
    useState(false);

  const passwordHasOneLower = regexLowercase.test(password);
  const passwordHasOneUpper = regexUpperCase.test(password);
  const passwordHasOneSpecial = caracterExpecial.test(password);
  const passwordHasOneNumber = regexNumber.test(password);
  const passwordHasEightChar = password.length >= 8;
  const equalPasswords = confirmationPassword === password;

  const validPassword =
    passwordHasOneLower &&
    passwordHasOneUpper &&
    passwordHasOneSpecial &&
    passwordHasOneNumber &&
    passwordHasEightChar;

  const themeStyles = {
    xIconColor: "#05031A",
    backgroundColor: "#FDFDFD",
    boxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 16px 24px -4px rgba(5, 3, 26, 0.16)",
    textColor: "#262539",
    infoColor: "rgba(0, 0, 0, 0.6)",
    disabledInfoColor: "#B4AFBF",
    buttonBackground:
      "linear-gradient(179.82deg, #6A5FFC 0.16%, #2C1DFE 51.04%, #0F00DA 99.84%)",
    buttonBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 4px 8px rgba(5, 3, 26, 0.1)",
    buttonHoverBackground:
      "linear-gradient(179.82deg, #6A5FFC 0.16%, #2C1DFE 51.04%, #1100FF 99.84%)",
    buttonHoverBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 8px 16px -4px rgba(5, 3, 26, 0.16)",
    buttonActiveBackground: "#2C1DFE",
    buttonActiveBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 2px 4px rgba(5, 3, 26, 0.08)",
    buttonColor: "white",
    inputBackground: "white",
    inputTextColor: "#0F0E17",
    upperValidationColor: passwordHasOneUpper ? "#2C1DFE" : "#FE2C1D",
    lowerValidationColor: passwordHasOneLower ? "#2C1DFE" : "#FE2C1D",
    specialValidationColor: passwordHasOneSpecial ? "#2C1DFE" : "#FE2C1D",
    numberValidationColor: passwordHasOneNumber ? "#2C1DFE" : "#FE2C1D",
    lengthValidationColor: passwordHasEightChar ? "#2C1DFE" : "#FE2C1D",
    equalValidationColor: equalPasswords ? "#2C1DFE" : "#FE2C1D",
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePasswordChange = (event: any) => {
    const passwordWithoutSpaces = event.target.value.trim();
    setPassword(passwordWithoutSpaces);
  };

  useEffect(() => {
    if (password.length === 0 || validPassword) {
      setPasswordInputError(false);
    }
    if (confirmationPassword.length === 0 || equalPasswords) {
      setConfirmationPasswordInputError(false);
    }
  }, [password, confirmationPassword]);

  const handleConfirmationPasswordChange = (event: any) => {
    const passwordWithoutSpaces = event.target.value.trim();
    setConfirmationPassword(passwordWithoutSpaces);
  };

  const handleCheckValid = (isValid: boolean) => {
    return isValid ? (
      <FontAwesomeIcon
        icon={faCircleCheck}
        style={{
          color: "#79F2C0",
          width: "12px",
          height: "12px",
        }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faCircleX}
        style={{
          color: "#FE2C1D",
          width: "12px",
          height: "12px",
        }}
      />
    );
  };
  const handleEmailChange = (event: any) => {
    const emailWithoutSpaces = event.target.value.trim();
    setEmail(emailWithoutSpaces);
  };

  useEffect(() => {
    const emailPattern = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
    );
    if (emailPattern.test(email)) {
      setIsEmailFieldValid(true);
      setEmailInputError(false);
    } else {
      setIsEmailFieldValid(false);
    }
  }, [email]);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setConfirmationPassword("");
  }, [toggleModal]);

  const { height } = useWindowDimensions();
  //decrease margin bottom as the screen height diminish
  const paddingY = 104 - (1043 - height) / 2;
  const adjustedPaddingY =
    height < 1043 ? (paddingY > 32 ? paddingY + "px" : "32px") : "104px";

  const handleLogin = () => {
    window.open("https://cadastro.plataformaoceano.com.br/", "_self");
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: { xs: "93%", sm: "624px" },
          borderRadius: "0px",
          display: "flex",
          alignItems: "center",
          backgroundColor: themeStyles.backgroundColor,
          overflowX: { md: "hidden" },
        },
      }}
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(6px)",
      }}
      open={toggleModal}
      data-modal
    >
      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            padding: { xs: "40px", sm: `${adjustedPaddingY} 118px` },
            height: "100%",
            minHeight: "100%",
            width: "100%",
            backgroundColor: themeStyles.backgroundColor,
          }}
        >
          <Button
            sx={{
              color: themeStyles.xIconColor,
              position: "absolute",
              padding: 0,
              right: "27px",
              top: "24px",
              maxWidth: "12px",
              maxHeight: "12px",
              minWidth: "12px",
              minHeight: "12px",
            }}
            onClick={() => setToggleModal(false)}
          >
            <FontAwesomeIcon
              style={{ width: "12px", height: "12px" }}
              title="close"
              icon={faX}
            />
          </Button>
          <form role={"registerForm"} onSubmit={checkExistingUser}>
            <Box
              sx={{
                width: { xs: "304px", sm: "384px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#2C1DFE",
                    fontSize: "42px",
                    padding: 0,
                    marginBottom: "8px",
                  }}
                >
                  Cadastre-se
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#2C1DFE",
                    display: "flex",
                    alignItems: "center !important",
                    marginBottom: "32px !important",
                    fontSize: "14px",
                    fontWeight: "300",
                  }}
                >
                  Já tem uma conta?&nbsp;
                  <Button
                    sx={{
                      color: "#4BAA82",
                      textTransform: "none",
                      textDecorationLine: "underline",
                      fontWeight: "400 !important",
                      padding: 0,
                      ":hover": {
                        color: "#4BAA82",
                        textDecorationLine: "underline",
                        background: "transparent",
                      },
                    }}
                    onClick={handleLogin}
                  >
                    Efetue o login.
                  </Button>
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gap: "16px",
                  marginBottom: "32px",
                }}
              >
                <Box>
                  <Input
                    value={email}
                    onChange={handleEmailChange}
                    error={emailInputError}
                    placeholder={"E-mail"}
                    valid={isEmailFieldValid}
                    type={"email"}
                  />
                  {email.length > 0 && !isEmailFieldValid && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#FE2C1D !important",
                        display: "flex",
                        alignItems: "center !important",
                        margin: "0px !important",
                        marginTop: "8px !important",
                        fontSize: "10px !important",
                        fontWeight: "500 !important",
                      }}
                    >
                      Insira um e-mail válido!
                    </Typography>
                  )}
                </Box>
                <Input
                  value={password}
                  onChange={handlePasswordChange}
                  error={passwordInputError}
                  placeholder={"Senha"}
                  valid={validPassword}
                  type={"password"}
                />
                <Box sx={{ display: "grid" }}>
                  <Input
                    value={confirmationPassword}
                    onChange={handleConfirmationPasswordChange}
                    error={confirmationPasswordInputError}
                    placeholder={"Confirmar Senha"}
                    valid={equalPasswords}
                    type={"password"}
                  />
                  <Box
                    sx={{
                      display:
                        (validPassword && equalPasswords) ||
                        (password.length === 0 &&
                          confirmationPassword.length === 0)
                          ? "none"
                          : "grid",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      sx={{
                        color: themeStyles.upperValidationColor,
                        fontSize: "10px !important",
                        fontWeight: "400 !important",
                        marginTop: "16px !important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {handleCheckValid(passwordHasOneUpper)}&nbsp;Deve conter
                      pelo menos 1 caractere maiúsculo;
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{
                        color: themeStyles.lowerValidationColor,
                        fontSize: "10px !important",
                        fontWeight: "400 !important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {handleCheckValid(passwordHasOneLower)}&nbsp;Deve conter
                      pelo menos 1 caractere minúsculo;
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{
                        color: themeStyles.specialValidationColor,
                        fontSize: "10px !important",
                        fontWeight: "400 !important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {handleCheckValid(passwordHasOneSpecial)}&nbsp;Deve conter
                      pelo menos 1 caractere especial ?!@#$%^&*();
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{
                        color: themeStyles.numberValidationColor,
                        fontSize: "10px !important",
                        fontWeight: "400 !important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {handleCheckValid(passwordHasOneNumber)}&nbsp;Deve conter
                      pelo menos 1 caractere numérico;
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{
                        color: themeStyles.lengthValidationColor,
                        fontSize: "10px !important",
                        fontWeight: "400 !important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {handleCheckValid(passwordHasEightChar)}&nbsp;Deve conter
                      pelo menos 8 caracteres;
                    </Typography>
                    <Typography
                      variant={"body2"}
                      sx={{
                        color: themeStyles.equalValidationColor,
                        fontSize: "10px !important",
                        fontWeight: "400 !important",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {handleCheckValid(equalPasswords)}&nbsp;As senhas devem
                      ser iguais.
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Button
                id="submit"
                type="submit"
                onClick={checkExistingUser}
                style={{ width: "100%", padding: 0 }}
                disabled={isLoading}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "48px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: themeStyles.buttonBackground,
                    boxShadow: themeStyles.buttonBoxShadow,
                    ":hover": {
                      background: themeStyles.buttonHoverBackground,
                      boxShadow: themeStyles.buttonHoverBoxShadow,
                    },
                    ":active": {
                      background: themeStyles.buttonActiveBackground,
                      boxShadow: themeStyles.buttonActiveBoxShadow,
                    },
                  }}
                >
                  <Typography
                    variant={"body2"}
                    sx={{
                      color: `${themeStyles.buttonColor} !important`,
                      fontSize: "16px !important",
                      textTransform: "none",
                    }}
                  >
                    {isLoading ? "Carregando..." : "Cadastrar"}
                  </Typography>
                </Box>
              </Button>
              <Typography
                sx={{
                  marginTop: "8px",
                  color: themeStyles.textColor,
                  fontSize: "12px",
                  fontWeight: "300",
                }}
              >
                Ao continuar, você está de acordo e ciente da nossa{" "}
                <Link
                  sx={{
                    textDecoration: "underline",
                    fontWeight: 600,
                    color: themeStyles.textColor + " !important",
                    ":hover": {
                      color: themeStyles.textColor + " !important",
                    },
                  }}
                  href="https://plataformaoceano.com.br/politica-de-privacidade/"
                  target="_blank"
                >
                  Política de Privacidade
                </Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Register;
