import styled from "styled-components";
import { devices } from "../../utils/constants";

interface ImageProps {
  img: string;
}

interface ImageCardModalProps {
  img: string;
  sizeImage: "large" | "small";
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const ImageContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
`;

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainer}:hover & {
    transform: scale(1.2);
  }
`;

export const ImageLarge = styled(Image)<ImageProps>`
  background-image: url(${({ img }) => img});
  width: 24.11719rem;
  height: 29.375rem;

  @media only screen and (max-width: 1245px) {
    width: 12rem;
    height: 23.125rem;
  }

  @media only screen and (${devices.sm}) {
    width: 19rem;
    height: 23.125rem;
  }
`;

export const ImageSmall = styled(Image)<ImageProps>`
  background-image: url(${({ img }) => img});
  width: 16.375rem;
  height: 16.375rem;

  @media only screen and (max-width: 1245px) {
    width: 9rem;
    height: 9rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  margin: 0;

  @media only screen and (max-width: 1245px) {
    font-size: 0.875rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ImageCardModal = styled.div<ImageCardModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${({ img }) => img});
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: ${({ sizeImage }) =>
    sizeImage === "small" ? "70%" : "cover"};
  background-color: #f5f5f5;
  height: 30rem;

  @media only screen and (${devices.md}) {
    height: 21rem;
  }

  @media only screen and (${devices.xs}) {
    height: 12rem;
  }
`;

export const AreaButtonClose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  max-width: 40rem;
  z-index: 100;
  position: absolute;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #090633;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin-top: 1rem;
  margin-right: 1rem;
`;

export const ContextCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  width: 40rem;
  height: 20.3125rem;

  @media only screen and (max-width: 840px) {
    width: 34rem;
  }

  @media only screen and (${devices.md}) {
    width: 22rem;
  }

  @media only screen and (${devices.xs}) {
    width: 19rem;
    gap: 3rem;
  }
`;

export const AreaDescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (${devices.md}) {
    gap: 0.5rem;
    align-items: center;
  }
`;

export const TitleCard = styled.p`
  color: #0f0e17;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const DescriptionCard = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media only screen and (${devices.md}) {
    margin: 0;
  }
`;

export const AreaIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Icon = styled.img`
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
`;

export const AreaSocialMediaCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;

  @media only screen and (${devices.md}) {
    gap: 0.5rem;
    align-items: center;
  }
`;

export const TextSocialMedia = styled.p`
  color: rgba(0, 0, 0, 0.4);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;

export const RowCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 92%;

  @media only screen and (${devices.md}) {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
`;

export const FooterCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 92%;
`;
