import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  ImageContainer,
  AreaImage,
  BackgroundElements,
} from "./styles";
import img68 from "../../../assets/images/68.webp";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";

const Contact = () => {
 

  return (
    <Container>
      <BackgroundElements>
        <Context>
          <ImageContainer>
            <AreaImage url={img68} />
          </ImageContainer>
          <AreaText>
            <Badge
              text="CAMINHO PARA O DESTAQUE"
              color="#1A1198"
              backgroundColor="#EEEDFF"
            />
            <Title>Encontre agora a audiência certa para a sua campanha!</Title>

            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
            <Button
              text="Cadastre-se agora"
              color="#0F0E17"
              backgroundColor="#F8F8F8"
            />
            </a>
          </AreaText>
        </Context>
      </BackgroundElements>
    </Container>
  );
};

export default Contact;
