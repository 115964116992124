import styled, { css } from "styled-components";
import { devices } from "../../utils/constants";

interface MenuProps {
  isOpen: boolean;
}

export const Container = styled.div<MenuProps>`
  display: flex;
  background: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  left: ${({ isOpen }) => (isOpen ? "18.9375rem" : "-100%")};

  @media only screen and (${devices.xs}) {
    left: -100%;
  }
`;

export const MenuContainer = styled.div<MenuProps>`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  height: 100%;
  width: ${({ isOpen }) => (isOpen ? "18.9375rem" : "0")};
  transition: left 0.5s ease-in-out;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;

  @media only screen and (${devices.xs}) {
    width: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.19rem;

  @media only screen and (${devices.xs}) {
    align-items: flex-end;
    max-width: 80%;
  }
`;

export const AreaMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 11rem;
  margin-top: 3.2rem;

  @media only screen and (${devices.xs}) {
    max-width: 100%;
    margin-top: 2.5rem;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const TextButtonClose = styled.p`
  color: #090633;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const Title = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-family: Lexend;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const Item = styled.p`
  color: #0f0e17;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  cursor: pointer;
  margin: 0;
  margin-bottom: 1rem;
  text-decoration: none;
`;

export const TextButton = styled.h1`
  color: #2c1dfe;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const Button = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const Footer = styled.div`
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 2rem;

  @media only screen and (${devices.xs}) {
    display: flex;
  }
`;
