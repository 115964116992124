import React from "react";
import SubNavigation from "../../components/SubNavigation";
import MainBanner from "./mainBanner";
import ProfileTwo from "./ProfileTwo";
import SocialMedia from "./SocialMedia";

import "./talent.scss";
import LabelTitleW400H40 from "../../components/label/labelTitelW300H40";
import TalentBanner from "../../components/banner/talentBanner";
import BannerDownloadApp from "../../components/banner/bannerDownloadApp";
import Steps from "../../components/steps/steps";
import TalentMovileBanner from "../../components/banner/talentMovileBanner";

const Talent = () => {
  return (
    <div className="talent">
      <SubNavigation
        headerPage={"INSTITUCIONAL"}
        isAboutUs={false}
        isTalent={true}
        pageSelected={"talent"}
      />
      <MainBanner />
      <div className="mainText">
        <div className="contentMainText">
          <LabelTitleW400H40
            text={"A porta para o "}
            span={"seu sucesso"}
            isSameLane={true}
            text2={" pode estar a um clique de distância!"}
          />
        </div>
      </div>
      <TalentBanner />
      <ProfileTwo />
      <BannerDownloadApp />
      <Steps />
      <TalentMovileBanner />
      <SocialMedia />
    </div>
  );
};

export default Talent;
