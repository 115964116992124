import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Section = styled.div``;

export const Container = styled.div`
  display: flex;
  height: 22.21rem;
  /* padding-left: 120px;
  padding-right: 120px; */
  background-color: #fdfdfd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
    height: 28rem;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.lg}) {
    flex-direction: column;
    gap: 3.25rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  span {
    color: #1d7efe;
  }

  @media only screen and (${devices.sm}) {
    font-size: 1.25rem;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  width: 19.5625rem;

  @media only screen and (${devices.lg}) {
    width: 100%;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const AreaText = styled.div`
  display: flex;
  width: 36.875rem;

  @media only screen and (${devices.lg}) {
    width: 100%;
  }
`;

export const AreaLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  height: 0.0625rem;
  background: rgba(0, 0, 0, 0.1);
  width: 70%;
`;
