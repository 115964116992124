import React from "react";
import arrowRotateIcon from "../../../assets/icons/arrows-rotate-icon.svg";
import calendarIcon from "../../../assets/icons/calendar-icon.svg";
import coinsIcon from "../../../assets/icons/coins-icon-two.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-icon.svg";
import ArrowBottonIcon from "../../../assets/icons/arrow-botton-icon.svg";
import {
  Content,
  Title,
  SubTitle,
  Context,
  AreaCard,
  Card,
  AreaDescriptionCard,
  DescriptionCard,
  TitleCard,
  Icon,
  CardContext,
  AreaIcon,
  AreaIconMobile,
} from "./styles";

const HowItWorks = () => {
  return (
    <Content id="how-it-works-page">
      <Context>
        <Title>
          Entenda <span>como funciona</span> o token
        </Title>
        <SubTitle>
          Aqui está um guia passo a passo para resgatar produtos incríveis com
          seu token Oceano
        </SubTitle>
        <CardContext>
          <Card key={1}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={arrowRotateIcon} alt="speaker-icon" height={24} />
                <Icon
                  src={ArrowBottonIcon}
                  alt="arrow-botton-icon"
                  height={60}
                />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={arrowRotateIcon} alt="speaker-icon" height={24} />
                  <Icon
                    src={ArrowRightIcon}
                    alt="arrow-right-icon"
                    width={260}
                  />
                </AreaIcon>
                <TitleCard>Programa de Cashback</TitleCard>
                <DescriptionCard>
                  Você faz parte do Programa de Cashback da Oceano. O resgate é
                  feito de forma fácil e prática, solicite seu acesso.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={2}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={calendarIcon} alt="stars-icon" height={24} />
                <Icon
                  src={ArrowBottonIcon}
                  alt="arrow-botton-icon"
                  height={60}
                />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={calendarIcon} alt="stars-icon" height={24} />
                  <Icon
                    src={ArrowRightIcon}
                    alt="arrow-right-icon"
                    width={260}
                  />
                </AreaIcon>
                <TitleCard>Seus benefícios, no tempo certo</TitleCard>
                <DescriptionCard>
                  No prazo de 1 ano o seu token estará disponível para resgate
                  no Programa de Cashback da Oceano.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={3}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={coinsIcon} alt="image-icon" height={24} />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={coinsIcon} alt="image-icon" height={24} />
                </AreaIcon>
                <TitleCard>Explore as opções de resgate</TitleCard>
                <DescriptionCard>
                  A Plataforma Oceano disponibiliza inúmeras opções de resgate,
                  como produção audiovisual, shows, eventos, campanhas de mídia
                  no rádio e TV, benefícios corporativos e muito mais.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
        </CardContext>
      </Context>
    </Content>
  );
};

export default HowItWorks;
