import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Content = styled.div`
  display: flex;
  height: 27rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #090633;
  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (max-width: 1142px) {
    height: 38rem;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 10%;
    padding-right: 10%;
    height: 64rem;
  }

  @media only screen and (${devices.xs}) {
    height: 62rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  color: #fdfdfd;
  font-family: Lexend;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: start;
  margin: 0;
  margin-bottom: 2.5rem;

  @media only screen and (${devices.xs}) {
    font-size: 1.25rem;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  width: 100%;
  max-width: 46.25rem;
`;

export const TitleCard = styled.h1`
  align-self: stretch;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-top: 1.2rem;

  @media only screen and (${devices.xs}) {
    font-size: 1rem;
  }
`;

export const AreaCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 15.3125rem;

  @media only screen and (${devices.sm}) {
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 15.3125rem;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (${devices.sm}) {
    width: 100%;
  }

  @media only screen and (${devices.xs}) {
    width: 100% !important;
  }
`;

export const AreaDescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  margin-left: 1.06rem;
`;

export const Icon = styled.img`
  margin-top: 0.5rem;
`;

export const CardContext = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1142px) {
    gap: 3rem;
  }

  @media only screen and (max-width: 903px) {
    justify-content: flex-start;
    column-gap: 18%;
  }

  @media only screen and (max-width: 714px) {
    column-gap: 3rem;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 3rem;
`;
