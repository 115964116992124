import axios from "axios";

export default class ApiService {
    private baseUrl: string | undefined;

    constructor() {
        let self = this;
        self.baseUrl = process.env.REACT_APP_BASE_URL;
    }
    
    public post<T1, T2>(request: T1, endpoint: string): Promise<T2> {
        let self = this;
        
        let headersConfig: any = {
            'Content-Type': 'application/json',
        };

        let url = self.baseUrl + endpoint;

        let config = {
            headers: headersConfig
        }

        return new Promise<T2>((resolve, reject) => {
            axios.post(url, request, config)
                .then((resonse: any) => {
                    resolve(resonse.data);
                }).catch((error: any) => {
                    reject(error.response.data);
                });
        })
    }
}