import React, { useState } from "react";
import "./serviceTokenLoginStyle.scss";

import LogoBlue from "../../components/logo/logoBlue";
import LogoWhite from "../../components/logo/logo";
import LoginForm from "../../components/form/loginForm";
import BannerModelLogin from "../../components/banner/bannerModelLogin";
import serviceTokenLoginController from "./serviceTokenLoginController";
import { User } from "../../types/types";
import ForgetPasswordModal from "../../components/Modal/forgetPasswordModal";

const ServiceTokenLogin = () => {
  const controller: serviceTokenLoginController = new serviceTokenLoginController();
  const [isForgetPassword, isForgetPasswordSet] = useState<boolean>(false);

  return (
    <div className="ServiceTokenLoginContent">
      <div className="leftConten">
        <LogoBlue />
        <LogoWhite />
        <LoginForm
          callback={(user: User, remember: boolean) => controller.tokenGet(user, remember)}
          callbackShowForgetPassword={isForgetPasswordSet}
        />
      </div>
      <div className="rightContent">
        <BannerModelLogin />
      </div>
      {isForgetPassword ? (
        <ForgetPasswordModal
          callback={() => isForgetPasswordSet(false)}
          controller={controller}
        />
      ) : null}
    </div>
  );
};

export default ServiceTokenLogin;
