import styled from "styled-components";

interface LineProps {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const StartLine = styled.div<LineProps>`
  display: flex;
  height: 0.5rem;
  width: 80%;
  background: ${({ color }) => color ? color : '#9C1DFE'};
`;

export const EndLine = styled.div<LineProps>`
  display: flex;
  height: 0.5rem;
  width: 20%;
  background: ${({ color }) => color ? color : '#5E1198'};
`;
