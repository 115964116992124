import React from "react";

import partnerAna from '../../assets/images/partnerAna.webp'
import partnerMaisLaser from '../../assets/images/partnerMaisLaser.webp'
import partnerUltra from '../../assets/images/partnerUltra.webp'
import partnerCabelo from '../../assets/images/partnerCabelo.webp'
import partnerTargifor from '../../assets/images/partnerTargifor.webp'
import partnerFQM from '../../assets/images/partnerFQM.webp'
import partnerUber from '../../assets/images/partnerUber.webp'

import './partner.scss';

const PartnerLogo = () => {
    return (
        <div className="logos">
            <img src={partnerAna} className="h40" alt="" />
            <img src={partnerMaisLaser} className="h40" alt="" />
            <img src={partnerUltra} className="h40" alt="" />
            <img src={partnerCabelo} className="h100" alt="" />
            <img src={partnerTargifor} className="h40" alt="" />
            <img src={partnerFQM} className="h40" alt="" />
            <img src={partnerUber} className="h40" alt="" />
        </div>
    );
}

export default PartnerLogo;
