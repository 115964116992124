import React from "react";

import './contracts.scss';
import LabelTitleW400H40 from "../label/labelTitelW300H40";
import ContactText from "../policy/contactText";

const ContactPolicy = () => (
    <div className="privacyPolicy">
        <div className="title">
            <div className="titleHeader">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="80" height="80" rx="4" fill="#EDF5FF" />
                    <path d="M32.8125 22.9531L35.9375 30.4531C36.4844 31.7031 36.1719 33.1875 35.0781 34.0469L31.25 37.25C33.8281 42.7188 38.2812 47.1719 43.75 49.75L46.9531 45.9219C47.8125 44.8281 49.2969 44.5156 50.5469 45.0625L58.0469 48.1875C59.5312 48.7344 60.2344 50.375 59.8438 51.8594L57.9688 58.7344C57.5781 60.0625 56.4062 61 55 61C35.625 61 20 45.375 20 26C20 24.5938 20.9375 23.4219 22.2656 23.0312L29.1406 21.1562C30.625 20.7656 32.2656 21.4688 32.8125 22.9531Z" fill="#2C1DFE" />
                </svg>
                <LabelTitleW400H40 text={"Contato"} text2={""} span={""} isSameLane={true} />
            </div>
        </div>
        <ContactText />
    </div>
)

export default ContactPolicy;