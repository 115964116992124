import styled from "styled-components";
import img43 from "../../../assets/images/bannerPersonalitie.png";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 32rem;
  background-color: #1f0633;
  position:relative;
  justify-content: end;

  @media only screen and (max-width: 960px) {
    padding: 0;
  }

 
  @media(max-width:768px){
    height:auto;
    margin-top:5rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 7%;

  @media only screen and (max-width: 1423px) {
    background-position-x: 140%;
  }

  @media only screen and (max-width: 960px) {
    position: relative;
    border-radius: 0;
    padding: 0;
    height: 48rem;
  }

  @media only screen and (${devices.md}) {
    height: 37rem;
  }
`;

export const BackgroundImage = styled.img`
  width: 50%;
  height: 100%;
  align-self: flex-end;
  @media(max-width:768px){
    display:none;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 43.25rem;
  position:absolute;
  z-index:10;
  left:5rem;
  top:50%;
  transform:translateY(-50%);
  

  @media only screen and (max-width: 1170px) {
    max-width: 36.25rem;
  }
  @media(max-width:768px){
    position:static;
    left:none;
    top:none;
    transform:none;
    max-width:inherit
  }

`;

export const AreaDescription = styled.div`
  width: 100%;
  height: 9.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  @media only screen and (max-width: 960px) {
    border-radius: 0;
    height: 14.6rem;
    align-items: flex-start;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9c1dfe;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media only screen and (max-width: 960px) {
    border-radius: 0;
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33rem;
  gap: 0.5rem;

  @media only screen and (max-width: 960px) {
    max-width: 90%;
    padding-left: 7%;
    padding-right: 3%;
  }
`;
