import React, { useState } from 'react';
import './checkbox.scss';
import LabelW500H10 from '../label/labelW500H10';

interface props {
    remember: boolean;
    rememberSet: any;
}

const CheckboxPrimary = ({ remember, rememberSet }: props) => {
    const [isChecked, isCheckedSet] = useState<boolean>(false);

    const checkedButton = (): void => {
        remember = !isChecked;
        isCheckedSet(remember);
        rememberSet(remember);
    }

    return (
        <div className='checkboxComponent'>
            <div className="checkboxPrimary" onClick={checkedButton}>
                <input type="checkbox" checked={isChecked} />
                <span className="checkmark"></span>
            </div>
            <LabelW500H10 text={'Lembrar-me'} color={'blue'} />
        </div>
    );
}

export default CheckboxPrimary;