import React, { ReactElement, createContext, useState } from "react";
import Component from "./Component";

export const ForgotPasswordDialogContext = createContext<{
  actions: {
    open: () => void;
    close: () => void;
    setStep: (step: string) => void;
    setPayloadEmail: (email: string) => void;
    setPayloadPassword: (email: string) => void;
    setSignedIn: (signed: boolean) => void;
  };
  store: {
    step: string;
    open: boolean;
    payloadEmail: string;
    payloadPassword: string;
    signedIn: boolean;
  };
}>({
  // @ts-ignore
  actions: {},
  store: {
    step: "",
    open: false,
    payloadEmail: "",
    payloadPassword: "",
    signedIn: false,
  },
});

interface Props {
  children: ReactElement;
}

export const ForgotPasswordDialogProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState("email");
  const [payloadEmail, setPayloadEmail] = useState("");
  const [payloadPassword, setPayloadPassword] = useState("");
  const [signedIn, setSignedIn] = useState(false);

  const open = () => {
    setStep("email");
    setPayloadPassword("");
    setPayloadEmail("");
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <ForgotPasswordDialogContext.Provider
      value={{
        actions: {
          open,
          close,
          setStep,
          setPayloadEmail,
          setPayloadPassword,
          setSignedIn,
        },
        store: {
          step: step,
          open: isOpen,
          payloadEmail: payloadEmail,
          payloadPassword: payloadPassword,
          signedIn: signedIn,
        },
      }}
    >
      {isOpen && <Component />}
      {children}
    </ForgotPasswordDialogContext.Provider>
  );
};
