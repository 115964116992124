import styled from "styled-components";

interface ContentProps {
  color: string;
}

interface TextProps {
  color: string;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: flex-start;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background: ${({ color }) => color};
  width: max-content;
`;

export const Text = styled.p<TextProps>`
  color: ${({ color }) => color};
  font-family: Lexend;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
`;
