import React from "react";

import './bannerStyle.scss';
import mobile from '../../assets/images/mobile-talent.webp';
import google from '../../assets/icons/google-play-white-icon.svg';
import apple from '../../assets/icons/apple-store-white-icon.svg';

import LabelW300H32 from "../label/labelW300H32";
import LabelW400H16 from "../label/labelW400H16";

const TalentMovileBanner = () => {
  const goStore = (url: string) => {
    if (url === 'google')
      window.open('https://play.google.com/store/apps/details?id=oceano.mobile1233', '_blank');
    else if (url === 'apple')
      window.open('https://apps.apple.com/br/app/oceano/id1629763551', '_blank');
  }

  return (
    <div className="talentMovileBanner">
      <div className="image">
        <img src={mobile} alt="app-mobile" />
      </div>
      <div className="description">
        <LabelW300H32 text={"Encontre oportunidades fantásticas na Oceano!"} />
        <div className="stores">
          <LabelW400H16 text={"BAIXE O APP"} isWhite={true} />
          <div className="icons">
            <img src={google} alt="" onClick={() => goStore('google')} />
            <img src={apple} alt="" onClick={() => goStore('apple')} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalentMovileBanner;
