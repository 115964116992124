import styled from "styled-components";
import img77 from "../../../assets/images/77.webp";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 32rem;
  padding: 1.25rem;
  background-color: #fdfdfd;

  @media only screen and (max-width: 1096px) {
    padding: 0;
    height: 52rem;
  }

  @media only screen and (${devices.md}) {
    margin-top: 5rem;
  }

  @media only screen and (max-width: 620px) {
    height: 49rem;
  }

  @media only screen and (max-width: 535px) {
    height: 44rem;
  }

  @media only screen and (${devices.xs}) {
    height: 38rem;
  }

  @media only screen and (max-width: 360px) {
    height: 36rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 30rem;
  border-radius: 0.25rem;
  background-image: url(${img77});
  background-position-x: 105%;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: 68%;
  background-color: #1f0633;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 7%;

  @media only screen and (max-width: 1423px) {
    background-position-x: 140%;
  }

  @media only screen and (max-width: 1297px) {
    background-position-x: 152%;
    background-size: 73%;
  }

  @media only screen and (max-width: 1212px) {
    background-position-x: 170%;
    background-size: 78%;
  }

  @media only screen and (max-width: 1137px) {
    background-position-x: 184%;
    background-size: 81%;
  }

  @media only screen and (max-width: 1096px) {
    position: relative;
    border-radius: 0;
    padding: 0;
    height: 48rem;
  }

  @media only screen and (${devices.md}) {
    height: 44rem;
  }

  @media only screen and (${devices.xs}) {
    height: 34rem;
  }
`;

export const BackgroundImage = styled.div`
  display: none;
  width: 100%;
  height: 28.4rem;
  background-image: url(${img77});
  background-position-x: 50%;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #fdfdfd;
  position: absolute;
  bottom: 0;

  @media only screen and (max-width: 1096px) {
    display: flex;
  }

  @media only screen and (${devices.md}) {
    height: 24.4rem;
  }

  @media only screen and (max-width: 339px) {
    height: 23.4rem;
  }

  @media only screen and (${devices.xs}) {
    height: 14.4rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 43.25rem;

  @media only screen and (max-width: 1212px) {
    max-width: 36.25rem;
  }

  @media only screen and (max-width: 1096px) {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
`;

export const AreaDescription = styled.div`
  width: 100%;
  height: 12.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  @media only screen and (max-width: 1096px) {
    border-radius: 0;
    height: 14.6rem;
    align-items: flex-start;
  }

  @media only screen and (max-width: 349px) {
    height: 14.6rem;
  }

  @media only screen and (max-width: 339px) {
    height: 15.6rem;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c1dfe;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media only screen and (max-width: 1096px) {
    border-radius: 0;
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33rem;
  gap: 0.5rem;

  @media only screen and (max-width: 1096px) {
    max-width: 90%;
    padding-left: 7%;
    padding-right: 3%;
  }
`;
