import React, { useState } from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  ImageContainer,
  AreaImage,
  Description,
} from "./styles";
import img70 from "../../../assets/images/70.webp";
import Badge from "../../../components/Badge";
import TextButton from "../../../components/TextButton";
import arrowRightIcon from "../../../assets/icons/circle-arrow-right-blue.svg";
import Register from "../../../components/Register";

const DiscoverThePlatform = () => {
  const [toggleRegisterModal, setToggleRegisterModal] =
    useState<boolean>(false);

  return (
    <Container>
      <Context>
        <AreaText>
          <Badge
            text="REVOLUÇÃO DO MARKETING"
            color="#114C98"
            backgroundColor="#EDF5FF"
          />
          <Title>
            Conheça a <span>Plataforma Oceano</span>
          </Title>
          <Description>
            O seu portal de acesso aos talentos de todo o Brasil, agora reunidos
            em uma plataforma intuitiva e eficiente. Sabemos que encontrar a
            pessoa certa para dar vida à sua campanha é um elemento fundamental
            para o seu sucesso. É por isso que criamos a Oceano, especialmente
            para você.
          </Description>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
          <TextButton
            color="#090633"
            icon={arrowRightIcon}
            text="QUERO ENCONTRAR TALENTOS"
          />
          </a>
        </AreaText>
        <ImageContainer>
          <AreaImage url={img70} />
        </ImageContainer>
      </Context>
      <Register
        toggleModal={toggleRegisterModal}
        setToggleModal={setToggleRegisterModal}
      />
    </Container>
  );
};

export default DiscoverThePlatform;
