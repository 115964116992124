import styled, { StyledObject } from "styled-components";

interface TextProps {
  color: string;
}

interface ContainerProps {
  style?: StyledObject;
}

export const Text = styled.h1<TextProps>`
  color: ${({ color }) => color};
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &:hover {
    font-weight: 500;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  ${({ style }) => {
    if (style) {
      return style;
    }
  }}

  cursor: pointer;
`;
