import React, { useState } from "react";
import './input.scss';

interface props {
    finalCodeSet: any;
}

const InputCode = ({ finalCodeSet }: props) => {
    const [code, codeSet] = useState<Array<string>>(['', '', '', '', '']);

    const codeChange = (position: number, e: any): void => {
        code[position] = e.target.value.substr(e.target.value.length - 1).replace(/\D/g, '');
        codeSet(code);

        let codeString: string = '';
        code.forEach(obj => {
            codeString += obj;
        });

        finalCodeSet(codeString);
    }

    return (
        <div className="inputCode">
            <div className="codeInner">
                <input value={code[0]} onChange={(e: any) => codeChange(0, e)} />
            </div>
            <div className="codeInner">
                <input value={code[1]} onChange={(e: any) => codeChange(1, e)} />
            </div>
            <div className="codeInner">
                <input value={code[2]} onChange={(e: any) => codeChange(2, e)} />
            </div>
            <div className="codeInner">
                <input value={code[3]} onChange={(e: any) => codeChange(3, e)} />
            </div>
            <div className="codeInner">
                <input value={code[4]} onChange={(e: any) => codeChange(4, e)} />
            </div>
            <div className="codeInner">
                <input value={code[5]} onChange={(e: any) => codeChange(5, e)} />
            </div>
        </div>
    );
}

export default InputCode;