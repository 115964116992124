import styled from "styled-components";
import { devices } from "../../../utils/constants";
import backgroundImage from "../../../assets/icons/background-solutions-our-services.svg";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 34.8125rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8%;
  background-color: #090633;
  background-image: url(${backgroundImage});
  background-position-x: 100%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 500px) {
    height: 41.8125rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const AreaText = styled.div`
  display: flex;
  width: 56.3125rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`;

export const Title = styled.h1`
  color: #fdfdfd;
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: #2c1dfe;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  margin-top: 2rem;
`;

export const ButtonArea = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 811px) {
    flex-direction: column;
  }
`;
