import React from "react";
import { BtnHeaderText, BtnHeader } from "../styles";

interface ButtonHeaderProps {
  text: string;
  icon?: string;
  onClick?: () => void;
}

const ButtonHeader = ({ text, icon, onClick }: ButtonHeaderProps) => {
  return (
    <BtnHeader onClick={onClick}>
      {icon && <img src={icon} alt={text} />}
      <BtnHeaderText>{text}</BtnHeaderText>
    </BtnHeader>
  );
};

export default ButtonHeader;
