import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    isWhite: boolean;
}

const LabelW400H16 = ({ text, isWhite }: props) => {
    const [color, colorSet] = useState<string>('');

    useEffect(() => {
        let text = isWhite ? 'white' : '';
        colorSet(text);
    }, [isWhite]);

    return (
        <div className={`labelW400H16 ${color}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW400H16;