import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonHeader from "./components/ButtonHeader";
import logo from "../../../assets/icons/logo.svg";
import { HeaderWrapper, Content, ButtonsArea, Img } from "./styles";
import Button from "../Button";
import { URL_SITE } from "../../../utils/constants";

const Navigation = () => {
  const navigate = useNavigate();
  const [isVisible, isVisibleSet] = useState<boolean>(true);

  useEffect(() => {
    isVisibleSet(!window.location.pathname.includes("termsToken"))
  }, [window.location.pathname]);



  const goHome = () => {
    window.scrollTo(0, 0);
    navigate("/");
  };

  const openLink = () => {
    window.open(URL_SITE, "_blank");
  };

  const scrollToSection = (sessionId: string) => {
    const section = document.getElementById(sessionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <HeaderWrapper>
      <Content>
        <Img src={logo} alt="logo" onClick={goHome} />
        <ButtonsArea>
          {
            isVisible ? (
              <>
                <ButtonHeader
                  text="Benefícios"
                  onClick={() => scrollToSection("how-it-works-page")}
                />
                <ButtonHeader
                  text="Conheça a Gabi Matis"
                  onClick={() => scrollToSection("get-to-know-gabi-page")}
                />
              </>
            ) : null
          }
          <Button
            text="Acesse nosso site"
            width="12.1875rem"
            height="2rem"
            onClick={goHome}
          />
        </ButtonsArea>
      </Content>
    </HeaderWrapper>
  );
};

export default Navigation;
