import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 48rem;
  background-color: #fdfdfd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8%;
  gap: 6rem;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 570px) {
    height: 52rem;
  }

  @media only screen and (max-width: 535px) {
    height: 70rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media only screen and (max-width: 1110px) {
    gap: 3rem;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  @media only screen and (max-width: 535px) {
    align-items: center;
    justify-content: center;
  }
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  @media only screen and (${devices.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media only screen and (${devices.md}) {
    flex-direction: row;
    align-items: flex-start;
  }

  @media only screen and (max-width: 535px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardDescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 14.25rem;

  @media only screen and (${devices.md}) {
    max-width: 100%;
    align-items: flex-start;
  }

  @media only screen and (max-width: 535px) {
    align-items: center;
  }
`;

export const TitleCard = styled.h3`
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  margin: 0;

  @media only screen and (max-width: 535px) {
    text-align: center;
  }
`;

export const DescriptionCard = styled.p`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.md}) {
    text-align: start;
  }

  @media only screen and (max-width: 535px) {
    text-align: center;
  }
`;

export const ImageCardArea = styled.div`
  display: flex;
  width: auto;
  justify-content: center;

  @media only screen and (${devices.md}) {
    width: 9rem;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 535px) {
    width: auto !important;
  }
`;

export const ImageCard = styled.img`
  height: 5rem;

  @media only screen and (${devices.md}) {
    height: 3rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 535px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.75rem;
  letter-spacing: 0.0125rem;
  margin: 0;

  span {
    color: #2c1dfe;
  }

  @media only screen and (max-width: 960px) {
    font-size: 2rem;
    line-height: 120%;
  }

  @media only screen and (max-width: 535px) {
    text-align: center;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  margin: 0;

  @media only screen and (max-width: 535px) {
    text-align: center;
  }
`;

export const ImageContainer = styled.div`
  width: 35.937rem;
  height: 16.375rem;
  border-radius: 0.25rem;
  overflow: hidden;
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
`;
