import React from "react";
import wavesIcon from "../../../assets/icons/waves-icon.svg";
import checkIcon from "../../../assets/icons/check-icon.svg";
import {
  Container,
  Context,
  AreaText,
  Title,
  AreaTitle,
  Description,
  AreaItem,
  Line,
  IconWaves,
  Item,
  Icon,
} from "./styles";

const FindTalent = () => {
  return (
    <Container>
      <Context>
        <AreaTitle>
          <IconWaves src={wavesIcon} alt="waves" />
          <Title>
            Na Oceano, temos uma variedade de{" "}
            <span>serviços para potencializar e valorizar sua marca</span>
          </Title>
        </AreaTitle>
        <AreaItem>
          <Line />
          <AreaText>
            <Item>
              <Icon src={checkIcon} alt="checkIcon" />
              <Description>
                Serviços adaptados às necessidades específicas de cada cliente
              </Description>
            </Item>
            <Item>
              <Icon src={checkIcon} alt="checkIcon" />
              <Description>
                Acesso a uma rede de personalidades e influenciadores em
                diversos setores
              </Description>
            </Item>
            <Item>
              <Icon src={checkIcon} alt="checkIcon" />
              <Description>
                Maior alcance da sua marca através de plataformas de mídia de
                alto impacto
              </Description>
            </Item>
            <Item>
              <Icon src={checkIcon} alt="checkIcon" />
              <Description>
                Bônus de cashback para otimizar seus investimentos
              </Description>
            </Item>
            <Item>
              <Icon src={checkIcon} alt="checkIcon" />
              <Description>
                Anúncio em emissoras de rádio e TV para alcançar um público mais
                amplo
              </Description>
            </Item>
            <Item>
              <Icon src={checkIcon} alt="checkIcon" />
              <Description>
                Consultoria para produção de conteúdo através de nossos
                parceiros
              </Description>
            </Item>
          </AreaText>
        </AreaItem>
      </Context>
    </Container>
  );
};

export default FindTalent;
