import React from "react";
import downloadIcon from '../../../assets/icons/download-icon.svg';
import profileIcon from '../../../assets/icons/profile-talent-icon.svg';
import cameraIcon from '../../../assets/icons/camera-talent-icon.svg';
import starIcon from '../../../assets/icons/star-talent-icon.svg';
import ArrowRightIcon from '../../../assets/icons/arrow-icon.svg';
import ArrowBottonIcon from '../../../assets/icons/arrow-botton-icon.svg';
import GoogleLogo from '../../../assets/images/google.webp';
import AppleLogo from '../../../assets/images/apple.webp';
import {
  Content,
  Title,
  SubTitle,
  Context,
  AreaCard,
  Card,
  AreaDescriptionCard,
  DescriptionCard,
  TitleCard,
  Icon,
  AreaButton,
  CardContext,
  AreaIcon,
  AreaIconMobile,
  AreaSubTitle,
  Footer
} from './styles';
import LabelW400H16 from "../../../components/label/labelW400H16";

const ProfileTwo = () => {
  const goStore = (url: string) => {
    if (url === 'google')
      window.open('https://play.google.com/store/apps/details?id=oceano.mobile1233', '_blank');
    else if (url === 'apple')
      window.open('https://apps.apple.com/br/app/oceano/id1629763551', '_blank');
  }

  return (
    <Content>
      <Context>
        <Title>O seu perfil é único, saiba <span>como se destacar</span></Title>
        <AreaSubTitle>
          <SubTitle>Observe o quão descomplicado é o processo de cadastro e como você pode começar a descobrir oportunidades de trabalho imediatamente.</SubTitle>
        </AreaSubTitle>
        <CardContext>
          <Card key={1}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={downloadIcon} alt="speaker-icon" height={24} />
                <Icon src={ArrowBottonIcon} alt="arrow-botton-icon" height={60} />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={downloadIcon} alt="speaker-icon" height={24} />
                  <Icon src={ArrowRightIcon} alt="arrow-right-icon" width={220} />
                </AreaIcon>
                <TitleCard>Baixe o Aplicativo!</TitleCard>
                <DescriptionCard>
                  Acesse a loja de aplicativos do seu celular e procure por "Plataforma Oceano"!
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={2}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={profileIcon} alt="stars-icon" height={24} />
                <Icon src={ArrowBottonIcon} alt="arrow-botton-icon" height={60} />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={profileIcon} alt="stars-icon" height={24} />
                  <Icon src={ArrowRightIcon} alt="arrow-right-icon" width={220} />
                </AreaIcon>
                <TitleCard>Crie seu perfil</TitleCard>
                <DescriptionCard>
                  Em menos de 10 minutos, seu perfil estará pronto para as  melhores campanhas do Brasil!
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={3}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={cameraIcon} alt="image-icon" height={24} />
                <Icon src={ArrowBottonIcon} alt="arrow-botton-icon" height={60} />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={cameraIcon} alt="image-icon" height={24} />
                  <Icon src={ArrowRightIcon} alt="arrow-right-icon" width={220} />
                </AreaIcon>
                <TitleCard>Mostre o seu diferencial</TitleCard>
                <DescriptionCard>
                  Manter seu perfil atualizado é fundamental para encontrar o trabalhos que se adequam a você.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={4}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={starIcon} alt="profiles-icon" height={24} />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={starIcon} alt="profiles-icon" height={24} />
                </AreaIcon>
                <TitleCard>Destaque seu talento!</TitleCard>
                <DescriptionCard>
                  Agora, procure trabalhos e espere o contato dos contratantes para mostrar todo o seu brilho.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
        </CardContext>
      </Context>
      <Footer>
        <LabelW400H16 text={"BAIXE O APP AGORA"} isWhite={false} />
        <AreaButton>
          <Icon src={GoogleLogo} alt="google-play" onClick={() => goStore('google')} />
          <Icon src={AppleLogo} alt="app-store" onClick={() => goStore('apple')} />
        </AreaButton>
      </Footer>
    </Content>
  );
};

export default ProfileTwo;
