import React from "react";
import {
  Container,
  Context,
  Title,
  AreaTitle,
  AreaButton,
  Description,
} from "./styles";
import Badge from "../../../components/Badge";

const Cashback = () => {
  const scrollToSection = () => {
    window.open("https://cadastro.plataformaoceano.com.br/", "_blank");
  };

  return (
    <Container>
      <Context>
        <AreaTitle>
          <Badge
            text="MÍDIA E CASHBACK"
            color="#fff"
            backgroundColor="#2C1DFE"
          />
          <Title>
            Amplifique sua marca divulgando no Rádio e TV e conheça o nosso
            Programa de Cashback
          </Title>
        </AreaTitle>
        <AreaButton>
          <Description>
            Descubra como é fácil destacar sua marca nos principais canais de
            televisão e rádio com nossas estratégias de mídia customizadas,
            alinhadas aos objetivos.
            <br />
            <br /> Além disso, temos ótimas ofertas que garantem um aumento no
            retorno do seu investimento em publicidade, combinando eficiência e
            economia.
          </Description>
        </AreaButton>
      </Context>
    </Container>
  );
};

export default Cashback;
