import React from "react";
import './modal.scss';

import confirm from '../../assets/icons/confirmIconPasswordChange.svg';
import LabelW700H26 from "../label/labelW700H26";
import LabelW400H20 from "../label/labelW400H20";


const ConfirmChangePasswordModal = () => {
    return (
        <div className="confirmChangePasswordModal">
            <img src={confirm} alt="icon" className="icon" />
            <LabelW700H26 text={"Senha alterada!"} color="blue" />
            <LabelW400H20 text={"Sua senha foi alterada com sucesso."} />
        </div>
    );
}

export default ConfirmChangePasswordModal;