import React, { useState, useEffect } from "react";
import chevronRight from "../../assets/icons/chevron-right.svg";
import {
  HeaderWrapper,
  AreaNavigate,
  TextNavigate,
  AreaMenu,
  TextMenu,
} from "./styles";

interface MenuProps {
  id: number;
  description: string;
  link: string;
}

interface props {
  headerPage: string;
  isAboutUs: boolean;
  isTalent: boolean;
  pageSelected: string;
}

const SubNavigation = ({
  headerPage,
  isAboutUs,
  isTalent,
  pageSelected,
}: props) => {
  const [linkHeaderPage, setLinkHeaderPage] = useState<string>("");
  const location = window.location.pathname;
  const menu: MenuProps[] = [
    {
      id: 1,
      description: "Anuncie no Rádio e TV",
      link: "/media",
    },
    {
      id: 2,
      description: "Nossas Personalidades",
      link: "/personalities",
    },
    {
      id: 3,
      description: "Contrate Talentos",
      link: "/service-talent",
    },
    {
      id: 4,
      description: "Sobre Nós",
      link: "/aboutUs",
    },
    {
      id: 6,
      description: "Seja um talento",
      link: "/talent",
    },
  ];

  useEffect(() => {
    if (headerPage === "NOSSOS SERVIÇOS") {
      setLinkHeaderPage("/our-services");
    }
  }, []);

  return (
    <HeaderWrapper>
      <AreaNavigate>
        <TextNavigate to="/">HOME</TextNavigate>
        <img src={chevronRight} alt="chevron-right" />
        <TextNavigate
          isSelected={linkHeaderPage === location}
          to={linkHeaderPage}
        >
          {headerPage}
        </TextNavigate>
      </AreaNavigate>
      <AreaMenu>
        {menu.map(({ id, description, link }) =>
          isTalent && id === 6 ? (
            <TextMenu key={id} to={link} isSelected={link === location}>
              {description}
            </TextMenu>
          ) : isAboutUs && !isTalent && id === 4 ? (
            <TextMenu key={id} to={link} isSelected={link === location}>
              {description}
            </TextMenu>
          ) : !isAboutUs && !isTalent && id <= 3 ? (
            <TextMenu key={id} to={link} isSelected={link === location}>
              {description}
            </TextMenu>
          ) : null
        )}
      </AreaMenu>
    </HeaderWrapper>
  );
};

export default SubNavigation;
