import React, { ReactNode } from 'react';
import { CSSObject } from 'styled-components';
import {
  Overlay,
  ModalContainer,
  CloseButton,
  Header
} from './styles';
import closeIcon from '../../assets/icons/circle-close.svg';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  isButtonClose?: boolean;
  style?: CSSObject;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  isButtonClose,
  style
}: ModalProps) => {
  if (!isOpen) return null;

  return (
    <Overlay>
      <ModalContainer style={style}>
        {isButtonClose && (
          <Header>
            <CloseButton onClick={onClose}>
              <img src={closeIcon} alt='X' /> Fechar
            </CloseButton>
          </Header>
        )}
        {children}
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;
