import React from "react";
import { Content, Context, Text, Timeline, ContextTimeline } from "./styles";

const HowToFindOpportunities = () => {
  return (
    <Content id="section-7">
      <Context>
        <Text>Como encontrar oportunidades</Text>
        <ContextTimeline>
          <Timeline />
        </ContextTimeline>
      </Context>
    </Content>
  );
};

export default HowToFindOpportunities;
