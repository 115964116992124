import styled from "styled-components";
import img69 from '../../../assets/images/69.webp';
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 28rem;
  padding: 1.25rem;
  background-color: #FDFDFD;

  @media only screen and (max-width: 1194px) {
    padding: 0;
    height: 48rem;
  }

  @media only screen and (${devices.md}) {
    margin-top: 5rem;
    height: 37rem;
  }

  @media only screen and (max-width: 439px) {
    height: 33rem;
  }

  @media only screen and (max-width: 382px) {
    height: 32rem;
  }

  @media only screen and (max-width: 353px) {
    height: 31rem;
  }

  @media only screen and (max-width: 325px) {
    height: 30rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 30rem;
  border-radius: 0.25rem;
  background-image: url(${img69});
  background-position-x: 100%;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #090633;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 7%;

  @media only screen and (max-width: 1423px) {
    background-position-x: 140%;
  }

  @media only screen and (max-width: 1230px) {
    background-position-x: 154%;
  }

  @media only screen and (max-width: 1194px) {
    position: relative;
    border-radius: 0;
    padding: 0;
    height: 48rem;
    background-position-x: 100%;
    background-position-y: bottom;
    background-size: 100%;
  }

  @media only screen and (${devices.md}) {
    height: 37rem;
  }

  @media only screen and (max-width: 439px) {
    height: 33rem;
  }

  @media only screen and (max-width: 382px) {
    height: 32rem;
  }

  @media only screen and (max-width: 353px) {
    height: 31rem;
  }

  @media only screen and (max-width: 325px) {
    height: 30rem;
  }
`;

export const BackgroundImage = styled.div`
  display: none;
  width: 100%;
  height: 28.4rem;
  background-image: url(${img69});
  background-position-x: 50%;
  background-position-y: 4%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #1F0633;
  position: absolute;
  bottom: 0;

  @media only screen and (max-width: 1194px) {
    display: flex;
  }

  @media only screen and (${devices.md}) {
    height: 17.4rem;
  }

  @media only screen and (max-width: 439px) {
    height: 13.4rem;
    background-position-y: bottom;
  }

  @media only screen and (max-width: 382px) {
    height: 12.4rem;
  }

  @media only screen and (max-width: 353px) {
    height: 11.4rem;
  }

  @media only screen and (max-width: 325px) {
    height: 10.4rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 43.25rem;

  @media only screen and (max-width: 1194px) {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
`;

export const AreaDescription = styled.div`
  width: 100%;
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FDFDFD;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  @media only screen and (max-width: 1194px) {
    border-radius: 0;
    height: 14.6rem;
    align-items: flex-start;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1D7EFE;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media only screen and (max-width: 1194px) {
    border-radius: 0;
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.80);
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.60);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33rem;
  gap: 0.5rem;

  @media only screen and (max-width: 1194px) {
    max-width: 89%;
    padding-left: 7%;
    padding-right: 4%;
  }
`;
