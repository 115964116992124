import React from "react";

import './labelBG.scss';

interface props {
    text: string;
}

const LabelW300H32 = ({ text }: props) => {
    return (
        <div className="labelW300H32">
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW300H32 ;