import React, { useState, createContext, ReactNode, ReactElement } from "react";
import { iSimpleDialog } from "./index";
import Component from "./Component";

// @ts-ignore
export const SimpleDialogContext = createContext<{
  actions: {
    open: (
      title: string,
      content: string | ReactNode,
      buttonsInfo?: {
        showCancel?: boolean;
        cancelText?: string;
        showOk?: boolean;
        okText?: string;
      },
      callback?: (success: boolean) => void
    ) => void;
    close: () => void;
  };
}>({
  // @ts-ignore
  actions: {},
});

interface Props {
  children: ReactElement;
}

export const SimpleDialogProvider = ({ children }: Props) => {
  const [dialog, setDialog] = useState<iSimpleDialog>({
    content: "",
    title: "",
    showCancel: false,
    cancelText: "Cancelar",
    showOk: false,
    okText: "Confirmar",
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = (
    title: string,
    content: string | ReactNode,
    buttonsInfo?: {
      showCancel?: boolean;
      cancelText?: string;
      showOk?: boolean;
      okText?: string;
    },
    callback?: (success: boolean) => void
  ) => {
    setDialog({
      ...dialog,
      title,
      content,
      ...buttonsInfo,
      callback,
    });
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <SimpleDialogContext.Provider
      value={{
        actions: {
          close,
          open,
        },
      }}
    >
      {isOpen && <Component store={dialog} />}
      {children}
    </SimpleDialogContext.Provider>
  );
};
