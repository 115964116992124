/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingModal from "../../components/Modal/loadingModal";
import { Iframe, Container } from "./styles";

function Register() {
  const { state } = useLocation();
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const receberRespostaDoIframe = (event: any) => {
      const response = event.data;
      if (response === "successfully") {
        // @ts-ignore
        iframeRef.current.contentWindow.postMessage(state, "*");
        setIsLoading(false);
      }
    };

    window.addEventListener("message", receberRespostaDoIframe);

    return () => {
      window.removeEventListener("message", receberRespostaDoIframe);
    };
  }, []);

  return (
    <Container>
      <Iframe
        ref={iframeRef}
        src={`${process.env.REACT_APP_BASE_URL_WEB}cadastro-site`}
      >
        Carregando...
      </Iframe>
      {isLoading && <LoadingModal />}
    </Container>
  );
}

export default Register;
