import styled from "styled-components";
import { devices } from '../../../utils/constants';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 29.125rem;
  background-color: #05031A;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;

  @media only screen and (max-width: 1423px) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @media only screen and (${devices.xl}) {
    height: 40rem;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
  }

  @media only screen and (${devices.md}) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @media only screen and (max-width: 535px) {
    height: 57rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const AreaCopyright = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 2rem;

  @media only screen and (${devices.xl}) {
    justify-content: center;
  }
`;

export const AreaLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    cursor: pointer;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3.75rem;

  @media only screen and (${devices.xl}) {
    flex-direction: column;
  }
`;

export const AreaContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 29.5rem;
  width: 100%;

  @media only screen and (${devices.xl}) {
    max-width: 100%;
  }
`;

export const AreaTermsAndPrivacy = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (${devices.xl}) {
    flex-direction: row;
  }

  @media only screen and (max-width: 535px) {
    flex-direction: column;
    margin-top: 1.5rem;
  }
`;

export const Icon = styled.img`
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  @media only screen and (max-width: 535px) {
    margin-left: 0.5rem;
  }
`;

export const AreaIcons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextCopyright = styled.p`
  color: rgba(255, 255, 255, 0.70);
  font-family: Lexend;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  text-align: center;
`;

export const TitleContact = styled.p`
  color: #FDFDFD;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 535px) {
    font-size: 1rem;
  }
`;

export const TextRadio = styled.label`
  color: #EEEDFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  margin-right: 1.5rem;
`;

export const AreaRadio = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const AreaInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;

  @media only screen and (${devices.xl}) {
    width: 100%;
  }
`;

export const InputType = styled.input`
  cursor: pointer;
`;

export const Input = styled.input`
  display: flex;
  width: 19rem;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 0.5px solid #2C1DFE;
  background: #FFF;
  margin-right: 1.5rem;

  ::placeholder {
    color: #B4AFBF;
    font-family: Lexend;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }

  @media only screen and (max-width: 1423px) {
    margin-right: 0.8rem;
  }

  @media only screen and (${devices.xl}) {
    width: 74%;
    margin-right: 1.5rem;
  }

  @media only screen and (max-width: 535px) {
    margin-right: 0.8rem;
  }
`;

export const Button = styled.button`
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
  border: 0;
  border-radius: 0.25rem;
  background: #2C1DFE;
  width: 9rem;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: '#2c1dfee0';
  }
`;

export const TexteButton = styled.p`
  color: #FFF;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 0;
`;

export const TextTerms = styled.label`
  color: #EEEDFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const Underline = styled.span`
  text-decoration-line: underline;
`;

export const AreaTerms = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;

  @media only screen and (${devices.xl}) {
    margin-right: 1.5rem;
  }

  @media only screen and (max-width: 535px) {
    margin-right: 0;
    margin-top: 0.5rem;
  }
`;

export const Line = styled.div`
  width: 0.0625rem;
  max-height: 16.875rem;
  height: 100%;
  background: #D9D9D9;

  @media only screen and (${devices.xl}) {
    height: 0.0625rem;
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
`;

export const AreaEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 37.4375rem;
  width: 100%;

  @media only screen and (${devices.xl}) {
    justify-content: space-between;
    max-width: 100%;
  }

  @media only screen and (max-width: 535px) {
    display: none;
  }
`;

export const AreaEndMobile = styled.div`
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @media only screen and (max-width: 535px) {
    display: flex;
  }
`;

export const Row = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const AreaService = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (max-width: 535px) {
    margin-bottom: 1.5rem;
  }
`;

export const Title = styled.p`
  color: #FFF;
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const Description = styled.p`
  color: rgba(255, 255, 255, 0.70);
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const DescriptionButton = styled.p`
  color: rgba(255, 255, 255, 0.70);
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  width: 380px;
  height: 505px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 96px;

  @media (max-height: 871px) {
    gap: 48px;
  }
`;

export const ModalContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ModatlContentButton = styled.button`
  background-color: #2C1DFE;
  width: 100%;
  height: 48px;
  color: #FFFFFF;
  border-radius: 4px;
  border: none;
`;
