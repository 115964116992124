import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    isSelect: boolean;
}

const LabelW300H16 = ({ text, isSelect }: props) => {
    const [selectText, selectTextSet] = useState<string>('');

    useEffect(() => {
        let text: string = isSelect ? 'selected' : '';
        selectTextSet(text);
    }, [isSelect]);

    return (
        <div className={`labelW300H16 ${selectText}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW300H16;