import styled from "styled-components";
import ellipseIcon from "../../../assets/images/bannerConnections.png";
import ellipseBottonIcon from "../../../assets/icons/ellipse-backgound-botton.svg";
import ellipseBottonSmallIcon from "../../../assets/icons/ellipse-background-small.svg";

export const Content = styled.section`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  /* margin-top: 7rem; */
  background-color: #fdfdfd;

  /* @media only screen and (max-width: 1070px) {
    height: 53.125rem;
  }

  @media only screen and (max-width: 580px) {
    height: 53.125rem;
  }

  @media only screen and (max-width: 530px) {
    height: 39.125rem;
  }

  @media only screen and (max-width: 490px) {
    height: 43.125rem;
  } */
`;

export const Context = styled.div`
  background-size: auto;
  background-color: #05031a;
  justify-content: space-between;
  border-radius: 0.25rem;
  height: 100%;
  display: flex;
  align-items: center;

  .banner{
    width:100%;
    max-width:36.75rem;
  }


  @media only screen and (max-width: 1070px) {
    flex-direction: column;
    align-items: flex-start;
    background-position: 50% 90%;
    background-size: auto;
  }

  @media only screen and (max-width: 580px) {
    justify-content: center;
  }

`;

export const AreaTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 24.3125rem;
  margin-left: 6.13rem;

  @media only screen and (max-width: 1070px) {
    margin-top: 5rem;
    max-width: 31.125rem;
    margin-left: 3rem;
  }

  @media only screen and (max-width: 580px) {
    max-width: 22rem;
    margin-left: 1rem;
  }

  @media only screen and (max-width: 490px) {
    max-width: 19rem;
  }

  @media only screen and (max-width: 400px) {
    max-width: 14rem;
  }
`;

export const Title = styled.h1`
  align-self: stretch;
  color: #f8f8f8;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 580px) {
    font-size: 1.5rem;
  }
`;

export const Description = styled.p`
  align-self: stretch;
  color: rgba(255, 255, 255, 0.7);
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const TextPurple = styled.span`
  color: #9c1dfe;
`;

export const RectangleTwo = styled.div`
  width: 100%;
  height: 0.5rem;
  flex-shrink: 0;
  border-radius: 0rem 0.25rem 0rem 0rem;
  background: #9c1dfe;
`;

export const RectangleOne = styled.div`
  width: 13.48956rem;
  height: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem 0rem 0rem 0rem;
  background: #3e0c66;
`;

export const AreaRectangle = styled.div`
  display: flex;
  flex-direction: row;
`;
