import styled from "styled-components";
import { devices } from "../../../utils/constants";
import background from "../../../assets/icons/background-ocean-token.svg";

export const Container = styled.div`
  display: flex;
  height: 19.25rem;
  background-image: url(${background});
  background-color: #9c1dfe;
  background-position-x: left;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1170px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media only screen and (${devices.xl}) {
    gap: 2rem;
  }

  @media only screen and (${devices.lg}) {
    gap: 3.25rem;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.0625rem;
  margin: 0;

  span {
    font-weight: 700;
  }

  @media only screen and (${devices.sm}) {
    font-size: 1.25rem;
  }
`;

export const Description = styled.p`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.0625rem;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 1.25rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
  max-width: 36rem;

  @media only screen and (max-width: 1170px) {
    max-width: 100%;
  }
`;
