import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Content = styled.section`
  display: flex;
  height: 61.87rem;
  background-color: #fdfdfd;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @media only screen and (${devices.xl}) {
    height: 52.87rem;
  }

  @media only screen and (${devices.lg}) {
    height: 42rem;
  }

  @media only screen and (${devices.md}) {
    height: 29rem;
  }

  @media only screen and (${devices.sm}) {
    height: 23rem;
  }

  @media only screen and (max-width: 536px) {
    height: 18rem;
  }

  @media only screen and (${devices.xs}) {
    height: 15rem;
  }
`;

export const Context = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 7.56rem;
  text-align: center;

  @media only screen and (${devices.xl}) {
    margin-top: 1.9rem;
  }

  @media only screen and (${devices.md}) {
    margin-top: 4rem;
    font-size: 1.5rem;
  }

  @media only screen and (${devices.sm}) {
    margin-top: 0rem;
  }
`;

export const AreaVideo = styled.div`
  width: 92%;
  max-width: 75rem;
  height: 100%;
  max-height: 42.1875rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  position: absolute;
  top: 27%;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  @media only screen and (${devices.xl}) {
    max-height: 37.1875rem;
  }

  @media only screen and (${devices.lg}) {
    max-height: 30rem;
  }

  @media only screen and (${devices.md}) {
    max-height: 22rem;
  }

  @media only screen and (${devices.sm}) {
    max-height: 17rem;
  }

  @media only screen and (max-width: 536px) {
    max-height: 14rem;
  }

  @media only screen and (${devices.xs}) {
    max-height: 11rem;
    margin-top: 1.5rem;
  }
`;

export const Icon = styled.img`
  position: absolute;
`;

export const Img = styled.img`
  width: 100%;
  height: 96%;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 98%;
  border: none;
  border-radius: 0.5rem;
`;
