import React from "react";

import './labelBG.scss';

interface props {
    text: string;
}

const LabelW400H24 = ({ text }: props) => {
    return (
        <div className="labelW400H24">
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW400H24;