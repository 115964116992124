import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 35rem;
  background-color: #fdfdfd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  /* padding: 0px 120px; */
  padding: 0 8%;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 960px) {
    height: 60rem;
    margin-top: 0;
  }

  @media only screen and (${devices.sm}) {
    height: 54rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  @media only screen and (max-width: 960px) {
    align-items: center;
    gap: 1rem;
    margin-top: 5rem;
  }

  @media only screen and (max-width: 535px) {
    width: 16.44144rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  span {
    color: #1d7efe;
  }

  @media only screen and (max-width: 960px) {
    font-size: 2rem;
    text-align: center;
  }

  @media only screen and (max-width: 535px) {
    font-size: 1.25rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 0.85rem;
  }
`;

export const ImageContainer = styled.div`
  width: 24.125rem;
  height: 24.125rem;
  overflow: hidden;
  border-radius: 0.25rem;

  @media only screen and (max-width: 535px) {
    width: 16.44144rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainer}:hover & {
    transform: scale(1.2);
  }
`;
