import React from "react";

import './courses.scss';

const Courses = () => {
    return (
        <div className="coursesComponent">
            <iframe src="https://plataformaoceano.ensinio.com/browse" className="innerContent"></iframe>
        </div>
    );
}

export default Courses;