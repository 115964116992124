import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const Iframe = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`;
