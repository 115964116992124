import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    btnText?: string;
    color?: string;
    callback?: any
}

const LabelW400H12 = ({ text, btnText = '', color = '', callback = () => { } }: props) => {
    const [textColor, textColorSet] = useState('');

    useEffect(() => {
        textColorSet(color);
    }, []);

    return (
        <div className={`labelW400H12 ${color}`}>
            <p className="labelContent">
                {text}
                {
                    btnText != '' ? (
                        <button className="btn" onClick={callback}>{btnText}</button>
                    ) : null
                }
            </p>
        </div>
    );

}

export default LabelW400H12;