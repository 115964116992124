import React from "react";

import './policy.scss';
import PolicyTerm from "../../components/Navigation/policyTerm";
import { Pages } from "../../types/enums";
import ContactPolicy from "../../components/contracts/contactPolicy";

const Contact = () => {
    return (
        <div className="policy">
            <PolicyTerm page={Pages.contact} />
            <ContactPolicy />
        </div>
    );
}

export default Contact;