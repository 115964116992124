import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  background-color: #fdfdfd;

  padding-left: 8%;
  padding-right: 8%;
  a {
    text-decoration:none;
    width:24rem;
  }
  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media only screen and (max-width: 1245px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  width: 100%;
  max-width: 17.375rem;
  flex-direction: column;

  @media only screen and (max-width: 1245px) {
    max-width: 100%;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-top: 1rem;

  span {
    font-weight: 600;
  }

  @media only screen and (${devices.sm}) {
    font-size: 1.25rem;
  }
`;

export const AreaDescription = styled(AreaTitle)`
  max-width: 36.75rem;

  @media only screen and (max-width: 1245px) {
    max-width: 100%;
    margin-top: 2.5rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 1rem;
  }
`;

export const AreaImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 9%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.sm}) {
    flex-direction: column;
    gap: 3.5rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 5rem;
`;

export const AreaButton = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5rem;
  align-items: center;
  justify-content: center;
`;
