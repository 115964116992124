import styled from "styled-components";

interface ButtonProps {
  color?: string;
  width?: string;
  height?: string;
}

export const Container = styled.div<ButtonProps>`
  display: flex;
  padding: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.25rem;
  background-color: ${({ color }) => (color ? color : "#2C1DFE")};
  max-width: ${({ width }) => width};
  max-height: ${({ height }) => height};
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ color }) => (color ? color : "#2c1dfee0")};
  }
`;

export const Text = styled.h1<ButtonProps>`
  color: ${({ color }) => (color ? color : "#FFF")};
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
  margin: 0;

  ${Container}:hover & {
    font-weight: 500;
  }
`;
