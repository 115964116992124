import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  Description
} from './styles';
import mapIcon from "../../../assets/icons/map-location-icon.svg";
import checkIcon from "../../../assets/icons/badge-check-icon.svg";

const Mission = () => {
  return (
    <Container>
      <Context>
        <AreaText>
          <img src={mapIcon} alt="map-icon" />
          <Title>
            Nossa Missão
          </Title>
          <Description>
            Simplificar o processo de encontrar a combinação perfeita para sua campanha de marketing. Através de nossa plataforma inovadora, você pode explorar uma vasta gama de modelos, influenciadores, atores e apresentadores talentosos, tudo em um só lugar.
          </Description>
        </AreaText>
        <AreaText>
          <img src={checkIcon} alt="check-icon" />
          <Title>
            Facilidade e Eficiência
          </Title>
          <Description>
            Não acreditamos em complicação. É por isso que oferecemos um processo fácil e direto para que você possa publicar suas oportunidades, receber propostas, realizar audições e contratar talentos, tudo sem o incômodo de contratos complexos ou burocracia.
          </Description>
        </AreaText>
      </Context>
    </Container>
  );
};

export default Mission;
