import styled from "styled-components";
import { devices } from "../../../utils/constants";
import circlesBackground from "../../../assets/icons/circles-background.svg";
// import wavesBackground from '../../../assets/icons/waves-background.svg';

interface ButtonProps {
  image: string;
}

export const Content = styled.section`
  height: 37.44rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  /* background-image: url();
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: auto; */

  @media only screen and (max-width: 1200px) {
    height: 30rem;
  }

  @media only screen and (${devices.sm}) {
    height: 36rem;
  }
`;

export const Context = styled.div`
  background-image: url(${circlesBackground});
  background-color: #090633;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  height: 100%;
  max-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (${devices.md}) {
    justify-content: center;
  }

  @media only screen and (${devices.sm}) {
    flex-direction: column;
    justify-content: flex-end;
    max-height: 34rem;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25.5rem;

  @media only screen and (max-width: 900px) {
    max-width: 18.6rem;
  }

  @media only screen and (max-width: 321px) {
    max-width: 16.6rem;
  }
`;

export const Title = styled.h1`
  color: #f8f8f8;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.lg}) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 321px) {
    font-size: 1rem;
    text-align: center;
  }
`;

export const Description = styled.p`
  color: #f8f8f8;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-top: 3rem;

  @media only screen and (${devices.lg}) {
    font-size: 0.8rem;
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

export const Button = styled.div<ButtonProps>`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 2.5rem;
  width: 8.125rem;
  margin-right: 1rem;

  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  }
`;

export const Img = styled.img`
  max-width: 40.25rem;
  max-height: 37.4375rem;
  margin-top: 8rem;

  @media only screen and (max-width: 1200px) {
    max-width: 27.25rem;
    max-height: 26.4375rem;
    margin-top: 0rem;
  }

  @media only screen and (${devices.lg}) {
    width: 20.9375rem;
    height: 19.5625rem;
  }

  @media only screen and (${devices.md}) {
    width: 18rem;
    height: 17rem;
  }
`;
