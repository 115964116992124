import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Content = styled.div`
  display: flex;
  height: 20rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fdfdfd;
  /* padding-left: 120px;
  padding-right: 120px; */
  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }

  @media only screen and (max-width: 1211px) {
    height: 46rem;
  }

  @media only screen and (${devices.xs}) {
    height: 54rem;
    padding-right: 5%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: start;
  margin: 0;
  margin-bottom: 2.5rem;
`;

export const TitleCard = styled.h1`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.9);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-top: 1.2rem;
`;

export const TextBlue = styled.span`
  color: #2c1dfe;
`;

export const DescriptionCard = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.4);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  margin-top: 1.5rem;
`;

export const AreaCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 20.625rem;

  @media only screen and (max-width: 1211px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 20.625rem;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 1211px) {
    width: 100%;
  }
`;

export const AreaDescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  margin-left: 1.06rem;
`;

export const Icon = styled.img`
  margin-top: 1.5rem;
`;

export const CardContext = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
