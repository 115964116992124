import React, { useState } from "react";
import instagramIcon from "../../../assets/icons/instagram-white-icon.svg";
import facebookIcon from "../../../assets/icons/facebook-white-icon.svg";
import youtubeIcon from "../../../assets/icons/youtube-white-icon.svg";
import linkedinIcon from "../../../assets/icons/linkedin-white-icon.svg";
import logo from "../../../assets/icons/logo-icon.svg";
import {
  Content,
  Context,
  AreaCopyright,
  AreaIcons,
  AreaLogo,
  Icon,
  TextCopyright,
  Body,
  AreaContact,
  TitleContact,
  AreaRadio,
  TextRadio,
  AreaInput,
  Input,
  Button,
  TexteButton,
  TextTerms,
  Underline,
  AreaTerms,
  Line,
  AreaEnd,
  Row,
  AreaService,
  Title,
  Description,
  AreaTermsAndPrivacy,
  AreaEndMobile,
  DescriptionButton,
  ModalContent,
  ModalContentDescription,
  ModatlContentButton,
  InputType,
} from "./styles";
import { useNavigate } from "react-router-dom";
import Oceano from "../../../types/oceano";
import { Pages, Stores } from "../../../types/enums";
import Modal from "../../../components/Modal";
import LabelW400H32 from "../../../components/label/labelW400H32";
import LabelW300H20 from "../../../components/label/labelW300H20";
import axios from "axios";
import { URL_SHARP } from "../../../utils/constants";

const Footer = () => {
  const navigate = useNavigate();
  const oceano = new Oceano();
  const [isModalOpen, isModalOpenSet] = useState<boolean>(false);
  const [email, emailSet] = useState<string>("");
  const [userType, setUserType] = useState<number>(0);
  const [isAcceptTerms, setIsAcceptTerms] = useState<boolean>(false);
  const [isAcceptReceivedEmail, setIsAcceptReceivedEmail] =
    useState<boolean>(false);

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  const goToPage = (page: Pages) => {
    window.scrollTo(0, 0);
    oceano.goToPage(page, navigate);
  };

  const goToStore = (store: Stores) => {
    oceano.goToStores(store);
  };

  const handleOpenModal = () => {
    isModalOpenSet(true);
  };

  const handleCloseModal = () => {
    isModalOpenSet(false);
  };

  const newsLetterModal = () => {
    if (isModalOpen) return;

    if (!isAcceptTerms || !isAcceptReceivedEmail || !email) {
      return alert("É necessário preencher os campos e aceitar os termos.");
    }

    axios.post(
      "https://api.plataformaoceano.com.br/v1/SharpSpring/InstitutionalReceivedNews",
      {
        item: {
          sharpSpringInstitutionalTypes: userType,
          email,
          isAcceptTerms,
          isAcceptReceivedEmail,
          page: "all",
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    handleOpenModal();
  };

  const changeEmail = (e: any) => {
    emailSet(e.target.value);
  };

  return (
    <Content id="section-13">
      <Context>
        <AreaLogo>
          <img src={logo} alt="logo" onClick={() => goToPage(Pages.home)} />
          <AreaIcons>
            <Icon
              key={1}
              src={instagramIcon}
              alt="instagram"
              onClick={() => openLink("https://instagram.com/plataformaoceano")}
            />
            <Icon
              key={2}
              src={facebookIcon}
              alt="facebook"
              onClick={() =>
                openLink("https://web.facebook.com/plataformaoceano")
              }
            />
            <Icon
              key={3}
              src={youtubeIcon}
              alt="youtube"
              onClick={() =>
                openLink("https://www.youtube.com/@plataformaoceano")
              }
            />
            <Icon
              key={4}
              src={linkedinIcon}
              alt="linkedin"
              onClick={() =>
                openLink("https://www.linkedin.com/company/plataforma-oceano/")
              }
            />
          </AreaIcons>
        </AreaLogo>
        <Body>
          <AreaContact>
            <TitleContact>Fique por dentro das novidades!</TitleContact>
            <AreaRadio>
              <InputType
                type="radio"
                id="talent"
                name="fav_language"
                value="0"
                checked={userType === 0}
                onChange={() => setUserType(0)}
              />
              <TextRadio htmlFor="talent">Sou talento</TextRadio>
              <InputType
                type="radio"
                id="company"
                name="fav_language"
                value="1"
                checked={userType === 1}
                onChange={() => setUserType(1)}
              />
              <TextRadio htmlFor="company">Sou marca</TextRadio>
            </AreaRadio>
            <AreaInput>
              <Input
                placeholder="Seu melhor e-mail"
                name="email"
                value={email}
                onChange={changeEmail}
              />
              <Button onClick={newsLetterModal}>
                <TexteButton>Cadastrar</TexteButton>
              </Button>
            </AreaInput>
            <AreaTermsAndPrivacy>
              <AreaTerms>
                <InputType
                  type="checkbox"
                  id="privacy-policy"
                  name="privacy-policy"
                  value="privacy-policy"
                  checked={isAcceptTerms}
                  onChange={() => setIsAcceptTerms(!isAcceptTerms)}
                />
                <TextTerms htmlFor="privacy-policy">
                  {" "}
                  Eu concordo com os{" "}
                  <Underline>Termos e Política de Privacidade</Underline>
                </TextTerms>
              </AreaTerms>
              <AreaTerms>
                <InputType
                  type="checkbox"
                  id="emails"
                  name="emails"
                  value="emails"
                  checked={isAcceptReceivedEmail}
                  onChange={() =>
                    setIsAcceptReceivedEmail(!isAcceptReceivedEmail)
                  }
                />
                <TextTerms htmlFor="emails">
                  {" "}
                  Eu aceito receber emails com novidades e promoções
                </TextTerms>
              </AreaTerms>
            </AreaTermsAndPrivacy>
          </AreaContact>
          <Line />
          <AreaEnd>
            <Row>
              <AreaService>
                <Title
                  onClick={() => goToPage(Pages.ourservices)}
                  style={{ cursor: "pointer" }}
                >
                  NOSSOS SERVIÇOS
                </Title>
                <DescriptionButton onClick={() => goToPage(Pages.media)}>
                  Anuncie na TV e Rádio
                </DescriptionButton>
                <DescriptionButton
                  onClick={() => goToPage(Pages.personalities)}
                >
                  Nossas Personalidades
                </DescriptionButton>
                <DescriptionButton
                  onClick={() => goToPage(Pages.serviceTalent)}
                >
                  Contratar Talentos
                </DescriptionButton>
                <DescriptionButton
                  onClick={() =>
                    openLink("https://cadastro.plataformaoceano.com.br/")
                  }
                >
                  Acessar Plataforma
                </DescriptionButton>
              </AreaService>
              <AreaService>
                <Title>PARA SUA CARREIRA</Title>
                <DescriptionButton onClick={() => goToPage(Pages.talent)}>
                  Seja um Talento
                </DescriptionButton>
                {/* <DescriptionButton onClick={() => goToPage(Pages.)}>Beleza com Diversidade</DescriptionButton> */}
                <DescriptionButton onClick={() => goToStore(Stores.google)}>
                  Baixar App (Android)
                </DescriptionButton>
                <DescriptionButton onClick={() => goToStore(Stores.apple)}>
                  Baixar App (iOS)
                </DescriptionButton>
              </AreaService>
              <AreaService>
                <Title>INSTITUCIONAL</Title>
                <DescriptionButton onClick={() => goToPage(Pages.aboutUs)}>
                  Sobre Nós
                </DescriptionButton>
                {/* <Description>Blog</Description> */}
              </AreaService>
            </Row>
            <Row style={{ marginTop: "3.88rem" }}>
              <AreaService>
                <Title>CONTATO</Title>
                <Description>atendimento@plataformaoceano.com.br</Description>
                <Description>+55 11 4580-2353</Description>
              </AreaService>
              <AreaService>
                <DescriptionButton onClick={() => goToPage(Pages.policy)}>
                  Política de Privacidade
                </DescriptionButton>
                <DescriptionButton onClick={() => goToPage(Pages.terms)}>
                  Termos de Uso
                </DescriptionButton>
              </AreaService>
            </Row>
          </AreaEnd>
          <AreaEndMobile>
            <AreaService>
              <Title>SERVIÇOS</Title>
              <DescriptionButton onClick={() => goToPage(Pages.media)}>
                Anuncie na TV e Rádio
              </DescriptionButton>
              <DescriptionButton onClick={() => goToPage(Pages.personalities)}>
                Nossas Personalidades
              </DescriptionButton>
              <DescriptionButton onClick={() => goToPage(Pages.serviceTalent)}>
                Contratar Talentos
              </DescriptionButton>
              <DescriptionButton
                onClick={() =>
                  openLink("https://cadastro.plataformaoceano.com.br/")
                }
              >
                Acessar Plataforma
              </DescriptionButton>
            </AreaService>
            <AreaService>
              <Title>PARA SUA CARREIRA</Title>
              <DescriptionButton onClick={() => goToPage(Pages.talent)}>
                Seja um Talento
              </DescriptionButton>
              {/* <DescriptionButton onClick={() => goToPage(Pages.)}>Beleza com Diversidade</DescriptionButton> */}
              <DescriptionButton onClick={() => goToStore(Stores.google)}>
                Baixar App (Android)
              </DescriptionButton>
              <DescriptionButton onClick={() => goToStore(Stores.apple)}>
                Baixar App (iOS)
              </DescriptionButton>
            </AreaService>
            <AreaService>
              <Title>INSTITUCIONAL</Title>
              <DescriptionButton onClick={() => goToPage(Pages.aboutUs)}>
                Sobre Nós
              </DescriptionButton>
              {/* <DescriptionButton>Blog</DescriptionButton> */}
            </AreaService>
            <AreaService>
              <Title>CONTATO</Title>
              <Description>atendimento@plataformaoceano.com.br​</Description>
              <Description>+55 11 4580-2353</Description>
            </AreaService>
            <AreaService>
              <DescriptionButton onClick={() => goToPage(Pages.policy)}>
                Política de Privacidade
              </DescriptionButton>
              <DescriptionButton onClick={() => goToPage(Pages.terms)}>
                Termos de Uso
              </DescriptionButton>
            </AreaService>
          </AreaEndMobile>
        </Body>
        <AreaCopyright>
          <TextCopyright>
            Copyright © 2023 Plataforma Oceano. Todos os direitos reservados.
          </TextCopyright>
        </AreaCopyright>
      </Context>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        isButtonClose
        style={{
          width: "640px",
          height: "704px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalContent>
          <svg
            width="98"
            height="97"
            viewBox="0 0 98 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M96.8125 7.9375L84.8125 85.75C84.625 87.625 83.5 89.3125 81.8125 90.25C80.875 90.625 79.9375 91 78.8125 91C78.0625 91 77.3125 90.8125 76.5625 90.4375L53.6875 80.875L44.125 95.125C43.375 96.4375 42.0625 97 40.75 97C38.6875 97 37 95.3125 37 93.25V75.25C37 73.75 37.375 72.4375 38.125 71.5L79 19L23.875 68.6875L4.5625 60.625C2.5 59.6875 1 57.8125 1 55.375C0.8125 52.75 1.9375 50.875 4 49.75L88 1.9375C89.875 0.8125 92.5 0.8125 94.375 2.125C96.25 3.4375 97.1875 5.6875 96.8125 7.9375Z"
              fill="#2C1DFE"
            />
          </svg>
          <ModalContentDescription>
            <LabelW400H32 text={"Obrigado por se inscrever!"} isCenter={true} />
            <LabelW300H20
              text={
                "Aguarde conteúdos fresquinhos e exclusivos diretamente na sua caixa de entrada. Em breve você receberá as últimas novidades!"
              }
              center={true}
            />
          </ModalContentDescription>
          <ModatlContentButton onClick={handleCloseModal}>
            Voltar
          </ModatlContentButton>
        </ModalContent>
      </Modal>
    </Content>
  );
};

export default Footer;
