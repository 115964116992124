import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 34rem;
  padding: 7%;
  background-color: #090633;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1137px) {
    height: 35.875rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  border-radius: 0.25rem;

  @media only screen and (max-width: 1137px) {
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.xs}) {
    font-size: 1.25rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media only screen and (${devices.xs}) {
    font-size: 1rem;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  width: 26.0625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  flex-shrink: 0;

  @media only screen and (max-width: 1137px) {
    width: 92%;
  }
`;

export const AreaButton = styled.div`
  display: flex;
  width: 28.5rem;

  @media only screen and (max-width: 1137px) {
    width: 92%;
  }
`;
