import React, { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import JsonData from "./data/data.json";
import Personalities from "./pages/Personalities";
import Media from "./pages/Media";
import "./App.css";
import AboutUs from "./pages/aboutUs/AboutUs";
import Talent from "./pages/talent/talent";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import ServiceTalent from "./pages/ServiceTalent";
import Policy from "./pages/policy/policy";
import Terms from "./pages/policy/terms";
import Contact from "./pages/policy/contact";
import ContactToken from "./pages/policy/contactToken";
import ServiceTokenLogin from "./pages/serviceTokenLogin/serviceTokenLogin";
import OceanToken from "./pages/OceanToken";
import LoadingProvider, { LoadingContext } from "./context/loadingContext";
import TermsToken from "./pages/policy/termsToken";
import OurServices from "./pages/OurServices";
import LayoutToken from "./pages/LayoutToken";
import Register from "./pages/Register";
import Courses from "./pages/courses/courses";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  const [landingPageData, setLandingPageData] = useState({});
  const contexLoading = useContext(LoadingContext);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <LoadingProvider>
      <div>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="personalities" element={<Personalities />} />
            <Route path="talent" element={<Talent />} />
            <Route path="media" element={<Media />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="service-talent" element={<ServiceTalent />} />
            <Route path="policy" element={<Policy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="contact" element={<Contact />} />
            <Route path="obrigado" element={<Home />} />
            <Route path="our-services" element={<OurServices />} />
          </Route>
          <Route path="/">
            <Route path="serviceTokenLogin" element={<ServiceTokenLogin />} />
            <Route path="register" element={<Register />} />
            <Route path="/serviceToken" element={<LayoutToken />}>
              <Route index element={<OceanToken />} />
              <Route path="termsToken" element={<TermsToken />} />
              <Route path="contactToken" element={<ContactToken />} />
            </Route>
            <Route path="cursos" element={<Courses />} />
          </Route>
        </Routes>
      </div>
    </LoadingProvider>
  );
}

export default App;
