import React from "react";

import "./logo.scss";
import logo from "../../assets/icons/logo-white.svg";

const Logo = () => {
  return (
    <div className="mainBannerText">
      <img src={logo} alt="" />
    </div>
  );
};

export default Logo;
