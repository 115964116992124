import React from "react";
import {
  Session,
  Container,
  Context,
  AreaText,
  Title,
  Description,
} from "./styles";
import Button from "../../../components/Button";
import { URL_TOKEN } from "../../../utils/constants";

const AccessYourToken = () => {
  const openLink = () => {
    window.open(URL_TOKEN, "_blank");
  };

  return (
    <Session>
      <Container>
        <Context>
          <AreaText>
            <Title>Acesse seu token</Title>
          </AreaText>
          <AreaText>
            <Description>
              Se você já é detentor, clique abaixo e acesse o token em nossa
              carteira oficial
            </Description>
            <Button
              text="Acessar carteira digital"
              backgroundColor="#fff"
              color="#9C1DFE"
              width="16.375rem"
              onClick={openLink}
            />
          </AreaText>
        </Context>
      </Container>
    </Session>
  );
};

export default AccessYourToken;
