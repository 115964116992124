import React from "react";

import "./bannerStyle.scss";

import ret from "../../assets/images/Rectangle139.webp";
import images from "../../assets/icons/image-blue-icon.svg";
import cam from "../../assets/icons/camera-icon.svg";
import mobile from "../../assets/icons/mobile-icon.svg";
import polaroid from "../../assets/images/modelsPolaroid.png";
import LabelW400H24 from "../label/labelW400H24";
import LabelW300H20 from "../label/labelW300H20";

const TalentBanner = () => {
  return (
    <div className="talentBanner">
      <div className="leftElement">
        <img src={ret} alt="" />
        <div className="leftContent">
          <div className="descriptionEl">
            <div className="firstElement">
              <img src={mobile} alt="" />
              <LabelW400H24 text={"Se cadastre gratuitamente"} />
            </div>
            <div className="lastElement">
              <LabelW300H20
                text={
                  "Junte-se à nossa plataforma de forma rápida e fácil, sem nenhum custo. Insira suas fotos e procure trabalhos."
                }
                center={false}
              />
            </div>
          </div>
          <div className="descriptionEl">
            <div className="firstElement">
              <img src={images} alt="" />
              <LabelW400H24 text={"Grandes oportunidades"} />
            </div>
            <div className="lastElement">
              <LabelW300H20
                text={
                  "Ao se cadastrar, você terá acesso a oportunidades incríveis de trabalho, colaborando com marcas de todo o país."
                }
                center={false}
              />
            </div>
          </div>
          <div className="descriptionEl">
            <div className="firstElement">
              <img src={cam} alt="" />
              <LabelW400H24 text={"Não precisa ter book"} />
            </div>
            <div className="lastElement">
              <LabelW300H20
                text={
                  "Você não precisa de um portfólio elaborado para começar. Insira suas fotos e já pode começar a receber campanhas."
                }
                center={false}
              />
            </div>
          </div>
        </div>
      </div>
      <img src={polaroid} className="rightElement" alt="" />
    </div>
  );
};

export default TalentBanner;
