import React from "react";
import { Container, Context, AreaText, Title, Description } from "./styles";

const NewAge = () => {
  return (
    <Container>
      <Context>
        <AreaText>
          <Description>
            Descubra uma nova era na indústria da moda, onde você pode fazer
            parte do sucesso da vencedora do reality Beleza com Diversidade,
            Gabi Matis.
          </Description>
        </AreaText>
        <AreaText>
          <Title>
            Seja parte dessa jornada única,{" "}
            <span>
              colabore com sua carreira e resgate benefícios exclusivos!
            </span>
          </Title>
        </AreaText>
      </Context>
    </Container>
  );
};

export default NewAge;
