import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 48rem;
  background-color: #f8f8f8;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8%;
  gap: 6rem;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 1024px) {
    height: 73rem;
    margin-top: 0;
  }

  @media only screen and (max-width: 535px) {
    height: 68rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    gap: 4rem;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 2.62rem;
    align-items: flex-start;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

export const CardDescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 14.25rem;

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const TitleCard = styled.h3`
  color: rgba(0, 0, 0, 0.8);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
`;

export const DescriptionCard = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 102%;
  margin: 0;
`;

export const ImageCard = styled.img`
  @media only screen and (max-width: 1024px) {
    height: 5rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  @media only screen and (max-width: 1024px) {
    gap: 2rem;
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0625rem;
  text-transform: capitalize;
  margin: 0;

  span {
    color: #9c1dfe;
  }

  @media only screen and (max-width: 535px) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  margin: 0;

  @media only screen and (max-width: 535px) {
    font-size: 1rem;
  }
`;

export const ImageContainer = styled.div`
  width: 35.937rem;
  height: 16.375rem;
  border-radius: 0.25rem;
  overflow: hidden;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 535px) {
    height: 8.6875rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
`;
