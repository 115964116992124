import React, { useEffect, useState } from "react";
import "./modal.scss";
import ForgetPasswordForm from "../form/forgetPasswordForm";
import ActionButton from "../Button/actionButton";
import ForgetPasswordChangePasswordForm from "../form/forgetPasswordChangePasswordForm";
import { User } from "../../types/types";
import ConfirmChangePasswordModal from "./confirmChangePasswordModal";

interface props {
  callback: any;
  controller: any;
}

const ForgetPasswordModal = ({ callback, controller }: props) => {
  const [btnText, btnTextSet] = useState<string>("Confirmar");
  const [isChangePassword, isChangePasswordSet] = useState<boolean>(false);
  const [isConfirm, isConfirmSet] = useState<boolean>(false);
  const [user, userSet] = useState<User>(new User());
  const [code, codeSet] = useState<string>("");
  const [passwordConfirm, passwordConfirmSet] = useState<string>("");
  const [showValidations, showValidationsSet] = useState<boolean>(false);
  const [validations, validationsSet] = useState<Array<boolean>>([false, false, false, false, false, false]);


  useEffect(() => {
    showValidationsSet(false);

    if (user.password != "" || passwordConfirm != "")
      showValidationsSet(true);

      validationsSet(controller.validatePassword(user.password, passwordConfirm));
  }, [passwordConfirm])

  const changeEmail = (email: string) => {
    user.email = email;
    userSet(user);
  };

  const changePassword = (password: string) => {
    user.password = password;
    userSet(user);
    showValidationsSet(false);

    if (password != "" || passwordConfirm != "")
      showValidationsSet(true);

    validationsSet(controller.validatePassword(user.password, passwordConfirm));
  };

  const sendEmail = async () => {
    let success;
    if (!isChangePassword && !isConfirm) {
      success = await controller.ForgotPassword(user);
      isChangePasswordSet(success);
    } else if (!isConfirm) {
      success = await controller.NewPassword(user, passwordConfirm, code);
      btnTextSet("Entrar");
      isConfirmSet(true);
    } else controller.tokenGet(user, false);
  };

  return (
    <div className="modal">
      <div className="forgetContent">
        <div className="close" onClick={callback}>
          <button>x</button>
        </div>
        <div className="forgetInnerContent">
          {!isChangePassword && !isConfirm ? (
            <ForgetPasswordForm
              emailSet={(email: string) => changeEmail(email)}
            />
          ) : !isConfirm ? (
            <ForgetPasswordChangePasswordForm
              codeSet={codeSet}
              passwordSet={(password: string) => changePassword(password)}
              passwordConfirmSet={passwordConfirmSet}
              resendEmailCallback={() => controller.ForgotPassword(user)}
              showValidations={showValidations}
              validations={validations}
            />
          ) : (
            <ConfirmChangePasswordModal />
          )}
          <div className="actionButton">
            <ActionButton text={btnText} callback={sendEmail} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordModal;
