import React, { useState } from "react";
import './input.scss';
import { TextField } from "@mui/material";
import lock from '../../assets/icons/lockIcon.svg';
import okIcon from '../../assets/icons/okIcon.svg';
import eye from '../../assets/icons/eyeIcon.svg';
import eyeClosed from '../../assets/icons/eyeClosedIcon.svg';
import Oceano from "../../types/oceano";

interface props {
    passwordSet: any;
}

const InputPassword = ({ passwordSet }: props) => {
    const oceano = new Oceano();
    const [emailText, emailTextSet] = useState<string>('');
    const [isCorrect, isCorrectSet] = useState<boolean>(true);
    const [isVisible, isVisibleSet] = useState<boolean>(false);

    const style = {
        backgroundColor: '#FFF',
        width: '100%',
        height: '48px',
        borderRadius: '4px',
        fieldset: { borderColor: 'blue' },
        '& .MuiOutlinedInput-root': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
                borderColor:
                    'blue'
            },
            '&.Mui-focused fieldset': {
                borderColor:
                    'blue'
            },
            '& fieldset': {
                // - The <fieldset> inside the Input-root
                borderColor:
                    'blue'
            },
            '&:hover fieldset': {
                borderColor:
                    'blue'
            }
        }
    }

    const emailChange = (e: any): void => {
        emailTextSet(e.target.value);

        isCorrectSet(!oceano.isValidPassword(e.target.value));

        passwordSet(e.target.value);
    }

    const changeVisibility = () => {
        isVisibleSet(!isVisible);
    }

    return (
        <TextField label={emailText ? 'Senha' : ''} variant="outlined" placeholder="Senha" value={emailText} onChange={emailChange}
            type={!isVisible ? "password" : "text"}
            InputProps={{
                startAdornment: (
                    <div className="innerContent">
                        <img src={lock} alt="email" />
                    </div>
                ),
                endAdornment: (
                    <div className="innerContentVerify" >
                        <button className="btnEye" onClick={changeVisibility} >
                            {
                                isVisible ? (
                                    <img src={eyeClosed} alt="close" />
                                ) : (
                                    <img src={eye} alt="open" />
                                )
                            }
                        </button>
                        {
                            !isCorrect ? (
                                <img src={okIcon} alt="ok" />
                            ) : null
                        }
                    </div >
                ),
            }}
            sx={style}
            className="inputPrimary"
        />
    );
}

export default InputPassword;