import React from "react";
import { useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import TextButton from "../../../components/TextButton";
import barIcon from '../../../assets/icons/bar-icon.svg';
import tvIcon from '../../../assets/icons/tv-icon.svg';
import starIcon from '../../../assets/icons/star-icon.svg';
import cameraIcon from '../../../assets/icons/camera-icon.svg';
import arrowRightIcon from '../../../assets/icons/circle-arrow-right-blue.svg';
import {
  Content,
  Title,
  TextBlue,
  Context,
  AreaCard,
  Card,
  AreaDescriptionCard,
  DescriptionCard,
  TitleCard,
  Icon,
  Space
} from './styles';

const Opportunities = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    window.scrollTo(0, 0);
    navigate(route);
  };

  const responsive = {
    0: {
      items: 10,
      itemsFit: 'contain',
    },
  };

  const items = [
    <Card key={1}>
      <AreaCard>
        <img src={barIcon} alt="bar-icon" />
        <AreaDescriptionCard>
          <Icon src={tvIcon} alt="tv-icon" height={24} />
          <TitleCard>Coloque sua marca na TV e na Rádio</TitleCard>
          <DescriptionCard>
            Com o apoio de uma equipe de especialistas, a sua campanha vai poder ser veiculada em grandes emissoras do país com o menor preço do mercado.
          </DescriptionCard>
          <TextButton text="DESCUBRA COMO" icon={arrowRightIcon} color="#090633" style={{ marginTop: '1.3rem' }} onClick={() => handleNavigate('/media')} />
        </AreaDescriptionCard>
      </AreaCard>
      <Space />
    </Card>,
    <Card key={2}>
      <AreaCard>
        <img src={barIcon} alt="bar-icon" />
        <AreaDescriptionCard>
          <Icon src={starIcon} alt="star-icon" height={24} />
          <TitleCard>Conheça as nossas personalidades</TitleCard>
          <DescriptionCard>
            Na Oceano temos um portfólio de personalidades que vai fazer a sua campanha ter ainda mais visibilidade. Nomes como Ana Hickmann e Cleber Machado.
          </DescriptionCard>
          <TextButton text="VEJA NOSSO PORTFÓLIO" icon={arrowRightIcon} color="#090633" style={{ marginTop: '1.3rem' }} onClick={() => handleNavigate('/personalities')} />
        </AreaDescriptionCard>
      </AreaCard>
      <Space />
    </Card>,
    <Card key={3}>
      <AreaCard>
        <img src={barIcon} alt="bar-icon" />
        <AreaDescriptionCard>
          <Icon src={cameraIcon} alt="camera-icon" height={24} />
          <TitleCard>Contrate modelos ou influenciadores</TitleCard>
          <DescriptionCard>
            Com mais de 3000 profissionais disponíveis para trabalhos de modelo, influencers, narrador, etc. Você pode encontrar hoje a pessoa perfeita para sua campanha.
          </DescriptionCard>
          <TextButton text="CADASTRE-SE NA PLATAFORMA" icon={arrowRightIcon} color="#090633" style={{ marginTop: '1.3rem' }} onClick={() => handleNavigate('/service-talent')} />
        </AreaDescriptionCard>
      </AreaCard>
      <Space />
    </Card>
  ];

  return (
    <Content id="section-5" >
      <Context>
        <Title>Explore cada <TextBlue>oportunidade</TextBlue></Title>
        <AliceCarousel
          mouseTracking
          disableButtonsControls
          disableDotsControls
          autoWidth
          items={items}
          responsive={responsive}
        />
      </Context>
    </Content>
  );
};

export default Opportunities;
