import React from "react";
import coinsIcon from "../../../assets/icons/coins-icon.svg";
import chartIcon from "../../../assets/icons/chart-icon.svg";
import tvIcon from "../../../assets/icons/tv-retro-icon.svg";
import {
  Content,
  Title,
  TextBlue,
  Context,
  AreaCard,
  Card,
  AreaDescriptionCard,
  DescriptionCard,
  TitleCard,
  Icon,
  CardContext,
} from "./styles";

const Opportunities = () => {
  return (
    <Content id="section-opportunities-media">
      <Context>
        <Title>
          Destaque-se e desfrute de <TextBlue>vantagens</TextBlue> de mercado
        </Title>
        <CardContext>
          <Card key={1}>
            <AreaCard>
              <AreaDescriptionCard>
                <Icon src={coinsIcon} alt="coins-icon" height={24} />
                <TitleCard>Valores Acessíveis</TitleCard>
                <DescriptionCard>
                  Oferecemos ótimas condições de mercado para que você possa
                  maximizar o retorno do seu investimento em mídia. Somos a
                  escolha inteligente para quem busca eficiência e otimizar o
                  investimento.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={2}>
            <AreaCard>
              <AreaDescriptionCard>
                <Icon src={chartIcon} alt="chart-icon" height={24} />
                <TitleCard>Planejamento Personalizado</TitleCard>
                <DescriptionCard>
                  Compreendemos que cada marca é única, então você define os
                  seus objetivos e a área de atuação, e nós elaboramos
                  estratégias sob medida para alcançar os melhores resultados.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={3}>
            <AreaCard>
              <AreaDescriptionCard>
                <Icon src={tvIcon} alt="tv-icon" height={24} />
                <TitleCard>Programação Determinada</TitleCard>
                <DescriptionCard>
                  Nossa abordagem à programação é pautada na precisão, seguindo
                  o planejamento de mídia do cliente. Garantimos que seus
                  anúncios alcancem a audiência de grandes emissoras do país.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
        </CardContext>
      </Context>
    </Content>
  );
};

export default Opportunities;
