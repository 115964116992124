import styled, { keyframes } from "styled-components";
import { devices } from '../../../utils/constants';

interface ButtonProps {
  image: string;
}

interface ImageProps {
  url: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Content = styled.section`
  height: 55.31rem;
  background-color: #FDFDFD;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (${devices.lg}) {
    flex-direction: column;
    justify-content: space-between;
    height: 53.31rem;
  }
`;

export const Context = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  width: 38.9375rem;
  position: relative;
  width: 100%;

  @media only screen and (${devices.lg}) {
    align-items: center;
    margin-bottom: 7.5rem;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25.375rem;
  margin-left: 7.56rem;

  @media only screen and (max-width: 1200px) {
    margin-left: 3rem;
  }

  @media only screen and (${devices.lg}) {
    margin-left: 0;
    max-width: 34rem;
    margin-top: 7.5rem;
  }

  @media only screen and (${devices.sm}) {
    max-width: 19rem;
  }

  @media only screen and (max-width: 321px) {
    max-width: 17rem;
  }
`;

export const Title = styled.h1`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.70);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media only screen and (${devices.sm}) {
    font-size: 1.5rem;
  }
`;

export const Description = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.40);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const TextBlue = styled.span`
  color: #2C1DFE;
`;

export const AreaButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Button = styled.div<ButtonProps>`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 2.5rem;
  width: 8.125rem;
  margin-right: 1rem;
  margin-top: 2.5rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  }
`;

export const Rectangle = styled.div`
  width: 31.6875rem;
  height: 40.8125rem;
  flex-shrink: 0;
  border-radius: 2.5rem 0rem 0rem 0rem;
  background: #EAE9EF;
  position: absolute;

  @media only screen and (max-width: 1200px) {
    width: 25.6875rem;
  }

  @media only screen and (${devices.lg}) {
    width: 29rem;
    height: 30.625rem;
    border-radius: 0.25rem;
  }

  @media only screen and (${devices.sm}) {
    width: 19rem;
    height: 21.1875rem;
  }

  @media only screen and (${devices.xs}) {
    width: 16rem;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  flex: 1 0 0;
  align-self: stretch;
`;

export const ImageContainerLarger = styled.div`
  width: 22.3125rem;
  height: 16.78125rem;
  border-radius: 0.25rem;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    width: 19.3125rem;
    height: 14.78125rem;
  }

  @media only screen and (${devices.lg}) {
    width: 24.0625rem;
    height: 13.5625rem;
  }

  @media only screen and (${devices.sm}) {
    width: 14.25rem;
    height: 8.53125rem;
  }

  @media only screen and (${devices.xs}) {
    width: 12.25rem;
  }

  @media only screen and (max-width: 321px) {
    width: 10.5rem;
    height: 8rem;
  }
`;

export const AreaImageLarger = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainerLarger}:hover & {
    transform: scale(1.2);
  }
`;

export const ImageContainerSmall = styled.div`
  width: 13.6875rem;
  height: 16.78125rem;
  border-radius: 0.25rem;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    width: 12.6875rem;
    height: 14.78125rem;
  }

  @media only screen and (${devices.lg}) {
    width: 13.6875rem;
    height: 13.5625rem;
  }

  @media only screen and (${devices.sm}) {
    width: 8.125rem;
    height: 8.53125rem;
  }

  @media only screen and (${devices.xs}) {
    width: 7.125rem;
  }

  @media only screen and (max-width: 321px) {
    width: 7rem;
    height: 8rem;
  }
`;

export const AreaImageSmall = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainerSmall}:hover & {
    transform: scale(1.2);
  }
`;

export const RectangleSmall = styled.div`
  width: 13.6875rem;
  height: 16.78125rem;
  border-radius: 0.25rem;
  background: #9C1DFE;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    width: 12.6875rem;
    height: 14.78125rem;
  }

  @media only screen and (${devices.lg}) {
    width: 13.6875rem;
    height: 13.5625rem;
  }

  @media only screen and (${devices.sm}) {
    width: 8.125rem;
    height: 8.53125rem;
  }

  @media only screen and (${devices.xs}) {
    max-width: 7.125rem;
  }

  @media only screen and (max-width: 321px) {
    max-width: 7rem;
    height: 8rem;
  }
`;

export const Letter = styled.span<{ delay: string }>`
  color: #FDFDFD;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  display: inline-block;
  opacity: 0;
  animation: ${fadeIn} 0.2s forwards;
  animation-delay: ${props => props.delay};
  /* animation-iteration-count: infinite; */

  &:nth-child(1) {
    animation-delay: ${props => props.delay};
  }

  &:nth-child(2) {
    animation-delay: ${props => props.delay + '0.2s'};
  }

  &:nth-child(3) {
    animation-delay: ${props => props.delay + '0.4s'};
  }

  &:nth-child(4) {
    animation-delay: ${props => props.delay + '0.6s'};
  }
`;

export const AreaImages = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  width: 38.9375rem;
  position: absolute;

  @media only screen and (max-width: 1200px) {
    width: 33.9375rem;
  }

  @media only screen and (${devices.lg}) {
    align-items: center;
    width: 39rem;
  }

  @media only screen and (${devices.sm}) {
    width: 23.375rem;
  }

  @media only screen and (${devices.xs}) {
    width: 20.375rem;
  }

  @media only screen and (max-width: 321px) {
    width: 18.375rem;
  }
`;
