import styled from "styled-components";
import { Link } from "react-router-dom";
import { devices } from "../../utils/constants";

interface TextMenuProps {
  isSelected: boolean;
}

export const HeaderWrapper = styled.div`
  height: 4rem;
  margin-top: 5rem;
  border-top: 1px solid #f1eff5;
  background: #f8f8f8;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.md}) {
    display: none;
  }
`;

export const TextNavigate = styled(Link)<{ isSelected?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? "#2C1DFE" : "rgba(0, 0, 0, 0.5)")};
  font-family: Lexend;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
  line-height: 120%;
  text-decoration: none;

  &:hover {
    font-weight: 500;
  }
`;

export const AreaNavigate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const AreaMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;

export const TextMenu = styled(Link)<TextMenuProps>`
  color: ${({ isSelected }) => (isSelected ? "#2C1DFE" : "rgba(0, 0, 0, 0.5)")};
  text-align: center;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
  line-height: 120%;
  text-decoration: none;

  &:hover {
    font-weight: 500;
  }
`;
