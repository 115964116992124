import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Content = styled.section`
  background-color: #fdfdfd;
  margin: 74px 0px 160px 0px;

  @media only screen and (max-width: 860px) {
    height: 31rem;

    .alice-carousel {
      margin-top: 5rem;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 500px) {
    .alice-carousel {
      margin-top: 4rem;
      margin-bottom: 0;
    }
  }
`;

export const Context = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  /* padding: 0px 120px; */
  padding: 0 8%;
  margin-bottom: 96px;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 4rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 860px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 860px) {
    font-size: 0.75rem;
  }
`;

export const TextBlue = styled.span`
  color: #2c1dfe;
  font-weight: 400;
`;

export const AreaTitle = styled.div`
  display: flex;
  max-width: 28rem;

  @media only screen and (max-width: 860px) {
    max-width: 19rem;
  }

  @media only screen and (max-width: 500px) {
    max-width: 16rem;
  }
`;

export const AreaDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 500px) {
    align-items: flex-start;
  }
`;

export const Icon = styled.img`
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  @media only screen and (max-width: 500px) {
    margin-left: 0;
    margin-right: 1rem;
  }
`;

export const AreaIcons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

export const Image = styled.div<ImageProps>`
  width: 20rem;
  height: 20rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.25rem;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;

  @media only screen and (max-width: 860px) {
    width: 12.5rem;
    height: 12.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 10rem;
    height: 10rem;
  }
`;


export const Video = styled.video`
  width: 20rem;
  height: 20rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.25rem;

  @media only screen and (max-width: 860px) {
    width: 12.5rem;
    height: 12.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 10rem;
    height: 10rem;
  }
`;

export const ContentImage = styled.a`
  width: 21rem;
  height: 20rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 860px) {
    width: 13.5rem;
    height: 12.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 10.5rem;
    height: 10rem;
  }
`;

export const Space = styled.div`
  width: 1rem;
  height: 20rem;

  @media only screen and (max-width: 860px) {
    height: 12.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 0.5rem;
    height: 10rem;
  }
`;
