import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  AreaIcons,
  AreaCircle,
  CircleOne,
  CircleTwo,
  Icon,
} from "./styles";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import cameraIcon from "../../../assets/icons/camera-white-icon.svg";
import tvIcon from "../../../assets/icons/tv-white-icon.svg";

const Budget = () => {


  return (
    <Container>
      <Context>
        <AreaText>
          <Badge
            text="A UM CLIQUE DE DISTÂNCIA"
            color="#5E1198"
            backgroundColor="#F7EDFF"
          />
          <Title>
            Personalidades disponíveis para dar um{" "}
            <span>impulso à sua campanha</span> publicitária
          </Title>
          <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
          <Button
            text="Cadastre-se agora"
            width="24rem"
          />
          </a>
        </AreaText>
        <AreaIcons>
          <AreaCircle style={{ justifyContent: "flex-end" }}>
            <CircleOne>
              <CircleTwo />
              <Icon src={cameraIcon} />
            </CircleOne>
          </AreaCircle>
          <AreaCircle>
            <CircleOne>
              <CircleTwo />
              <Icon src={tvIcon} />
            </CircleOne>
          </AreaCircle>
        </AreaIcons>
      </Context>
    </Container>
  );
};

export default Budget;
