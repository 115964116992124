import React from "react";
import Badge from "../../../components/Badge";
import TextButton from "../../../components/TextButton";
import arrowRightIcon from '../../../assets/icons/circle-arrow-right-blue.svg';
import img66 from '../../../assets/images/66.webp';
import img67 from '../../../assets/images/67.webp';
import {
  Content,
  Context,
  ContextDescription,
  AreaImage,
  Column,
  ColumnTwo,
  Rectangle,
  TextRectangle,
  Title,
  Description,
  AreaDescription,
  AreaButtons,
  TextBlue,
  ImageContainer
} from './styles';

const YourBrand = () => {

  return (
    <Content id="section-yourbrand">
      <Context>
        <Column>
          <Rectangle color="#090633">
            <TextRectangle>Em todas as emissoras do Brasil</TextRectangle>
          </Rectangle>
          <ImageContainer>
            <AreaImage url={img67} />
          </ImageContainer>
        </Column>
        <ColumnTwo>
          <ImageContainer>
            <AreaImage url={img66} />
          </ImageContainer>
          <Rectangle color="#2C1DFE">
            <TextRectangle>Com valores que cabem no seu bolso e retorno imediato</TextRectangle>
          </Rectangle>
        </ColumnTwo>
      </Context>
      <ContextDescription>
        <AreaDescription>
          <Badge text="SEU NEGÓCIO = SUCESSO" backgroundColor="#EDF5FF" color="#114C98" />
          <Title>
            Veicule <TextBlue>sua marca em grandes mídias</TextBlue> e vire um sucesso
          </Title>
          <Description>
            Aqui, você tem a chance de promover o seu negócio em canais de televisão e rádio, tudo de forma descomplicada e eficaz. Além disso,  oferecemos suporte completo dos nossos especialistas para garantir o sucesso da sua campanha, com planejamento de mídia personalizado para a sua marca.
          </Description>
        </AreaDescription>
        <AreaButtons>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
          <TextButton
            color="#090633"
            icon={arrowRightIcon}
            text="ANUNCIAR AGORA"
          />
          </a>
        </AreaButtons>
      </ContextDescription>
    </Content>
  );
};

export default YourBrand;
