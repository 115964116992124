import React, { useEffect, useState } from "react";
import GetToKnowOceanToken from "./GetToKnowOceanToken";
import Video from "./Video";
import SocialMedia from "./SocialMedia";
import NewAge from "./NewAge";
import HowItWorks from "./HowItWorks";
import GetToKnowGabi from "./GetToKnowGabi";
import HowToHelp from "./HowToHelp";
import AccessYourToken from "./AccessYourToken";
import OceanTokenController from "./oceanTokenController";

const OceanToken = () => {
  const controller: OceanTokenController = new OceanTokenController();

  return (
    <>
      <GetToKnowOceanToken />
      <NewAge />
      <HowItWorks />
      <GetToKnowGabi />
      <HowToHelp />
      <AccessYourToken />
      <Video />
      <SocialMedia />
    </>
  );
};

export default OceanToken;
