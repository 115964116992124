import React, { useState } from "react";
import Modal from "../Modal";
import instagramIcon from "../../assets/icons/instagram-gray-icon.svg";
import tiktokIcon from "../../assets/icons/tiktok-icon.svg";
import facebookIcon from "../../assets/icons/facebook-gray-icon.svg";
import youtubeIcon from "../../assets/icons/youtube-gray-icon.svg";
import twitterIcon from "../../assets/icons/x-twitter-icon.svg";
import closeIcon from "../../assets/icons/circle-close.svg";
import {
  Container,
  ImageContainer,
  Footer,
  Description,
  ImageLarge,
  ImageSmall,
  ContextCard,
  AreaDescriptionCard,
  DescriptionCard,
  TitleCard,
  AreaSocialMediaCard,
  AreaIcons,
  Icon,
  TextSocialMedia,
  RowCard,
  ImageCardModal,
  Header,
  AreaButtonClose,
  CloseButton,
  FooterCard,
} from "./styles";
import Button from "../Button";

interface NetworksProps {
  id: number;
  type: string;
  value: string;
}

interface NetworkProps {
  id: number;
  alt: string;
  src: string;
  link: string;
}

interface ImageCardProps {
  img: string;
  description?: string;
  size: "large" | "small";
  sizeImage: "large" | "small";
  profession: string;
  networks: NetworksProps[];
}

const ImageCard = ({
  img,
  description,
  size,
  sizeImage,
  networks,
  profession,
}: ImageCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const scrollToSection = () => {
    handleCloseModal();
    const section = document.getElementById("section-enrollment");
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const checkNetworks = (network: NetworksProps): NetworkProps | undefined => {
    switch (network.type) {
      case "instagram":
        return {
          id: network.id,
          alt: "instagram",
          link: network.value,
          src: instagramIcon,
        };
      case "tiktok":
        return {
          id: network.id,
          alt: "tiktok",
          link: network.value,
          src: tiktokIcon,
        };
      case "facebook":
        return {
          id: network.id,
          alt: "facebook",
          link: network.value,
          src: facebookIcon,
        };
      case "youtube":
        return {
          id: network.id,
          alt: "youtube",
          link: network.value,
          src: youtubeIcon,
        };
      case "twitter":
        return {
          id: network.id,
          alt: "twitter",
          link: network.value,
          src: twitterIcon,
        };
    }
  };

  return (
    <>
      <Container onClick={handleOpenModal}>
        <ImageContainer>
          {size === "large" && <ImageLarge img={img} />}
          {size === "small" && <ImageSmall img={img} />}
        </ImageContainer>
        {description && (
          <Footer>
            <Description>{description}</Description>
          </Footer>
        )}
      </Container>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        style={{ padding: 0 }}
      >
        <Header>
          <AreaButtonClose>
            <CloseButton onClick={handleCloseModal}>
              <img src={closeIcon} alt="X" /> Fechar
            </CloseButton>
          </AreaButtonClose>
          <ImageCardModal img={img} sizeImage={sizeImage} />
        </Header>
        <ContextCard>
          <RowCard>
            <AreaDescriptionCard>
              <TitleCard>{description}</TitleCard>
              <DescriptionCard>{profession}</DescriptionCard>
            </AreaDescriptionCard>
            <AreaSocialMediaCard>
              <TextSocialMedia>REDES SOCIAIS</TextSocialMedia>
              <AreaIcons>
                {networks.map((network) => {
                  const result = checkNetworks(network);
                  return (
                    <Icon
                      key={result?.id}
                      src={result?.src}
                      alt={result?.alt}
                      onClick={() => openLink(result?.link || "")}
                    />
                  );
                })}
              </AreaIcons>
            </AreaSocialMediaCard>
          </RowCard>
          <FooterCard style={{ justifyContent: "center" }}>
            <Button
              text="Solicite um orçamento"
              width="76%"
              onClick={scrollToSection}
            />
          </FooterCard>
        </ContextCard>
      </Modal>
    </>
  );
};

export default ImageCard;
