import React from "react";

import "./policyTerm.scss";
import LabelW500H20 from "../label/labelW500H20";
import LabelW300H16 from "../label/labelW300H16";
import { Pages } from "../../types/enums";
import Oceano from "../../types/oceano";
import { useNavigate } from "react-router";

interface props {
  page: Pages;
}

const policyTermToken = ({ page }: props) => {
  const navigate = useNavigate();
  const oceano = new Oceano();

  const goToPage = (page: Pages) => {
    window.scrollTo(0, 0);
    oceano.goToPage(page, navigate);
  };

  return (
    <div className="policyTerm">
      <div className="innerContent">
        <LabelW500H20 text={"Privacidade e Condições de Utilização"} />
        <div className="options">
          <div className="element" onClick={() => goToPage(Pages.termsToken)}>
            {page === Pages.termsToken ? (
              <svg
                width="1"
                height="11"
                viewBox="0 0 1 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="1" height="11" fill="#2C1DFE" />
              </svg>
            ) : null}
            <LabelW300H16
              text={"Termos de Uso"}
              isSelect={page === Pages.termsToken}
            />
          </div>
          <div className="element" onClick={() => goToPage(Pages.contactToken)}>
            {page === Pages.contactToken ? (
              <svg
                width="1"
                height="11"
                viewBox="0 0 1 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="1" height="11" fill="#2C1DFE" />
              </svg>
            ) : null}
            <LabelW300H16
              text={"Contato"}
              isSelect={page === Pages.contactToken}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default policyTermToken;
