import React from "react";

import './labelBG.scss';

interface props {
    text: string;
}

const LabelW400H14 = ({ text }: props) => {
    return (
        <div className="labelW400H14">
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW400H14;