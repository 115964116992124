import React from "react";
import { Container, Text } from "./styles";

interface ButtonProps {
  text: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  color?: string;
  backgroundColor?: string;
  icon?: string;
}

const Button = ({
  text,
  width,
  height,
  onClick,
  color,
  backgroundColor,
  icon,
}: ButtonProps) => {
  return (
    <Container
      id="component-button"
      onClick={onClick}
      width={width}
      height={height}
      color={backgroundColor}
    >
      <Text color={color}>{text}</Text>
      {icon && <img src={icon} alt={text} style={{ marginLeft: "0.5rem" }} />}
    </Container>
  );
};

export default Button;
