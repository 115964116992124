import React from "react";
import girlLogin from "../../assets/images/girlLogin.png";
import "./bannerStyle.scss";

const BannerModelLogin = () => {
  return (
    <div className="BannerModelLoginContent">
      <div className="circles">
        <div className="circle001"></div>
        <div className="circle002"></div>
        <div className="circle003"></div>
      </div>
      <img src={girlLogin} alt="girl" className="imageModel" />
    </div>
  );
};

export default BannerModelLogin;
