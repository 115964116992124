import React, { useState } from "react";
import profileIcon from "../../assets/icons/profile-icon.svg";
import closeIcon from "../../assets/icons/circle-close.svg";
import {
  Container,
  MenuContainer,
  CloseButton,
  TextButtonClose,
  Context,
  AreaMenu,
  Item,
  Title,
  Button,
  Footer,
  TextButton,
} from "./style";
import { Pages } from "../../types/enums";
import Oceano from "../../types/oceano";
import { useNavigate } from "react-router-dom";

interface MenuProps {
  isOpen: boolean;
}

interface ItemsProps {
  title: string;
  link?: Pages;
  items: {
    description: string;
    link: string;
    page: Pages;
  }[];
}

const Menu = ({ isOpen: open }: MenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const oceano = new Oceano();
  const navigate = useNavigate();

  const menu: ItemsProps[] = [
    {
      title: "NOSSOS SERVIÇOS",
      link: Pages.ourservices,
      items: [
        {
          description: "Anuncie na Rádio e TV",
          link: "media",
          page: Pages.media,
        },
        {
          description: "Nossas Personalidades",
          link: "personalities",
          page: Pages.personalities,
        },
        {
          description: "Contratar Talentos",
          link: "service-talent",
          page: Pages.serviceTalent,
        },
        {
          description: "Oceano Tokens",
          link: "serviceToken",
          page: Pages.serviceToken,
        },
        {
          description: "Cashback",
          link: "https://cashback.plataformaoceano.com.br/login",
          page: Pages.none,
        },
        {
          description: "Oceano Cursos",
          link: "https://cursos.plataformaoceano.com.br/",
          page: Pages.couses,
        }, 
        // {
        //   description: "Oceano Cursos",
        //   link: "cursos",
        //   page: Pages.couses,
        // },
      ],
    },
    {
      title: "PARA SUA CARREIRA",
      items: [
        {
          description: "Seja um Talento",
          link: "talent",
          page: Pages.talent,
        },
      ],
    },
    {
      title: "INSITUCIONAL",
      items: [
        {
          description: "Sobre Nós",
          link: "aboutUs",
          page: Pages.aboutUs,
        },
      ],
    },
  ];

  const openLink = (link?: string) => {
    if (link) window.open(link, "_blank");
  };

  const toggleMenu = (page: Pages, link: string) => {
    setIsOpen(!isOpen);
    if (page != Pages.none && page != Pages.couses) oceano.goToPage(page, navigate);
    else if (page === Pages.none) openLink(link);
    else window.open(link, "_self");
  };

  return (
    <Container
      isOpen={open !== !isOpen}
      onClick={() => toggleMenu(Pages.none, "")}
    >
      <MenuContainer id="menu-container" isOpen={open !== !isOpen}>
        <Context>
          <CloseButton onClick={() => toggleMenu(Pages.none, "")}>
            <img src={closeIcon} alt="X" />
            <TextButtonClose>Fechar</TextButtonClose>
          </CloseButton>
          {menu.map(({ title, link, items }) => (
            <AreaMenu>
              <Title
                onClick={() => {
                  if (link) toggleMenu(link, "");
                }}
                style={link ? { cursor: "pointer" } : {}}
              >
                {title}
              </Title>
              {items.map((item) => (
                <Item onClick={() => toggleMenu(item.page, item.link)}>
                  {item.description}
                </Item>
              ))}
            </AreaMenu>
          ))}
          <Footer>
            <Button
              onClick={() =>
                openLink("https://cadastro.plataformaoceano.com.br/")
              }
            >
              <img src={profileIcon} alt="profile-icon" />
              <TextButton>Acesse a Plataforma</TextButton>
            </Button>
          </Footer>
        </Context>
      </MenuContainer>
    </Container>
  );
};

export default Menu;
