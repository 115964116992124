import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && !error.response) {
      throw new Error("Tempo Excedido");
    }

    if ([401, 403].indexOf(error.response.status) === -1) {
      return Promise.reject(error);
    }

    return Promise.reject({
      response: {
        data: {},
      },
    });
  }
);

export default instance;
