import React, { useState } from "react";
import './input.scss';
import { TextField } from "@mui/material";
import email from '../../assets/icons/email.svg';
import okIcon from '../../assets/icons/okIcon.svg';

interface props {
    emailSet: any;
}

const InputEmail = ({ emailSet }: props) => {
    const [emailText, emailTextSet] = useState<string>('');
    const [isCorrect, isCorrectSet] = useState<boolean>(false);

    const style = {
        backgroundColor: '#FFF',
        width: '100%',
        height: '48px',
        borderRadius: '4px',
        fieldset: { borderColor: 'blue' },
        '& .MuiOutlinedInput-root': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
                borderColor:
                    'blue'
            },
            '&.Mui-focused fieldset': {
                borderColor:
                    'blue'
            },
            '& fieldset': {
                // - The <fieldset> inside the Input-root
                borderColor:
                    'blue'
            },
            '&:hover fieldset': {
                borderColor:
                    'blue'
            }
        }
    }

    const emailChange = (e: any): void => {
        emailTextSet(e.target.value);
        if (e.target.value.split('@').length == 2 && e.target.value.split('@')[1] != '')
            isCorrectSet(true);
        else
            isCorrectSet(false);

        emailSet(e.target.value);
    }

    return (
        <TextField label={emailText ? 'Email' : ''} variant="outlined" placeholder="Email" value={emailText} onChange={emailChange}
            InputProps={{
                startAdornment: (
                    <div className="innerContent">
                        <img src={email} alt="email" />
                    </div>
                ),

                endAdornment: (
                    <div className="innerContent" >
                        {
                            isCorrect ? (
                                <img src={okIcon} alt="ok" />

                            ) : null
                        }
                    </div >
                ),
            }}
            sx={style}
            className="inputPrimary"
        />
    );
}

export default InputEmail;