import React, { useState, useEffect } from "react";
import ButtunHeader from "./components/ButtunHeader";
import ButtonNavBar from "./components/ButtonNavBar";
import Menu from "../../components/Menu";
import logo from "../../assets/icons/logo.svg";
import logoXs from "../../assets/icons/logo-xs.svg";
import menuIcon from "../../assets/icons/menu-icon.svg";
import profileIcon from "../../assets/icons/profile-icon.svg";
import {
  HeaderWrapper,
  BtnHeaderText,
  Content,
  ContentMobile,
  ContentNavBar,
  Title,
  AreaButtons,
  Img,
} from "./styles";
import { useNavigate } from "react-router-dom";

interface ButtonNavBarProps {
  id: number;
  title: string;
  sections: number[];
}

export const buttonsNavBar: ButtonNavBarProps[] = [
  {
    id: 1,
    title: "NOSSO ECOSSISTEMA",
    sections: [1, 2],
  },
  {
    id: 3,
    title: "PARA MARCAS E EMPRESAS",
    sections: [3, 4],
  },
  {
    id: 5,
    title: "PARA TALENTOS",
    sections: [5, 6],
  },
  {
    id: 7,
    title: "BELEZA COM DIVERSIDADE",
    sections: [7, 8, 9, 10, 11, 12, 13],
  },
];

const Navigation = () => {
  const LINK_PLATAFORMA = "https://cadastro.plataformaoceano.com.br/";

  const [activeSection, setActiveSection] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [scrollingDown, setScrollingDown] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(true);

  const isMobile = window.matchMedia("(max-width: 580px)").matches;

  const navigate = useNavigate();

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    const currentScrollPos = window.scrollY;
    const scrollPosition = currentScrollPos + 200;
    let currentActiveSection = 0;

    if (currentScrollPos > prevScrollPos) {
      setScrollingDown(true);
    } else {
      setScrollingDown(false);
    }

    setPrevScrollPos(currentScrollPos);

    sections.forEach((section, index) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (
        scrollPosition >= sectionTop &&
        scrollPosition < sectionTop + sectionHeight
      ) {
        currentActiveSection = index;
      }
    });

    setActiveSection(currentActiveSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const goHome = () => {
    window.scrollTo(0, 0);
    navigate("");
  };

  return (
    <>
      <Menu isOpen={isOpenMenu} />
      <HeaderWrapper>
        {(activeSection === 0 || isMobile) && (
          <>
            <Content>
              <ButtunHeader text="Menu" icon={menuIcon} onClick={toggleMenu} />
              <Img src={logo} alt="logo" onClick={goHome} />
              <ButtunHeader
                text="Acesse a Plataforma"
                icon={profileIcon}
                onClick={() => openLink(LINK_PLATAFORMA)}
              />
            </Content>
            <ContentMobile>
              <Img src={menuIcon} alt="menu" onClick={toggleMenu} />
              <Img
                src={logoXs}
                alt="logo"
                height={24}
                width={24}
                onClick={goHome}
              />
              <BtnHeaderText onClick={() => openLink(LINK_PLATAFORMA)}>
                Entrar
              </BtnHeaderText>
            </ContentMobile>
          </>
        )}
        {activeSection > 0 && !isMobile && (
          <ContentNavBar>
            <Title>Conheça a Oceano</Title>
            <AreaButtons>
              {buttonsNavBar.map(({ id, title, sections }) => (
                <ButtonNavBar
                  key={id}
                  id={id}
                  title={title}
                  status={sections.indexOf(activeSection) !== -1}
                  scrollingDown={scrollingDown}
                />
              ))}
            </AreaButtons>
          </ContentNavBar>
        )}
      </HeaderWrapper>
    </>
  );
};

export default Navigation;
