import React from "react";

import "./policy.scss";
import PolicyTerm from "../../components/Navigation/policyTermToken";
import { Pages } from "../../types/enums";
import TermsToken from "../../components/contracts/termsToken";

const termsToken = () => {
  return (
    <div className="policy">
      <PolicyTerm page={Pages.termsToken} />
      <TermsToken />
    </div>
  );
};

export default termsToken;
