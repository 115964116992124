import React from "react";
import TextButton from "../../../components/TextButton";
import {
  Container,
  Content,
  Context,
  AreaDescription,
  AreaText,
  Footer,
  Title,
  Description,
  BackgroundImage,
} from "./styles";

const Banner = () => {
  return (
    <Container>
      <Content>
        <Context>
          <AreaDescription>
            <AreaText>
              <Title>Soluções integradas para crescimento e visibilidade</Title>
              <Description>
                Transforme sua presença no mercado com nossos serviços
                inovadores, feitos para ajudar o seu negócio alcançar novos
                patamares.
              </Description>
            </AreaText>
          </AreaDescription>
          <Footer>
            <AreaText>
              <TextButton
                text="EXPLORE CADA OPORTUNIDADE DE CRESCIMENTO"
                color="#EEEDFF"
              />
            </AreaText>
          </Footer>
        </Context>
        <BackgroundImage />
      </Content>
    </Container>
  );
};

export default Banner;
