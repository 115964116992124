import React from "react";

import './policy.scss'

const ContactText = () => {
    return (
        <div className="policyText">
            <div className="informations">
                <p className="title">PLATAFORMA OCEANO LTDA</p>
            </div>
            <div className="informations items">
                <p className="content">CNPJ sob o nº 44.576.640/0001-10</p>
                <p className="content">Escritório localizado na Av. Ibirapuera, n. 2907, Sala 1211 - Bairro Moema, São Paulo/SP.</p>
                <p className="content">Telefone/WhatsApp: (11) 4580-2353</p>
                <p className="content">E-mail: dados@plataformaoceano.com.br</p>
                <p className="content">Horários de Atendimento: Segunda à Sexta: Das 09h às 18h.</p>
            </div>
        </div>
    );
}

export default ContactText;