import React from "react";

import './contracts.scss';
import LabelTitleW400H40 from "../label/labelTitelW300H40";
import LabelW500H12 from "../label/labelW500H12";
import LabelW300H12 from "../label/labelW300H12";
import LabelW300H16 from "../label/labelW300H16";
import PolicyText from "../policy/policyText";

const PrivacyPolicy = () => (
    <div className="privacyPolicy">
        <div className="title">
            <div className="titleHeader">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="80" height="80" rx="4" fill="#EDF5FF" />
                    <path d="M41.0156 21.2344L55.7031 27.4844C57.4219 28.2656 58.75 29.9062 58.75 31.9375C58.6719 39.75 55.4688 53.8906 42.0312 60.375C40.7031 61 39.2188 61 37.8906 60.375C24.4531 53.8906 21.25 39.75 21.25 31.9375C21.1719 29.9062 22.5 28.2656 24.2188 27.4844L38.9062 21.2344C39.2188 21.0781 39.6094 21 40 21C40.3125 21 40.7031 21.0781 41.0156 21.2344ZM48.8281 37.3281H48.75C49.5312 36.625 49.5312 35.4531 48.75 34.6719C48.0469 33.9688 46.875 33.9688 46.1719 34.6719L37.5 43.4219L33.8281 39.75C33.0469 38.9688 31.875 38.9688 31.1719 39.75C30.3906 40.4531 30.3906 41.625 31.1719 42.3281L36.1719 47.3281C36.875 48.1094 38.0469 48.1094 38.8281 47.3281L48.8281 37.3281Z" fill="#2C1DFE" />
                </svg>
                <LabelTitleW400H40 text={"Política de Privacidade"} text2={""} span={""} isSameLane={true} />
            </div>
            <div className="titleBody">
                <LabelW500H12 text={"DATA DA ÚLTIMA ATUALIZAÇÃO"} />
                <LabelW300H12 text={"11/05/2022"} isWhite={false} />
            </div>
        </div>
        <div className="wellcome">
            <LabelW300H16 text={"Bem-vindo(a) à Plataforma Oceano!"} isSelect={false} />
            <br />
            <LabelW300H16 text={"Ficamos felizes por você estar aqui e esperamos que você aproveite tudo o que temos para oferecer."} isSelect={false} />
            <br />
            <LabelW300H16 text={"A seguir apresentamos a você nossa Política de Privacidade (“Política” ou “Política de Privacidade” ou “Instrumento”). Leia esta Política com atenção."} isSelect={false} />
        </div>
        <PolicyText />
    </div>
)

export default PrivacyPolicy;