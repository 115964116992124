import axios from "axios"

const URL = process.env.REACT_APP_BASE_URL_INSTAGRAM_API

const token = process.env.REACT_APP_INSTAGRAM_TOKEN

export const instagramApi = axios.create({
    baseURL: URL,
})

export const getPosts = () => instagramApi.get<{
    data:{
        caption:string
        id: string
        media_type:"IMAGE" | "VIDEO" | "CAROUSEL_ALBUM"
        media_url :string
        permalink: string
    }[]
}>(`/?fields=id,caption,media_type,media_url,permalink,thumbnail_url&access_token=${token}`)