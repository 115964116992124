import React from 'react';
import TextButton from '../../../components/TextButton';
import arrowRightIcon from '../../../assets/icons/circle-arrow-right.svg'
import img43 from "../../../assets/images/bannerPersonalitie.png";
import {
  Container,
  Content,
  Context,
  AreaDescription,
  AreaText,
  Footer,
  Title,
  Description,
  BackgroundImage
} from './styles';

const HirePersonalities = () => {


  return (
    <Container>
        <Context>
          <AreaDescription>
            <AreaText>
              <Title>Contrate personalidades</Title>
              <Description>Contratar celebridades e figuras da mídia garante visibilidade instantânea e credibilidade à sua campanha.</Description>
            </AreaText>
          </AreaDescription>
          <Footer>
            <AreaText>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
              <TextButton
                text='Comece agora'
                icon={arrowRightIcon}
                color='#EEEDFF'
              />
              </a>
            </AreaText>
          </Footer>
        </Context>
        <BackgroundImage src={img43}/>
    </Container>
  );
};

export default HirePersonalities
