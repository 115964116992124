import React, { useState } from "react";

import "./contracts.scss";
import LabelTitleW400H40 from "../label/labelTitelW300H40";
import LabelW500H12 from "../label/labelW500H12";
import LabelW300H12 from "../label/labelW300H12";
import LabelW300H16 from "../label/labelW300H16";
import TermsTokenText from "../policy/termsTokenText";
import fileIcon from "../../assets/icons/file-icon.svg";
import TermText from "../policy/termText";
import TermsAgencyText from "../policy/termsAgencyText";
import TermTextProfessional from "../policy/termTextProfessional";

const TermsToken = () => {
  const [termText, termTextSet] = useState<string>("token");
  const [selectedButton, selectedButtonText] = useState<Array<string>>([
    "",
    "",
    "",
    "selected",
  ]);

  const termShow = (termItem: number) => {
    if (termItem === 1) {
      termTextSet("hire");
      selectedButtonText(["selected", "", "", ""]);
    } else if (termItem === 2) {
      termTextSet("agency");
      selectedButtonText(["", "selected", "", ""]);
    } else if (termItem === 3) {
      termTextSet("professional");
      selectedButtonText(["", "", "selected", ""]);
    } else {
      termTextSet("token");
      selectedButtonText(["", "", "", "selected"]);
    }
  };

  return (
    <div className="privacyPolicy">
      <div className="title">
        <div className="titleHeader">
          <img src={fileIcon} alt="fileIcon" />
          <LabelTitleW400H40
            text={"Termos de Uso"}
            text2={""}
            span={""}
            isSameLane={true}
          />
        </div>
        <div className="titleBodyTerm">
          <div className="menu">
            <button
              className={`menuItem ${selectedButton[0]}`}
              onClick={() => termShow(1)}
            >
              CONTRATANTE
            </button>
            <div>|</div>
            <button
              className={`menuItem ${selectedButton[1]}`}
              onClick={() => termShow(2)}
            >
              AGÊNCIA
            </button>
            <div>|</div>
            <button
              className={`menuItem ${selectedButton[2]}`}
              onClick={() => termShow(3)}
            >
              PROFISSIONAL
            </button>
            <div>|</div>
            <button
              className={`menuItem ${selectedButton[3]}`}
              onClick={() => termShow(4)}
            >
              TOKEN
            </button>
          </div>
          {termText === "token" ? (
            <div>
              <LabelW500H12 text={"DATA DA ÚLTIMA ATUALIZAÇÃO"} />
              <LabelW300H12 text={"15/12/2023"} isWhite={false} />
            </div>
          ) : (
            <div>
              <LabelW500H12 text={"DATA DA ÚLTIMA ATUALIZAÇÃO"} />
              <LabelW300H12 text={"11/05/2022"} isWhite={false} />
            </div>
          )}
        </div>
      </div>
      {termText === "hire" ? (
        <TermText callback={termShow} />
      ) : termText === "agency" ? (
        <TermsAgencyText callback={termShow} />
      ) : termText === "professional" ? (
        <TermTextProfessional callback={termShow} />
      ) : termText === "token" ? (
        <TermsTokenText />
      ) : null}
    </div>
  );
};

export default TermsToken;
