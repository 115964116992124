import { TokenGetRequest, TokenGetResponse } from "../types/types";
import ApiService from "./baseService/apiService";

export default class TokenService {
    constructor (
        private apiService = new ApiService(),
    ) {
    }

    public TokenGet(request: TokenGetRequest): Promise<TokenGetResponse> {
        const self = this;
        let endpoint = 'v1/Token/TokenGet';

        return self.apiService.post(request, endpoint);
    }
}