import styled from "styled-components";
import { devices } from "../../../utils/constants";
import backgroundImage from "../../../assets/icons/background-aboutus.svg";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 51.69rem;
  background-color: #fdfdfd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8%;
  background-image: url(${backgroundImage});
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: auto;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (max-width: 1137px) {
    align-items: flex-start;
    background-image: none;
    justify-content: space-around;
    height: 63.69rem;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (max-width: 960px) {
    margin-top: 0;
  }

  @media only screen and (${devices.md}) {
    height: 68rem;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 620px) {
    height: 63rem;
  }

  @media only screen and (max-width: 576px) {
    height: 60rem;
  }

  @media only screen and (max-width: 500px) {
    height: 54rem;
  }

  @media only screen and (${devices.xs}) {
    height: 50rem;
  }

  @media only screen and (max-width: 377px) {
    height: 44rem;
  }

  @media only screen and (max-width: 351px) {
    height: 49rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;

  @media only screen and (max-width: 1137px) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: flex-end;
    gap: 5rem;
    height: 80%;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  @media only screen and (max-width: 1137px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.xs}) {
    font-size: 1.25rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.xs}) {
    font-size: 1rem;
  }
`;

export const ImageContainer = styled.div`
  width: 50.5rem;
  height: 30.4375rem;
  overflow: hidden;
  position: absolute;
  left: -10%;

  @media only screen and (max-width: 1440px) {
    width: 45.5rem;
    height: 27.4375rem;
  }

  @media only screen and (max-width: 1362px) {
    left: -7%;
    width: 40.5rem;
    height: 24.4375rem;
  }

  @media only screen and (max-width: 1194px) {
    left: -6%;
    width: 36.5rem;
    height: 22.4375rem;
  }

  @media only screen and (max-width: 1137px) {
    bottom: 0%;
    width: 50.5rem;
    height: 30.4375rem;
  }

  @media only screen and (${devices.lg}) {
    left: -5%;
  }

  @media only screen and (${devices.md}) {
    left: -5%;
    max-width: 50.5rem;
    width: 111%;
    max-height: 30.4375rem;
  }

  @media only screen and (max-width: 714px) {
    max-height: 27.4375rem;
  }

  @media only screen and (max-width: 620px) {
    max-height: 24.4375rem;
  }

  @media only screen and (max-width: 576px) {
    max-height: 22.4375rem;
  }

  @media only screen and (max-width: 500px) {
    max-height: 19.4375rem;
  }

  @media only screen and (${devices.xs}) {
    max-height: 16.4375rem;
  }

  @media only screen and (max-width: 377px) {
    max-height: 14.9rem;
  }

  @media only screen and (max-width: 351px) {
    max-height: 13.9rem;
  }

  @media only screen and (max-width: 324px) {
    max-height: 12.9rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
`;
