import axios, { Method } from "axios";
import React from "react";
// import { setLoading } from '../store/loading/LoadingReducer'

export const useRequest = () => {
  const [data, setData] = React.useState<any>([]);
  const [error, setError] = React.useState<string | null>(null);

  const request = async (
    url: string,
    method: Method,
    body = {},
    headers = {}
  ) => {
    // @ts-ignore
    const axiosRequest = axios[method];
    let response;
    // dispatch(setLoading(true))
    try {
      response = await axiosRequest(
        `${process.env.REACT_APP_BASE_URL_API}${url}`,
        body,
        headers
      );

      setData(response);
      setError(null);
    } catch (err) {
      response = err;
      // @ts-ignore
      setError(err?.message);
    } finally {
      // dispatch(setLoading(false))
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };

  return {
    request,
    data,
    error,
  };
};
