import styled from "styled-components";
import { devices } from "../../../utils/constants";
import background from "../../../assets/icons/background-ocean-token.svg";

export const Session = styled.div`
  display: flex;
  height: 35.8125rem;

  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.md}) {
    height: 30rem;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 23.1875rem;
  border-radius: 1rem;
  background-image: url(${background});
  background-color: #9c1dfe;
  background-position-x: left;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.md}) {
    height: 30rem;
  }

  @media only screen and (${devices.sm}) {
    height: 25rem;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.xl}) {
    gap: 2rem;
  }

  @media only screen and (${devices.lg}) {
    flex-direction: column;
    gap: 3.25rem;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.0625rem;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2.5rem;
  }

  @media only screen and (${devices.xs}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
  max-width: 25.9375rem;

  @media only screen and (${devices.lg}) {
    max-width: 100%;
  }
`;
