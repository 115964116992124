import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/icons/logo-icon.svg";
import nftOcean from "../../../assets/icons/nft-oceano.svg";
import { Content, Context, DescriptionButton, ImageArea } from "./styles";
import Oceano from "../../../types/oceano";
import { Pages } from "../../../types/enums";

const Footer = () => {
  const navigate = useNavigate();
  const oceano = new Oceano();

  const goToPage = (page: Pages) => {
    window.scrollTo(0, 0);
    oceano.goToPage(page, navigate);
  };

  return (
    <Content>
      <Context>
        <ImageArea>
          <img
            src={logo}
            alt="logo"
            onClick={() => goToPage(Pages.home)}
            height={35}
          />
          <img src={nftOcean} alt="nftOcean" height={35} />
        </ImageArea>
        <DescriptionButton onClick={() => goToPage(Pages.termsToken)}>
          TERMOS DE USO
        </DescriptionButton>
      </Context>
    </Content>
  );
};

export default Footer;
