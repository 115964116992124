import React from "react";
import { StyledObject } from "styled-components";
import { Container, Text } from "./styles";

interface TextButtonProps {
  text: string;
  icon?: string| React.ReactElement;
  color: string;
  style?: StyledObject;
  onClick?: () => void;
}

const TextButton = ({ icon, text, color, style, onClick }: TextButtonProps) => {
  return (
    <Container style={style} onClick={onClick}>
      <Text color={color}>{text}</Text>
      {typeof icon === "string" ? <img src={icon} alt={text} />:icon}
    </Container>
  );
};

export default TextButton;
