import React, { useState } from "react";
import "./form.scss";
import LabelW600H42 from "../label/labelW600H42";
import InputEmail from "../Input/inputEmail";
import InputPassword from "../Input/inputPassword";
import CheckboxPrimary from "../checkbox/checkboxPrimary";
import BasicButton from "../Button/basicButton";
import ActionButton from "../Button/actionButton";
import { User } from "../../types/types";
import LabelW500H10 from "../label/labelW500H10";

interface props {
  callback: any;
  callbackShowForgetPassword: any;
}

const LoginForm = ({ callback, callbackShowForgetPassword }: props) => {
  const [user, userSet] = useState<User>(new User());
  const [remember, rememberSet] = useState<boolean>(false);
  const [hasError, hasErrorSet] = useState<boolean>(false);

  const emailSet = (text: string): void => {
    user.email = text;
    userSet(user);
  };

  const passwordSet = (text: string): void => {
    user.password = text;
    userSet(user);
  };

  const login = async () => {
    let success = await callback(user, remember);
    hasErrorSet(success);
  }

  return (
    <div className="LoginFormContent">
      <LabelW600H42 text={"Entrar"} />
      <div className="loginFormEmailPassword">
        <InputEmail emailSet={(text: string) => emailSet(text)} />
        <InputPassword passwordSet={(text: string) => passwordSet(text)} />
        {
          hasError ? (
            <LabelW500H10 text={"E-mail ou senha inválido! Tente novamente."} color={"red"} hasError={true} />
          ) : null
        }
        <div className="isForgetPassword">
          <CheckboxPrimary remember={remember} rememberSet={rememberSet} />
          <BasicButton
            text={"Esqueceu a senha?"}
            color={"blue"}
            callback={() => callbackShowForgetPassword(true)}
          />
        </div>
      </div>
      <ActionButton text={"Entrar"} callback={login} />
    </div>
  );
};

export default LoginForm;
