import styled, { keyframes } from "styled-components";
import { devices } from "../../../utils/constants";

interface TextScrollProps {
  inDirectionLeft: boolean;
}

const scrollLeft = keyframes`
  0% {
    transform: translateX(60%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const scrollRight = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Content = styled.section`
  display: flex;
  height: 12rem;
  justify-content: flex-start;
  background-color: #fdfdfd;
`;

export const ContentMarquee = styled.div`
  display: flex;
  height: 5rem;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  background-color: #fdfdfd;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  @media only screen and (${devices.lg}) {
    height: 3.00394rem;
  }

  @media only screen and (${devices.sm}) {
    height: 1.8125rem;
  }
`;

export const TextScroll = styled.div<TextScrollProps>`
  display: flex;
  flex-direction: row;
  animation: ${({ inDirectionLeft }) =>
      inDirectionLeft ? scrollLeft : scrollRight}
    30s linear infinite;
`;

export const Text = styled.h1`
  color: #090633;
  font-family: Lexend;
  font-size: 4rem;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.lg}) {
    font-size: 2.4935rem;
  }

  @media only screen and (${devices.sm}) {
    font-size: 1.5rem;
  }
`;

export const Icon = styled.img`
  margin-left: 3rem;
  margin-right: 3rem;

  @media only screen and (${devices.lg}) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    height: 2rem;
    width: 2rem;
  }

  @media only screen and (${devices.sm}) {
    margin-left: 2rem;
    margin-right: 2rem;
    height: 1rem;
    width: 1rem;
  }
`;
