import styled from "styled-components";
import img43 from '../../../assets/images/modelTalent.webp';
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 32rem;
  padding: 1.25rem;
  background-color: #FDFDFD;

  @media only screen and (max-width: 1194px) {
    padding: 0;
    height: 52rem;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 5rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 30rem;
  border-radius: 0.25rem;
  background-image: url(${img43});
  background-position-x: 105%;
  background-position-y: 4%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #1F0633;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 7%;

  @media only screen and (max-width: 1423px) {
    background-position-x: 140%;
  }

  @media only screen and (max-width: 1194px) {
    position: relative;
    border-radius: 0;
    padding: 0;
    height: 52rem;
  }
`;

export const BackgroundImage = styled.div`
  display: none;
  width: 100%;
  height: 28.4rem;
  background-image: url(${img43});
  background-position-x: 50%;
  background-position-y: 4%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1F0633;
  position: absolute;
  bottom: 0;

  @media only screen and (max-width: 1194px) {
    display: flex;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 43.25rem;

  @media only screen and (max-width: 1170px) {
    max-width: 36.25rem;
  }

  @media only screen and (max-width: 1194px) {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
`;

export const AreaDescription = styled.div`
  width: 100%;
  height: 12.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FDFDFD;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  @media only screen and (max-width: 1194px) {
    border-radius: 0;
    height: 16.6rem;
    align-items: flex-start;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2C1DFE;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media only screen and (max-width: 1194px) {
    border-radius: 0;
    justify-content: flex-start;
    background: #1D7EFE;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.80);
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.60);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const AreaText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33rem;
  gap: 1rem;

  @media only screen and (max-width: 1194px) {
    max-width: 90%;
    padding-left: 7%;
    padding-right: 3%;
  }
`;
