import React, { useEffect, useState } from "react";
import './modal.scss';
import loading from '../../assets/images/loading.png';
import LabelW500H20 from "../label/labelW500H20";

const LoadingModal = () => {
    return (
        <div className="modal">
            <div className="loadingModal">
                <img src={loading} alt="loading" className="loadingIcon" />
                <LabelW500H20 text={"Carregando..."} />
            </div>
        </div>
    );
}

export default LoadingModal;