import React, { useState } from "react";
import img27 from "../../../assets/images/27.webp";
import playIcon from "../../../assets/icons/play-icon.svg";
import {
  Content,
  Context,
  Title,
  AreaVideo,
  Img,
  Icon,
  Iframe,
} from "./styles";

const Video = () => {
  const [play, setPlay] = useState(true);

  const handlePlayer = () => {
    setPlay(!play);
  };

  return (
    <Content>
      <Context>
        <Title>Assista aos episódios do reality Beleza com Diversidade</Title>
        <AreaVideo>
          {!play && (
            <>
              <Icon src={playIcon} alt="play-icon" onClick={handlePlayer} />
              <Img src={img27} alt="epsodios-reality" onClick={handlePlayer} />
            </>
          )}
          {play && (
            <Iframe
              src={`https://www.youtube.com/embed/7O-hlA3OH6Y?si=Tw-AE4tks4-ZpMD8&amp;controls=0`}
              title="REALITY SHOW BELEZA COM DIVERSIDADE - #EPISÓDIO1 O CASTING"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          )}
        </AreaVideo>
      </Context>
    </Content>
  );
};

export default Video;
