import React from "react";
import {
  Section,
  Container,
  Context,
  AreaText,
  Title,
  AreaTitle,
  Description,
  AreaLine,
  Line
} from './styles';

const FindTalent = () => {
  return (
    <Section>
      <Container>
        <Context>
          <AreaTitle>
            <Title>
              Encontre <span>os melhores rostos</span> para sua campanha
            </Title>
          </AreaTitle>
          <AreaText>
            <Description>
              Oferecemos um sistema de filtragem poderoso que permite que você refine sua busca e selecione o talento que melhor se adapta às suas necessidades. Monte o seu briefing, publique na plataforma e receba os melhores talentos.
            </Description>
          </AreaText>
        </Context>
      </Container>
      <AreaLine>
        <Line />
      </AreaLine>
    </Section>
  );
};

export default FindTalent;
