import React from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import instagramIcon from '../../../assets/icons/instagram-icon.svg';
import facebookIcon from '../../../assets/icons/facebook-icon.svg';
import youtubeIcon from '../../../assets/icons/youtube-icon.svg';
import linkedinIcon from '../../../assets/icons/linkedin-icon.svg';
import img29 from '../../../assets/images/29.webp';
import img30 from '../../../assets/images/30.webp';
import img31 from '../../../assets/images/31.webp';
import img32 from '../../../assets/images/32.webp';
import img33 from '../../../assets/images/33.webp';
import {
  Content,
  AreaTitle,
  Title,
  TextBlue,
  Context,
  Description,
  AreaDescription,
  AreaIcons,
  Icon,
  ContentImage,
  Image,
  Space
} from './styles';

const SocialMedia = () => {
  const responsive = {
    0: {
      items: 10,
      itemsFit: 'contain',
    },
  };

  const items = [
    <ContentImage key={1}><Image url={img29} /><Space /></ContentImage>,
    <ContentImage key={2}><Image url={img30} /><Space /></ContentImage>,
    <ContentImage key={3}><Image url={img31} /><Space /></ContentImage>,
    <ContentImage key={4}><Image url={img32} /><Space /></ContentImage>,
    <ContentImage key={5}><Image url={img33} /><Space /></ContentImage>
  ];

  const openLink = (url: string) => {
    window.open(url, '_blank');
  }

  return (
    <Content>
      <Context>
        <AreaTitle>
          <Title>
            Siga o <TextBlue>nosso perfil</TextBlue> e acompanhe as novidades
          </Title>
        </AreaTitle>
        <AreaDescription>
          <Description>NOS ENCONTRE NAS REDES SOCIAIS</Description>
          <AreaIcons>
            <Icon
              key={1}
              src={instagramIcon}
              alt="instagram"
              onClick={() => openLink('https://instagram.com/plataformaoceano')}
            />
            <Icon
              key={2}
              src={facebookIcon}
              alt="facebook"
              onClick={() => openLink('https://web.facebook.com/plataformaoceano')}
            />
            <Icon
              key={3}
              src={youtubeIcon}
              alt="youtube"
              onClick={() => openLink('https://www.youtube.com/@plataformaoceano')}
            />
            <Icon
              key={4}
              src={linkedinIcon}
              alt="linkedin"
              onClick={() => openLink('https://www.linkedin.com/company/plataforma-oceano/')}
            />
          </AreaIcons>
        </AreaDescription>
      </Context>
      <AliceCarousel
        mouseTracking
        disableButtonsControls
        disableDotsControls
        autoWidth
        responsive={responsive}
        items={items}
      />
    </Content>
  );
};

export default SocialMedia;
