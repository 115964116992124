import React, { useState, useEffect } from "react";
import Badge from "../../../components/Badge";
import appleStoreIcon from '../../../assets/icons/apple-store-black-icon.svg';
import googlePlayIcon from '../../../assets/icons/google-play-black-icon.svg';
import img04 from '../../../assets/images/modeloHome.webp';
import img05 from '../../../assets/images/05.png';
import img06 from '../../../assets/images/06.png';
import {
  Content,
  Context,
  AreaTitle,
  Title,
  TextBlue,
  Description,
  AreaButton,
  Button,
  Rectangle,
  Row,
  Letter,
  AreaImageLarger,
  AreaImageSmall,
  RectangleSmall,
  AreaImages,
  ImageContainerLarger,
  ImageContainerSmall
} from './styles';

const ForTalents = () => {
  const [textYou, setTextYou] = useState<string[]>([]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  }

  useEffect(() => {
    if (textYou.length === 0) {
      setTextYou(['V','O','C','Ê']);
    }
  }, [textYou]);

  return (
    <Content id="section-6">
      <AreaTitle>
        <Badge text="PARTICIPE DE GRANDES CAMPANHAS" backgroundColor="#EDF5FF" color="#114C98" />
        <Title>Faça parte do time Oceano e <TextBlue>seja nosso talento</TextBlue></Title>
        <Description>
          Descubra as melhores soluções adaptadas às suas necessidades ou ao seu empreendimento. Encontre respostas eficazes para alcançar seus objetivos e superar desafios com sucesso. Saiba mais sobre as opções que podem fazer a diferença e leve sua carreira ou seu negócio ao próximo nível.
        </Description>
        <AreaButton>
          <Button
            image={googlePlayIcon}
            onClick={() => openLink('https://play.google.com/store/apps/details?id=oceano.mobile1233')}
          />
          <Button
            image={appleStoreIcon}
            onClick={() => openLink('https://apps.apple.com/br/app/oceano/id1629763551')}
          />
        </AreaButton>
      </AreaTitle>
      <Context>
        <Rectangle />
        <AreaImages>
          <Row style={{ marginBottom: '1.25rem' }}>
            <ImageContainerLarger>
              <AreaImageLarger url={img04} />
            </ImageContainerLarger>
            <ImageContainerSmall>
              <AreaImageSmall url={img05} />
            </ImageContainerSmall>
          </Row>
          <Row>
            <RectangleSmall>
              {textYou.map((char, index) => {
                if (index === 3) {
                  setTimeout(() => {
                    setTextYou([]);
                  }, 3000);
                }
                return <Letter key={index} delay={`${index * 0.5}s`}>{char}</Letter>
              })}
            </RectangleSmall>
            <ImageContainerLarger>
              <AreaImageLarger url={img06} />
            </ImageContainerLarger>
          </Row>
        </AreaImages>
      </Context>
    </Content>
  );
};

export default ForTalents;
