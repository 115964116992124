import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    isWhite: boolean;
}

const LabelW300H12 = ({ text, isWhite }: props) => {
    const [white, whiteSet] = useState<string>('');

    useEffect(() => {
        let text = isWhite ? 'white' : '';
        whiteSet(text);
    }, [isWhite]);

    return (
        <div className={`labelW300H12 ${white}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW300H12;