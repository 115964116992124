import styled from "styled-components";
import { devices } from "../../utils/constants";

interface ButtonProps {
  status: boolean;
}

interface ColorProps {
  color: string;
}

interface LineProps {
  color: string;
  status: boolean;
}

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 5rem;
  flex-shrink: 0;
  border-bottom: 1px solid #f1eff5;
  background: #fdfdfd;
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  /* margin: 0px 120px; */
  margin: 0 8%;
  justify-content: space-between;

  @media only screen and (max-width: 1362px) {
    margin: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    margin: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    margin: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    margin: 0 3%;
  }

  @media only screen and (${devices.xs}) {
    display: none;
  }
`;

export const ContentNavBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin: 0px 120px; */
  margin: 0 8%;

  @media only screen and (max-width: 1362px) {
    margin: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    margin: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    margin: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    margin: 0 3%;
  }
`;

export const ContainerNavBarFixed = styled.div`
  display: flex;
  background-color: #f8f8f8;
`;

export const ContentMobile = styled.div`
  display: none;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  @media only screen and (${devices.xs}) {
    display: flex;
  }
`;

export const BtnHeaderText = styled.h1`
  color: #090633;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;

export const BtnHeader = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media only screen and (${devices.sm}) {
    font-size: 0.9rem;
    width: 5rem;
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (${devices.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Button = styled.a<ButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ status }) => (status ? "space-between" : "center")};
  height: 5rem;
  margin-right: 2.5rem;
  text-decoration: none;

  @media only screen and (${devices.lg}) {
    height: 2.5rem;
    margin-right: 1.5rem;
  }

  @media only screen and (${devices.sm}) {
    margin-right: 0.2rem;
  }
`;

export const LineSlidOutRight = styled.div<LineProps>`
  width: 100%;
  height: 0.125rem;
  background: ${({ color }) => color};
  transition: color 0.7s ease;
  animation: ${({ status }) => status && "slideOutRight 0.7s ease"};

  @keyframes slideOutRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const LineSlidInLeft = styled.div<LineProps>`
  width: 100%;
  height: 0.125rem;
  background: ${({ color }) => color};
  transition: color 0.7s ease;
  animation: ${({ status }) => status && "slideInLeft 0.7s ease"};

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const AreaTextButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextButton = styled.p<ColorProps>`
  color: ${({ color }) => color};
  text-align: center;
  font-family: Lexend;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-left: 0.5rem;
  transition: color 0.7s ease;
`;

export const Img = styled.img`
  cursor: pointer;
`;

export const IconNavBar = styled.img`
  height: 1.5rem;
  width: 1.5rem;

  @media only screen and (${devices.lg}) {
    height: 1rem;
    width: 1rem;
  }
`;

export const Space = styled.div``;
