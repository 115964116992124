import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Context,
  AreaText,
  Title,
  ImageContainer,
  AreaImage,
  Description,
} from "./styles";
import img78 from "../../../assets/images/78.webp";
import Badge from "../../../components/Badge";
import TextButton from "../../../components/TextButton";
import arrowRightIcon from "../../../assets/icons/circle-arrow-right-primary-color.svg";

const Personalits = () => {
  const navigate = useNavigate();

  const navigateTo = (route: "media" | "personalities" | "service-talent") => {
    navigate(`/${route}`);
  };

  return (
    <Container>
      <Context>
        <ImageContainer>
          <AreaImage url={img78} />
        </ImageContainer>
        <AreaText>
          <Badge text="PERSONALIDADES" color="#fff" backgroundColor="#2C1DFE" />
          <Title>Representação de grandes Personalidades</Title>
          <Description>
            Conecte-se com personalidades influentes para impulsionar sua marca.
            Acesse nosso portfólio diversificado de figuras públicas e
            especialistas, prontos para agregar valor e autenticidade à sua
            mensagem.
          </Description>
          <TextButton
            color="#2C1DFE"
            icon={arrowRightIcon}
            text="SAIBA MAIS"
            onClick={() => navigateTo("personalities")}
          />
        </AreaText>
      </Context>
    </Container>
  );
};

export default Personalits;
