import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

interface RectangleProps {
  color: string;
}

export const Content = styled.section`
  height: 45rem;
  flex-shrink: 0;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* margin: 0px 120px; */
  margin: 0 8%;

  @media only screen and (max-width: 1362px) {
    margin: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    margin: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    margin: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    margin: 0 3%;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    height: 70rem;
    justify-content: space-evenly;
  }

  @media only screen and (${devices.xs}) {
    height: 60rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 36.875rem;

  @media only screen and (${devices.xl}) {
    width: 30.875rem;
  }

  @media only screen and (${devices.lg}) {
    width: 26.875rem;
  }

  @media only screen and (max-width: 900px) {
    width: 39rem;
  }

  @media only screen and (max-width: 900px) {
    width: 35rem;
  }

  @media only screen and (${devices.sm}) {
    width: 29rem;
  }

  @media only screen and (max-width: 490px) {
    width: 25rem;
  }

  @media only screen and (${devices.xs}) {
    width: 19rem;
  }
`;

export const ContextDescription = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 32.875rem;

  @media only screen and (max-width: 1312px) {
    width: 24.875rem;
  }

  @media only screen and (${devices.xl}) {
    width: 25.875rem;
  }

  @media only screen and (max-width: 900px) {
    width: 33.9375rem;
  }

  @media only screen and (${devices.sm}) {
    width: 26.875rem;
  }

  @media only screen and (max-width: 490px) {
    width: 22rem;
  }

  @media only screen and (${devices.xs}) {
    width: 19rem;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 1.25rem;

  @media only screen and (${devices.xs}) {
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }
`;

export const ImageContainer = styled.div`
  width: 14.5rem;
  height: 9.16669rem;
  overflow: hidden;
  border-radius: 0.25rem;

  @media only screen and (${devices.xs}) {
    height: 5.14581rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainer}:hover & {
    transform: scale(1.2);
  }
`;

export const Rectangle = styled.div<RectangleProps>`
  width: 21.125rem;
  height: 9.16669rem;
  border-radius: 0.25rem;
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${devices.xl}) {
    width: 19.125rem;
  }

  @media only screen and (${devices.lg}) {
    width: 16.125rem;
  }

  @media only screen and (max-width: 900px) {
    width: 23.25rem;
  }

  @media only screen and (${devices.sm}) {
    width: 17rem;
  }

  @media only screen and (max-width: 490px) {
    width: 14rem;
  }

  @media only screen and (${devices.xs}) {
    width: 7.9375rem;
    height: 5.14581rem;
  }
`;

export const TextRectangle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  margin-left: 3rem;
  margin-right: 3rem;

  @media only screen and (${devices.xs}) {
    font-size: 0.625rem;
    font-weight: 500;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const Title = styled.h1`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media only screen and (${devices.xs}) {
    font-size: 1.5rem;
  }
`;

export const TextBlue = styled.span`
  color: #2c1dfe;
`;

export const Description = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.4);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;

export const AreaDescription = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    max-width: 33.9375rem;
  }

  @media only screen and (${devices.xs}) {
    width: 19rem;
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;

  @media only screen and (max-width: 490px) {
    flex-direction: column;
  }
`;
