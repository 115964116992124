import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  Description,
  CardArea,
  Card,
  CardDescriptionArea,
  TitleCard,
  DescriptionCard,
  ImageCard,
  ImageCardArea,
} from "./styles";
import shareIcon from "../../../assets/icons/share-token-icon.svg";
import locationIcon from "../../../assets/icons/location-token-icon.svg";
import peopleIcon from "../../../assets/icons/people-group-token-icon.svg";

const HowToHelp = () => {
  return (
    <Container>
      <Context>
        <AreaText>
          <Title>
            Como <span>ajudar</span> a carreira da modelo
          </Title>
        </AreaText>
        <AreaText>
          <Description>
            Além de resgatar produtos incríveis, há várias maneiras de ajudar
            Gabi Matis a alcançar ainda mais sucesso
          </Description>
        </AreaText>
      </Context>
      <CardArea>
        <Card>
          <ImageCardArea style={{ width: "7rem" }}>
            <ImageCard src={shareIcon} />
          </ImageCardArea>
          <CardDescriptionArea>
            <TitleCard>Compartilhe sua história</TitleCard>
            <DescriptionCard>
              Conte às pessoas sobre a jornada de Gabi e como elas podem se
              envolver.
            </DescriptionCard>
          </CardDescriptionArea>
        </Card>
        <Card>
          <ImageCardArea>
            <ImageCard src={locationIcon} />
          </ImageCardArea>
          <CardDescriptionArea>
            <TitleCard>Faça presença</TitleCard>
            <DescriptionCard>
              Esteja presente nos eventos promovidos por Gabi, e ajude a criar
              um impacto positivo. jornada.
            </DescriptionCard>
          </CardDescriptionArea>
        </Card>
        <Card>
          <ImageCardArea>
            <ImageCard src={peopleIcon} />
          </ImageCardArea>
          <CardDescriptionArea>
            <TitleCard>Incentive a participação</TitleCard>
            <DescriptionCard>
              Apresente a modelo para seus clientes e parceiros. Nossa booker
              está pronta pra atender.
            </DescriptionCard>
          </CardDescriptionArea>
        </Card>
      </CardArea>
    </Container>
  );
};

export default HowToHelp;
