import React, { useState } from "react";
import speakerIcon from "../../../assets/icons/speaker-icon.svg";
import starsIcon from "../../../assets/icons/stars-icon.svg";
import imageIcon from "../../../assets/icons/image-icon.svg";
import profilesIcon from "../../../assets/icons/profiles-icon.svg";
import moneyIcon from "../../../assets/icons/money-icon.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-icon.svg";
import ArrowBottonIcon from "../../../assets/icons/arrow-botton-icon.svg";
import {
  Content,
  Title,
  SubTitle,
  Context,
  AreaCard,
  Card,
  AreaDescriptionCard,
  DescriptionCard,
  TitleCard,
  Icon,
  AreaButton,
  CardContext,
  AreaIcon,
  AreaIconMobile,
} from "./styles";
import Button from "../../../components/Button";
import Register from "../../../components/Register";

const HowItWorks = () => {
  const [toggleRegisterModal, setToggleRegisterModal] =
    useState<boolean>(false);

  return (
    <Content id="section-HowItWorks-talent">
      <Context>
        <Title>Como Funciona?</Title>
        <SubTitle>Veja abaixo uma visão geral do nosso processo</SubTitle>
        <CardContext>
          <Card key={1}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={speakerIcon} alt="speaker-icon" height={24} />
                <Icon
                  src={ArrowBottonIcon}
                  alt="arrow-botton-icon"
                  height={60}
                />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={speakerIcon} alt="speaker-icon" height={24} />
                  <Icon
                    src={ArrowRightIcon}
                    alt="arrow-right-icon"
                    width={150}
                  />
                </AreaIcon>
                <TitleCard>Divulgue o seu trabalho</TitleCard>
                <DescriptionCard>
                  Publique sua oportunidade de forma rápida e descomplicada na
                  plataforma.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={2}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={starsIcon} alt="stars-icon" height={24} />
                <Icon
                  src={ArrowBottonIcon}
                  alt="arrow-botton-icon"
                  height={60}
                />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={starsIcon} alt="stars-icon" height={24} />
                  <Icon
                    src={ArrowRightIcon}
                    alt="arrow-right-icon"
                    width={150}
                  />
                </AreaIcon>
                <TitleCard>Talentos qualificados</TitleCard>
                <DescriptionCard>
                  Você receberá uma seleção dos melhores talentos para o seu
                  trabalho.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={3}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={imageIcon} alt="image-icon" height={24} />
                <Icon
                  src={ArrowBottonIcon}
                  alt="arrow-botton-icon"
                  height={60}
                />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={imageIcon} alt="image-icon" height={24} />
                  <Icon
                    src={ArrowRightIcon}
                    alt="arrow-right-icon"
                    width={150}
                  />
                </AreaIcon>
                <TitleCard>Realize audições online</TitleCard>
                <DescriptionCard>
                  Conduza audições virtuais para escolher os candidatos ideais
                  para seu projeto.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={4}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={profilesIcon} alt="profiles-icon" height={24} />
                <Icon
                  src={ArrowBottonIcon}
                  alt="arrow-botton-icon"
                  height={60}
                />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={profilesIcon} alt="profiles-icon" height={24} />
                  <Icon
                    src={ArrowRightIcon}
                    alt="arrow-right-icon"
                    width={150}
                  />
                </AreaIcon>
                <TitleCard>Contrate sem estresse</TitleCard>
                <DescriptionCard>
                  Contrate o candidato perfeito para sua marca em segundos, sem
                  complicações.
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={5}>
            <AreaCard>
              <AreaIconMobile>
                <Icon src={moneyIcon} alt="money-icon" height={24} />
              </AreaIconMobile>
              <AreaDescriptionCard>
                <AreaIcon>
                  <Icon src={moneyIcon} alt="money-icon" height={24} />
                </AreaIcon>
                <TitleCard>Aprovação garantida</TitleCard>
                <DescriptionCard>
                  Libere o pagamento somente após a aprovação do trabalho
                  realizado!
                </DescriptionCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
        </CardContext>
      </Context>
      <AreaButton>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
        <Button
          text="Fazer cadastro"
        />
        </a>
      </AreaButton>
      <Register
        toggleModal={toggleRegisterModal}
        setToggleModal={setToggleRegisterModal}
      />
    </Content>
  );
};

export default HowItWorks;
