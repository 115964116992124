import React from "react";
import { useNavigate } from "react-router-dom";
import Badge from "../../../components/Badge";
import TextButton from "../../../components/TextButton";
import arrowRightIcon from "../../../assets/icons/circle-arrow-right-blue.svg";
import img01 from "../../../assets/images/cameraMen.png";
import img02 from "../../../assets/images/38.webp";
import img03 from "../../../assets/images/modeloHome2.webp";
import {
  Content,
  Context,
  ContextDescription,
  AreaImage,
  Row,
  Rectangle,
  TextRectangle,
  Title,
  Description,
  AreaDescription,
  AreaButtons,
  TextBlue,
  ImageContainer,
} from "./styles";

const Solutions = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <Content id="section-2">
      <Context>
        <Row>
          <ImageContainer>
            <AreaImage url={img01} />
          </ImageContainer>
          <Rectangle color="#2C1DFE">
            <TextRectangle>sua marca na tv e no rádio</TextRectangle>
          </Rectangle>
        </Row>
        <Row>
          <Rectangle color="#090633">
            <TextRectangle>faça parte de grandes campanhas</TextRectangle>
          </Rectangle>
          <ImageContainer>
            <AreaImage url={img02} style={{ backgroundPositionY: "23%" }} />
          </ImageContainer>
        </Row>
        <Row>
          <ImageContainer>
            <AreaImage url={img03} />
          </ImageContainer>
          <Rectangle color="#2C1DFE">
            <TextRectangle>
              contrate personalidades, influenciadores ou modelos
            </TextRectangle>
          </Rectangle>
        </Row>
      </Context>
      <ContextDescription>
        <AreaDescription>
          <Badge
            text="SOLUÇÕES PARA CARREIRA E NEGÓCIO"
            backgroundColor="#EDF5FF"
            color="#114C98"
          />
          <Title>
            Conheça as <TextBlue>melhores soluções</TextBlue> para você ou para
            o seu negócio
          </Title>
          <Description>
            Descubra as melhores soluções adaptadas às suas necessidades ou ao
            seu empreendimento. Encontre respostas eficazes para alcançar seus
            objetivos e superar desafios com sucesso. Saiba mais sobre as opções
            que podem fazer a diferença e leve sua carreira ou seu negócio ao
            próximo nível.
          </Description>
        </AreaDescription>
        <AreaButtons>
          <TextButton
            color="#2C1DFE"
            icon={arrowRightIcon}
            text="SOU MARCA OU EMPRESA"
            style={{ marginRight: "1.5rem" }}
            onClick={() => handleNavigate("/our-services")}
          />
          <TextButton
            color="#2C1DFE"
            icon={arrowRightIcon}
            text="QUERO SER UM TALENTO"
            onClick={() => handleNavigate("/talent")}
          />
        </AreaButtons>
      </ContextDescription>
    </Content>
  );
};

export default Solutions;
