import React from "react";
import { useNavigate } from "react-router-dom";
import starIcon from "../../../assets/icons/star-icon.svg";
import cameraIcon from "../../../assets/icons/camera-movie-icon.svg";
import ticketIcon from "../../../assets/icons/ticket-icon.svg";
import tvIcon from "../../../assets/icons/tv-retro-icon.svg";
import barIcon from "../../../assets/icons/barra-icon.svg";
import arrowRightIcon from "../../../assets/icons/circle-arrow-right.svg";
import {
  Content,
  Title,
  Context,
  AreaCard,
  Card,
  AreaDescriptionCard,
  TitleCard,
  Icon,
  CardContext,
  TitleArea,
  ButtonArea,
} from "./styles";
import TextButton from "../../../components/TextButton";

const Midia = () => {
  const navigate = useNavigate();

  const navigateTo = (route: "media" | "personalities" | "service-talent") => {
    navigate(`/${route}`);
  };

  return (
    <Content>
      <Context>
        <TitleArea>
          <Title>
            Ao anunciar conosco, você ganha <strong>pontos de Cashback</strong>{" "}
            que podem ser trocados por diferentes produtos e serviços
          </Title>
        </TitleArea>
        <CardContext>
          <Card key={1}>
            <AreaCard>
              <img src={barIcon} alt="bar-icon" />
              <AreaDescriptionCard>
                <Icon src={starIcon} alt="star-icon" height={24} />
                <TitleCard>
                  Cachês diferenciados com celebridades exclusivas
                </TitleCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={2}>
            <AreaCard>
              <img src={barIcon} alt="bar-icon" />
              <AreaDescriptionCard>
                <Icon src={tvIcon} alt="tv-icon" height={24} />
                <TitleCard>Bônus para de divulgação no Rádio ou TV</TitleCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={3}>
            <AreaCard>
              <img src={barIcon} alt="bar-icon" />
              <AreaDescriptionCard>
                <Icon src={cameraIcon} alt="camera-icon" height={24} />
                <TitleCard>Produção audiovisual</TitleCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
          <Card key={4} style={{ width: "18.3125rem" }}>
            <AreaCard style={{ width: "18.3125rem" }}>
              <img src={barIcon} alt="bar-icon" />
              <AreaDescriptionCard>
                <Icon src={ticketIcon} alt="ticket-icon" height={18} />
                <TitleCard>
                  Crie experiencias e estreite relacionamentos com seus clientes
                  em camarotes e eventos exclusivos
                </TitleCard>
              </AreaDescriptionCard>
            </AreaCard>
          </Card>
        </CardContext>
        <ButtonArea>
          <TextButton
            text="SAIBA MAIS"
            icon={arrowRightIcon}
            color="#EEEDFF"
            onClick={() => navigateTo("media")}
          />
        </ButtonArea>
      </Context>
    </Content>
  );
};

export default Midia;
