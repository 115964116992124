import { ForgotPasswordRequest, ForgotPasswordResponse, NewPasswordRequest, NewPasswordResponse } from "../types/types";
import ApiService from "./baseService/apiService";

export default class UserService {
    constructor (
        private apiService = new ApiService(),
    ) {
    }

    public ForgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse> {
        const self = this;
        let endpoint = 'v1/User/ForgotPassword';

        return self.apiService.post(request, endpoint);
    }

    public NewPassword(request: NewPasswordRequest): Promise<NewPasswordResponse> {
        const self = this;
        let endpoint = 'v1/User/NewPassword';

        return self.apiService.post(request, endpoint);
    }
}