import React from "react";
import {
  Container,
  Context,
  AreaTitle,
  Title,
  AreaCards,
  Card,
  Description,
  TitleCard
} from './styles';

const Benefits = () => {
  return (
    <Container>
      <Context>
        <AreaTitle>
          <Title>Benefícios exclusivos que oferecemos para a sua marca</Title>
        </AreaTitle>
        <AreaCards>
          <Card>
            <TitleCard>| 30+</TitleCard>
            <Description>Grandes emissoras de Rádio e TV parceiras</Description>
          </Card>
          <Card>
            <TitleCard>| 5000+</TitleCard>
            <Description>Cidades alcançados com uma única inserção</Description>
          </Card>
          <Card>
            <TitleCard>| $$</TitleCard>
            <Description>Com valores acessíveis para o seu negócio</Description>
          </Card>
        </AreaCards>
      </Context>
    </Container>
  );
};

export default Benefits
