//#region Base
export class BaseRequest {
    public id: number = 0;
}

export class BaseGetRequest<T> extends BaseRequest {
    public item: T | undefined;
}

export class BaseSaveRequest<T> extends BaseRequest {
    public item: T | undefined;
}

export class BaseResponse {
    public success: boolean = false;
    public message: string = '';
    public errorMessage: string = '';
}

export class BaseItemResponse<T> extends BaseResponse {
    public item: T | undefined;
}
//#endregion Base

//#region Document
export class Document {
    public documentoID: number = 0;
    public tokenID: string = '';
    public mumber: string = '';
}
//#endregion Document


//#region Token
export class TokenGetRequest extends BaseGetRequest<User>
{
}

export class TokenGetResponse extends BaseItemResponse<Token>
{
}

export class Token {
    public tokenID: number = 0;
    public email: string = '';
    public userName: string = '';
    public name: string = '';
    public lastname: string = '';
    public tokenCode: string = '';
    public documents: Array<Document> = [];
}
//#endregion Token

//#region User
export class ForgotPasswordRequest extends BaseSaveRequest<User>
{
}

export class ForgotPasswordResponse extends BaseResponse {
}

export class NewPasswordRequest extends BaseSaveRequest<User>
{
    public code: string = "";
}

export class NewPasswordResponse extends BaseResponse {
}

export class User {
    public email: string = '';
    public password: string = '';
}
//#endregion User