import React, { useState, useEffect } from "react";
import wavesIcon from "../../../assets/icons/waves-icon.svg";
import { Content, ContentMarquee, Text, Icon, TextScroll } from "./styles";

const ScrollableText = () => {
  const [inDirectionLeft, setInDirectionLeft] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setInDirectionLeft((prev) => !prev);
    }, 13000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Content id="section-3">
      <ContentMarquee>
        <TextScroll inDirectionLeft={inDirectionLeft}>
          <Text>Contrate</Text>
          <Icon src={wavesIcon} alt="waves" />
          <Text>Encontre Trabalhos</Text>
          <Icon src={wavesIcon} alt="waves" />
          <Text>Talentos</Text>
          <Icon src={wavesIcon} alt="waves" />
          <Text>Mídia</Text>
          <Icon src={wavesIcon} alt="waves" />
          <Text>Sua marca na TV e Rádio</Text>
          <Icon src={wavesIcon} alt="waves" />
          <Text>Mídia</Text>
          <Icon src={wavesIcon} alt="waves" />
          <Text>Personalidade</Text>
          <Icon src={wavesIcon} alt="waves" />
        </TextScroll>
      </ContentMarquee>
    </Content>
  );
};

export default ScrollableText;
