import React, { useContext } from 'react';
import './button.scss';

import LabelW500H12 from '../label/labelW500H12';

interface ButtonProps {
  text: string;
  color: string;
  callback: any;
}

const BasicButton = ({ text, color, callback }: ButtonProps) => {
  
  return (
    <button className='basicButton' onClick={callback}>
      <LabelW500H12 text={text} color={color} />
    </button>
  );
};

export default BasicButton;
