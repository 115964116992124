import styled from "styled-components";

export default styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    margin-bottom: 2rem;
    h2{
        color:#00000099;
        max-width:29rem;
        font-size: 2.5rem;
        font-weight: 400;
        font-family: Lexend;
        margin:0;
        margin-bottom:5rem;
    }

    >div{
        width:100%;
        max-width:1200px;
        >div{
        display:flex;
        justify-content:center;
        flex-wrap: wrap;
        >img{
            width:12.5rem;
            height:16.19rem
        }
        .container_more{
            display:flex;
            width:25rem;
            height:16.19rem;
            background: #9C1DFE;
            flex-direction: column;
            box-sizing: border-box;
            justify-content:end;
            
            .wave{
                width: 12.5rem;
                height:auto;
                align-self: flex-end;
                >img{
                    width:100%;
                }
            }
            p{
                font-size: 1.5rem;
                color: #fff;
                font-weight: 500;
                max-width:16rem;
                margin-left:1rem;
                font-family: Lexend;
            }
        }
    }
    }

    @media(max-width:768px){

        h2{
            color:#000000CC;
            font-size: 1.5rem;
        max-width:inherit;
        margin-bottom: 2rem;
        }
        >div{
        max-width:624px;
            >div{
                >img{
                    width:6.5rem;
                    height:8.375rem;
                }
        .container_more{
                    width:13rem;
                    height:8.375rem;

                    .wave{
                        width:8rem;
                    }
                    p{
                        font-size:0.875rem;
                max-width:8.5rem;
                    }

        }
            }
        }
    }

    @media(max-width:768px){

h2{
        color:#00000099;
}
>div{
max-width:304px;
    >div{
        >img{
            width:4.75rem;
            height:6.15rem;
        }
.container_more{
    position:relative;
            width:100%;
            height:6.15rem;
            overflow:hidden;

            .wave{
                width:15rem;
                position:absolute;
                top:-80%;
                right:0;
            }
            p{
                font-size:0.875rem;
        max-width:8.5rem;

            }

}
    }
}
}

`