interface DevicesProps {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
}

const breakpoints = {
  xs: "426px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1269px",
  "2xl": "1536px",
};

export const devices: DevicesProps = {
  xs: `(max-width: ${breakpoints.xs})`,
  sm: `(max-width: ${breakpoints.sm})`,
  md: `(max-width: ${breakpoints.md})`,
  lg: `(max-width: ${breakpoints.lg})`,
  xl: `(max-width: ${breakpoints.xl})`,
  "2xl": `(max-width: ${breakpoints["2xl"]})`,
};

export const URL =
  "https://api.plataformaoceano.com.br/v1/SharpSpring/InstitutionalPageForm";
export const URL_SHARP =
  "https://api.plataformaoceano.com.br/v1/SharpSpring/InstitutionalReceivedNews";
export const URL_SITE = "https://plataformaoceano.com.br";
export const URL_TOKEN = "https://carteira.nftrend.io/";
export const URL_TERMS_TOKEN =
  "https://drive.google.com/file/d/1OsLr9CRr22PzETkvE0YZIhjG9ffz-rvn/view";
