import React from "react";

import './policy.scss';
import PolicyTerm from "../../components/Navigation/policyTerm";
import { Pages } from "../../types/enums";
import PrivacyPolicy from "../../components/contracts/privacyPolicy";

const Policy = () => {
  return (
    <div className="policy">
      <PolicyTerm page={Pages.policy} />
      <PrivacyPolicy />
    </div>
  );
}

export default Policy;
