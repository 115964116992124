import React from "react";
import { Pages } from "../../types/enums";
import Oceano from "../../types/oceano";
import { useNavigate } from "react-router";

export default class OceanTokenController {
    constructor(
        private navigate = useNavigate(),
        private oceano = new Oceano(),
    ) {
    }
}