import React from 'react';
import {
  Container,
  StartLine,
  EndLine
} from './styles';

interface LineProps {
  color?: string;
  backgroundColor?: string;
}

const Line = ({ color, backgroundColor }: LineProps) => {
  return (
    <Container>
      <StartLine color={color} />
      <EndLine color={backgroundColor} />
    </Container>
  );
};

export default Line;
