import React from "react";

import './partner.scss';
import LabelW300H32 from "../label/labelW300H32";
import PartnerLogo from "./partnerLogo";

const Partner = () => {
  return (
    <div className="partner">
      <LabelW300H32 text="Clientes e parceiros" />
      <PartnerLogo />
    </div>
  );
}

export default Partner;
