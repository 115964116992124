import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 16rem;
  padding: 7%;
  background-color: #FDFDFD;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a{
    width:100%;
    text-decoration: none;
  }

  @media only screen and (${devices.lg}) {
    height: 18rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #1D7EFE;
  border-radius: 0.25rem;

  @media only screen and (${devices.lg}) {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  color: #FDFDFD;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  width: 31.8125rem;

  @media only screen and (${devices.sm}) {
    width: 80%;
  }
`;

export const AreaButton = styled.div`
  display: flex;
  width: 19.0625rem;

  @media only screen and (${devices.xs}) {
    width: 15.0625rem;
  }
`;
