import React from 'react';
import './button.scss';

import LabelW500H12 from '../label/labelW500H12';
import LabelW400H16 from '../label/labelW400H16';

interface ButtonProps {
  text: string;
  callback: any;
}

const ActionButton = ({ text, callback }: ButtonProps) => {
  return (
    <button className='actionButton' onClick={callback}>
        <LabelW400H16 text={text} isWhite={true} />
    </button>
  );
};

export default ActionButton;