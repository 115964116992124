import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  ImageContainer,
  AreaImage,
  Description,
} from "./styles";
import img75 from "../../../assets/images/75.webp";
import Button from "../../../components/Button";
import { URL_SITE } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

const GetToKnowOceanToken = () => {
  const navigate = useNavigate();
  
  const openLink = () => {
    navigate('/');
  };

  return (
    <Container>
      <Context>
        <AreaText>
          <Title>Conheça a Oceano Tokens</Title>
          <Description>
            Iniciativa exclusiva e inovadora, colaborando com a carreira do novo
            rosto da moda e resgatando excelentes benefícios
          </Description>
          <Button text="Acesse nosso site" width="14rem" onClick={openLink} />
        </AreaText>
        <ImageContainer>
          <AreaImage url={img75} />
        </ImageContainer>
      </Context>
    </Container>
  );
};

export default GetToKnowOceanToken;
