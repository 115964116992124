import React from 'react';
import {
  Container,
  Content,
  Context,
  AreaDescription,
  AreaText,
  Footer,
  Title,
  Description,
  BackgroundImage
} from './styles';
import LabelW400H12 from '../../../components/label/labelW400H12';
import GoogleLogo from '../../../assets/images/google.webp';
import AppleLogo from '../../../assets/images/apple.webp';

const MainBanner = () => {
  const goStore = (url: string) => {
    if (url === 'google')
      window.open('https://play.google.com/store/apps/details?id=oceano.mobile1233', '_blank');
    else if (url === 'apple')
      window.open('https://apps.apple.com/br/app/oceano/id1629763551', '_blank');
  }

  return (
    <Container>
      <Content>
        <Context>
          <AreaDescription>
            <AreaText>
              <Title>Trabalhe com grandes marcas do Brasil</Title>
              <Description>No App Oceano você tem a oportunidade de dar início ou impulsionar sua carreira no mercado! Comece a trilhar o caminho do sucesso hoje mesmo.</Description>
            </AreaText>
          </AreaDescription>
          <Footer>
            <AreaText>
              <LabelW400H12 text='Baixe agora o App Oceano:' />
              <div className='store'>
                <img src={GoogleLogo} alt='google-play-icon' onClick={() => goStore('google')} />
                <img src={AppleLogo} alt='app-store-icon' onClick={() => goStore('apple')} />
              </div>
            </AreaText>
          </Footer>
        </Context>
        <BackgroundImage />
      </Content>
    </Container>
  );
};

export default MainBanner
