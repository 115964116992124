import React from "react";

import './labelBG.scss';

interface props {
    text: string;
}

const LabelContent = ({text}: props) => {
    return (
        <div className="labelContent">
            <p className="labelContentText">{text}</p>
        </div>
    );

}

export default LabelContent;