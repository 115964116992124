import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    color: string
}

const LabelW700H16 = ({ text, color }: props) => {
    const [textColor, textColorSet] = useState<string>('');

    useEffect(() => {
        textColorSet(color)
    }, []);
    
    return (
        <div className={`labelW700H16 ${textColor}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW700H16;