import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    isCenter: boolean;
}

const LabelW400H32 = ({ text, isCenter }: props) => {
    const[center, centerSet] = useState<string>('');
    
    useEffect(() => {
        let text = isCenter ? 'center' : '';
        centerSet(text);
    });

    return (
        <div className={`labelW400H32 ${center}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW400H32;