import React from "react";

import './policy.scss';
import PolicyTerm from "../../components/Navigation/policyTerm";
import { Pages } from "../../types/enums";
import TermPolicy from "../../components/contracts/termsPolicy";

const Terms = () => {
    return (
        <div className="policy">
            <PolicyTerm page={Pages.terms} />
            <TermPolicy />
        </div>
    );
}

export default Terms;