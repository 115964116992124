import React, { useEffect, useState } from "react";
import './form.scss';
import LabelW700H26 from "../label/labelW700H26";
import LabelW400H14 from "../label/labelW400H14";
import InputCode from "../Input/inputCode";
import LabelW700H16 from "../label/labelW700H16";
import LabelW400H12 from "../label/labelW400H12";
import InputPassword from "../Input/inputPassword";
import ValidationPassword from "../validations/validationPassword";

interface props {
    codeSet: any;
    passwordSet: any;
    passwordConfirmSet: any;
    resendEmailCallback: any;
    showValidations: boolean;
    validations: Array<boolean>;
}

const ForgetPasswordChangePasswordForm = ({ codeSet, passwordSet, passwordConfirmSet, resendEmailCallback, showValidations, validations }: props) => {
    const [timerText, timerTextSet] = useState<string>('00:30');
    const [time, timeSet] = useState<number>(30);
    const [showCode, showCodeSet] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            if (showCode) {
                timeSet(time - 1);
                if (time >= 10) {
                    timerTextSet(`00:${time}`)
                } else if (time >= 0) {
                    timerTextSet(`00:0${time}`)
                } else {
                    showCodeSet(false);
                }
            }
        }, 1000);
    }, [time]);

    const resendEmail = () => {
        timeSet(30);
        timerTextSet(`00:${time}`);
        showCodeSet(true);
        resendEmailCallback();
    }

    return (
        <div className="forgetPasswordForm">
            <LabelW700H26 text={"Esqueci a Senha"} />
            <LabelW400H14 text={"Caso o e-mail informado seja válido, você irá receber um código de para confirmar sua solicitação"} />
            {
                showCode ? (
                    <InputCode finalCodeSet={codeSet} />
                ) : null
            }
            <div className="timer">
                {
                    showCode ? (
                        <LabelW700H16 text={timerText} color={"blue"} />
                    ) : null
                }
                <LabelW400H12 text={"Não recebeu o código?"} btnText={"Enviar novamente"} color={'black'} callback={resendEmail} />
            </div>
            <div className="timer">
                <InputPassword passwordSet={passwordSet} />
                <InputPassword passwordSet={passwordConfirmSet} />
                <ValidationPassword showValidations={showValidations} validations={validations} />
            </div>
        </div>
    );
}

export default ForgetPasswordChangePasswordForm;