import React, { InputHTMLAttributes } from "react";
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
}

const Input = (props: InputProps) => {
  return <Container {...props} placeholder={props.placeholder} />;
};

export default Input;
