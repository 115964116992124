import { createContext, useState } from "react";
import LoadingModal from "../components/Modal/loadingModal";

export const LoadingContext = createContext<{
    loading: (visible: boolean) => void,
    zapShowing: (visible: boolean) => void,
}>({
    loading: () => { },
    zapShowing: () => { },
});

const LoadingProvider = ({ children }: any) => {
    const [loadingShow, loadingShowSet] = useState<boolean>(false);
    const [zapShow, zapShowSet] = useState<boolean>(true);

    const loadingVisibility = (visible: boolean) => {
        loadingShowSet(visible);
    }

    const zapShowingVisibility = (visible: boolean) => {
        zapShowSet(visible);
    }

    return (
        <LoadingContext.Provider value={{ loading: (visibility: boolean) => loadingVisibility(visibility), zapShowing: (visibility: boolean) => zapShowingVisibility(visibility) }}>
            {children}
            {
                loadingShow ? (
                    <LoadingModal />
                ) : null
            }
            {
                zapShow ? (
                    <a id="robbu-whatsapp-button" target="_blank" href="https://api.whatsapp.com/send?phone=551145802353&text=Ol%C3%A1,%20estava%20no%20site%20da%20Plataforma%20Oceano%20e%20preciso%20de%20ajuda!">
                        <img src="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-icon.svg" />
                    </a>
                ) : null
            }
        </LoadingContext.Provider>
    );
}

export default LoadingProvider;