import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 45.38rem;
  background-color: #fdfdfd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
    height: 40rem;
    justify-content: flex-start;
    margin-top: 5rem;
  }

  @media only screen and (${devices.md}) {
    height: 44rem;
    margin-top: 0;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 6%;
    padding-right: 6%;
    height: 48rem;
  }

  @media only screen and (max-width: 620px) {
    height: 36rem;
  }

  @media only screen and (max-width: 375px) {
    height: 39rem;
  }

  @media only screen and (max-width: 365px) {
    height: 44rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.lg}) {
    flex-direction: column;
    gap: 4rem;
    align-items: flex-start;
  }

  @media only screen and (${devices.xs}) {
    gap: 3rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  span {
    color: #2c1dfe;
  }

  @media only screen and (max-width: 620px) {
    font-size: 1.25rem;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 24.8125rem;

  @media only screen and (${devices.lg}) {
    width: 100%;
    flex-direction: row;
    gap: 1rem;
  }

  @media only screen and (max-width: 620px) {
    flex-direction: column;
    gap: 1.25rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 620px) {
    font-size: 1rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 33.375rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex-shrink: 0;

  @media only screen and (${devices.lg}) {
    width: 100%;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media only screen and (max-width: 1096px) {
    gap: 0.5rem;
  }

  @media only screen and (${devices.lg}) {
    gap: 1rem;
  }
`;

export const AreaItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.88rem;

  @media only screen and (max-width: 1096px) {
    gap: 0.88rem;
  }

  @media only screen and (${devices.lg}) {
    gap: 1.88rem;
  }
`;

export const Line = styled.div`
  width: 0.0625rem;
  background: #120c66;

  @media only screen and (${devices.lg}) {
    display: none;
  }
`;

export const IconWaves = styled.img`
  height: 1.25rem;
  margin-bottom: 1.5rem;

  @media only screen and (${devices.lg}) {
    margin-bottom: 0;
    margin-top: 0.7rem;
  }
`;

export const Icon = styled.img`
  @media only screen and (${devices.xs}) {
    height: 1.1875rem;
    width: 1rem;
    margin-top: 0.2rem;
  }
`;
