import React, { useState, useEffect } from "react";

import "./contracts.scss";
import LabelTitleW400H40 from "../label/labelTitelW300H40";
import LabelW500H12 from "../label/labelW500H12";
import LabelW300H12 from "../label/labelW300H12";
import TermText from "../policy/termText";
import TermsAgencyText from "../policy/termsAgencyText";
import TermTextProfessional from "../policy/termTextProfessional";
import Oceano from "../../types/oceano";
import TermsTokenText from "../policy/termsTokenText";
import LabelW300H16 from "../label/labelW300H16";

const TermPolicy = () => {
  const [termText, termTextSet] = useState<string>("hire");
  const [isToken, setIsToken] = useState<boolean>(false);
  const [selectedButton, selectedButtonText] = useState<Array<string>>([
    "selected",
    "",
    "",
    "",
  ]);

  const oceano = new Oceano();

  const termShow = (termItem: number) => {
    if (termItem === 1) {
      termTextSet("hire");
      selectedButtonText(["selected", "", "", ""]);
    } else if (termItem === 2) {
      termTextSet("agency");
      selectedButtonText(["", "selected", "", ""]);
    } else if (termItem === 3) {
      termTextSet("professional");
      selectedButtonText(["", "", "selected", ""]);
    } else if (termItem === 4) {
      termTextSet("token");
      selectedButtonText(["", "", "", "selected"]);
    }
  };

  useEffect(() => {
    (() => {
      const token = oceano.tokenGet();
      if (oceano.isEmpityString(token)) {
        setIsToken(false);
      } else setIsToken(true);
    })();
  }, []);

  return (
    <div className="privacyPolicy">
      <div className="title">
        <div className="titleHeader">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="80" height="80" rx="4" fill="#EDF5FF" />
            <path
              d="M30 21H42.5V31C42.5 32.4062 43.5938 33.5 45 33.5H55V56C55 58.8125 52.7344 61 50 61H30C27.1875 61 25 58.8125 25 56V26C25 23.2656 27.1875 21 30 21ZM45 21L55 31H45V21ZM31.25 26C30.5469 26 30 26.625 30 27.25C30 27.9531 30.5469 28.5 31.25 28.5H36.25C36.875 28.5 37.5 27.9531 37.5 27.25C37.5 26.625 36.875 26 36.25 26H31.25ZM31.25 31C30.5469 31 30 31.625 30 32.25C30 32.9531 30.5469 33.5 31.25 33.5H36.25C36.875 33.5 37.5 32.9531 37.5 32.25C37.5 31.625 36.875 31 36.25 31H31.25ZM32.5 38.5C31.0938 38.5 30 39.6719 30 41V46C30 47.4062 31.0938 48.5 32.5 48.5H47.5C48.8281 48.5 50 47.4062 50 46V41C50 39.6719 48.8281 38.5 47.5 38.5H32.5ZM32.5 41H47.5V46H32.5V41ZM43.75 53.5C43.0469 53.5 42.5 54.125 42.5 54.75C42.5 55.4531 43.0469 56 43.75 56H48.75C49.375 56 50 55.4531 50 54.75C50 54.125 49.375 53.5 48.75 53.5H43.75Z"
              fill="#2C1DFE"
            />
          </svg>
          <LabelTitleW400H40
            text={"Termos de Uso"}
            text2={""}
            span={""}
            isSameLane={true}
          />
        </div>
        <div className="titleBodyTerm">
          <div className="menu">
            <button
              className={`menuItem ${selectedButton[0]}`}
              onClick={() => termShow(1)}
            >
              CONTRATANTE
            </button>
            <div>|</div>
            <button
              className={`menuItem ${selectedButton[1]}`}
              onClick={() => termShow(2)}
            >
              AGÊNCIA
            </button>
            <div>|</div>
            <button
              className={`menuItem ${selectedButton[2]}`}
              onClick={() => termShow(3)}
            >
              PROFISSIONAL
            </button>
            {isToken && (
              <>
                <div>|</div>
                <button
                  className={`menuItem ${selectedButton[3]}`}
                  onClick={() => termShow(4)}
                >
                  TOKEN
                </button>
              </>
            )}
          </div>
          {termText === "token" ? (
            <div>
              <LabelW500H12 text={"DATA DA ÚLTIMA ATUALIZAÇÃO"} />
              <LabelW300H12 text={"15/12/2023"} isWhite={false} />
            </div>
          ) : (
            <div>
              <LabelW500H12 text={"DATA DA ÚLTIMA ATUALIZAÇÃO"} />
              <LabelW300H12 text={"11/05/2022"} isWhite={false} />
            </div>
          )}
        </div>
      </div>
      {termText === "hire" ? (
        <TermText callback={termShow} />
      ) : termText === "agency" ? (
        <TermsAgencyText callback={termShow} />
      ) : termText === "professional" ? (
        <TermTextProfessional callback={termShow} />
      ) : termText === "token" ? (
        <TermsTokenText />
      ) : null}
    </div>
  );
};

export default TermPolicy;
