import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    color?: string;
}

const LabelW700H26 = ({ text, color = '' }: props) => {
    const [colorText, colorTextSet] = useState<string>('');

    useEffect(() => {
        colorTextSet(color);
    }, []);

    return (
        <div className={`labelW700H26 ${colorText}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW700H26;