import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  height: 25.5rem;
  background-color: #fdfdfd;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (${devices.sm}) {
    height: 30rem;
  }
`;

export const Context = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin: 0px 120px; */
  margin: 0 8%;

  @media only screen and (max-width: 1362px) {
    margin: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    margin: 0 5%;
  }

  @media only screen and (max-width: 1143px) {
    flex-direction: column;
    margin-left: 7%;
    gap: 3rem;
  }

  @media only screen and (${devices.lg}) {
    margin: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    margin: 0 3%;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 1.25rem;
  }
`;

export const AreaTitle = styled.div`
  display: flex;
  width: 100%;
  max-width: 24.1875rem;

  @media only screen and (${devices.sm}) {
    max-width: 97%;
  }
`;

export const AreaCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;

  @media only screen and (${devices.sm}) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 12.333333rem;

  @media only screen and (${devices.sm}) {
    max-width: 97%;
    gap: 0.75rem;
  }
`;

export const TitleCard = styled.p`
  color: #2c1dfe;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2rem;
  }
`;

export const Description = styled(TitleCard)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  font-weight: 300;
`;
