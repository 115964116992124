import React from "react";
import { BtnHeaderText, BtnHeader } from '../styles';

interface ButtunHeaderProps {
  text: string;
  icon: string;
  onClick?: () => void;
}

const ButtunHeader = ({ text, icon, onClick } : ButtunHeaderProps) => {
  return (
    <BtnHeader onClick={onClick}>
      <img src={icon} alt={text} />
      <BtnHeaderText>{text}</BtnHeaderText>
    </BtnHeader>
  );
};

export default ButtunHeader;
