import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../theme/lightTheme";
import { ForgotPasswordDialogContext } from "./Context";
import OtpInput from "../OTPInput/OtpInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleX,
} from "@fortawesome/pro-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import { API } from "../../services/authService";

const regexUpperCase = /^(?=.*[A-Z]).+$/;
const regexLowercase = /^(?=.*[a-z]).+$/;
const regexNumber = /^(?=.*[0-9]).+$/;
const caracterExpecial = new RegExp("[?!@#$%^ˆ&*()]");

const ChangePasswordStep = () => {
  const { actions, store } = useContext(ForgotPasswordDialogContext);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const [code, setCode] = useState("");
  const [codeInputError, setCodeInputError] = useState(false);
  const [passwordInputError, setPasswordInputError] = useState(false);
  const [confirmationPasswordInputError, setConfirmationPasswordInputError] =
    useState(false);
  const [initialCounter, setInitialCounter] = useState(25);
  const [counter, setCounter] = useState(initialCounter);

  const passwordHasOneLower = regexLowercase.test(password);
  const passwordHasOneUpper = regexUpperCase.test(password);
  const passwordHasOneSpecial = caracterExpecial.test(password);
  const passwordHasOneNumber = regexNumber.test(password);
  const passwordHasEightChar = password.length >= 8;
  const equalPasswords = confirmationPassword === password;

  const validPassword =
    passwordHasOneLower &&
    passwordHasOneUpper &&
    passwordHasOneSpecial &&
    passwordHasOneNumber &&
    passwordHasEightChar;

  const themeStyles = {
    textColor: colors.neutralsColors.N900,
    infoColor: "rgba(0, 0, 0, 0.6)",
    disabledInfoColor: colors.neutralsColors.N50,
    buttonBackground:
      "linear-gradient(179.82deg, #6A5FFC 0.16%, #2C1DFE 51.04%, #0F00DA 99.84%)",
    buttonBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 4px 8px rgba(5, 3, 26, 0.1)",
    buttonHoverBackground:
      "linear-gradient(179.82deg, #6A5FFC 0.16%, #2C1DFE 51.04%, #1100FF 99.84%)",
    buttonHoverBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 8px 16px -4px rgba(5, 3, 26, 0.16)",
    buttonActiveBackground: colors.primaryColors.BLUE.default,
    buttonActiveBoxShadow:
      "0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.025), 0px 2px 4px rgba(5, 3, 26, 0.08)",
    buttonColor: "white",
    inputBackground: "white",
    inputTextColor: colors.neutralsColors.N900,
    upperValidationColor: passwordHasOneUpper
      ? colors.primaryColors.BLUE.default
      : colors.secondaryColors.RED.default,
    lowerValidationColor: passwordHasOneLower
      ? colors.primaryColors.BLUE.default
      : colors.secondaryColors.RED.default,
    specialValidationColor: passwordHasOneSpecial
      ? colors.primaryColors.BLUE.default
      : colors.secondaryColors.RED.default,
    numberValidationColor: passwordHasOneNumber
      ? colors.primaryColors.BLUE.default
      : colors.secondaryColors.RED.default,
    lengthValidationColor: passwordHasEightChar
      ? colors.primaryColors.BLUE.default
      : colors.secondaryColors.RED.default,
    equalValidationColor: equalPasswords
      ? colors.primaryColors.BLUE.default
      : colors.secondaryColors.RED.default,
  };
  const handlePasswordChange = (event: any) => {
    const passwordWithoutSpaces = event.target.value.trim();
    setPassword(passwordWithoutSpaces);
  };

  useEffect(() => {
    if (password.length === 0 || validPassword) {
      setPasswordInputError(false);
    }
    if (confirmationPassword.length === 0 || equalPasswords) {
      setConfirmationPasswordInputError(false);
    }
  }, [password, confirmationPassword]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleConfirmationPasswordChange = (event: any) => {
    const passwordWithoutSpaces = event.target.value.trim();
    setConfirmationPassword(passwordWithoutSpaces);
  };
  const handleClickShowConfirmationPassword = () =>
    setShowConfirmationPassword((show) => !show);

  const handleCheckValid = (isValid: boolean) => {
    return isValid ? (
      <FontAwesomeIcon
        icon={faCircleCheck}
        style={{
          color: colors.primaryColors.GREEN.default,
          width: "18px",
          height: "16px",
        }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faCircleX}
        style={{
          color: colors.secondaryColors.RED.default,
          width: "18px",
          height: "16px",
        }}
      />
    );
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    code.length === 6 && setCodeInputError(false);
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let errorPresent = false;
    if (
      password.length === 0 ||
      confirmationPassword.length === 0 ||
      !validPassword ||
      !equalPasswords
    ) {
      setPasswordInputError(true);
      setConfirmationPasswordInputError(true);
      errorPresent = true;
    }
    if (code.length !== 6) {
      setCodeInputError(true);
      errorPresent = true;
    }
    if (errorPresent) {
      toast("Revise os campos e tente novamente por favor!", { type: "error" });
      return;
    }
    const payload = {
      email: store.payloadEmail,
      senha: password,
      codigo: code,
    };
    try {
      await API.changePassword(payload);

      actions.setPayloadPassword(password);
      actions.setStep("changePasswordSuccessful");
    } catch (e) {
      setPasswordInputError(true);
      setCodeInputError(true);
      setPassword("");
      setConfirmationPassword("");
      setCode("");
      toast("Algo inesperado aconteceu, por favor tente novamente!", {
        type: "error",
      });
      console.log(e);
    }
  };

  function secondsToMmSsTime(totalSeconds: number) {
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
    if (minutes < 10) {
      // @ts-ignore
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      // @ts-ignore
      seconds = "0" + seconds;
    }
    return `${minutes}:${seconds}`;
  }

  const handleSendCodeAgain = async () => {
    const payload = {
      email: store.payloadEmail,
    };
    setInitialCounter(initialCounter + 25);
    setCounter(initialCounter + 25);
    try {
      await API.forgotPassword(payload);
    } catch (e) {
      console.log(e);
    }
  };

  const passwordInputStyle = {
    backgroundColor: themeStyles.inputBackground,
    width: "100%",
    height: "48px",
    borderRadius: "4px",
    fieldset: { borderColor: colors.primaryColors.BLUE.default },
    "& .MuiOutlinedInput-root": {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor:
          password.length > 0 && (!validPassword || passwordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
      "&.Mui-focused fieldset": {
        borderColor:
          password.length > 0 && (!validPassword || passwordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor:
          password.length > 0 && (!validPassword || passwordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
      "&:hover fieldset": {
        borderColor:
          password.length > 0 && (!validPassword || passwordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
    },
  };

  const confirmPasswordInputStyle = {
    backgroundColor: themeStyles.inputBackground,
    width: "100%",
    height: "48px",
    borderRadius: "4px",
    fieldset: { borderColor: colors.primaryColors.BLUE.default },
    "& .MuiOutlinedInput-root": {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor:
          confirmationPassword.length > 0 &&
          (!equalPasswords || confirmationPasswordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
      "&.Mui-focused fieldset": {
        borderColor:
          confirmationPassword.length > 0 &&
          (!equalPasswords || confirmationPasswordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor:
          confirmationPassword.length > 0 &&
          (!equalPasswords || confirmationPasswordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
      "&:hover fieldset": {
        borderColor:
          confirmationPassword.length > 0 &&
          (!equalPasswords || confirmationPasswordInputError)
            ? colors.secondaryColors.RED.default
            : colors.primaryColors.BLUE.default,
      },
    },
  };

  const { height } = useWindowDimensions();
  //decrease margin bottom as the screen height diminish
  const marginBottom = 104 - (1043 - height) / 2;
  const adjustedMarginBottom =
    height < 1043
      ? marginBottom > 32
        ? marginBottom + "px"
        : "32px"
      : "104px";

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          width: { xs: "304px", sm: "384px" },
        }}
      >
        <Typography
          variant={"h5"}
          sx={{
            color: colors.primaryColors.BLUE.default,
            fontSize: "26px",
            lineHeight: "24px",
          }}
        >
          Esqueci a Senha
        </Typography>
        <Typography
          variant={"body2"}
          sx={{ color: themeStyles.textColor, fontWeight: "300" }}
        >
          Caso o e-mail informado seja válido, você irá receber um código para
          confirmar sua solicitação
        </Typography>
        <OtpInput
          value={code}
          valueLength={6}
          onChange={setCode}
          error={codeInputError}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: colors.primaryColors.BLUE.default }}
          >
            {secondsToMmSsTime(counter)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: themeStyles.infoColor,
              fontWeight: "300",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Não recebeu o código?&nbsp;
            <Button
              sx={{
                color: themeStyles.infoColor,
                textTransform: "none",
                padding: 0,
                ":hover": {
                  color: themeStyles.infoColor,
                  textDecorationLine: "underline",
                },
                ":disabled": { color: themeStyles.disabledInfoColor },
              }}
              onClick={handleSendCodeAgain}
              disabled={counter > 0}
              tabIndex={-1}
            >
              Enviar novamente
            </Button>
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: "24px" }}>
          <TextField
            error={password.length > 0 && !validPassword}
            type={showPassword ? "text" : "password"}
            value={password}
            name="password"
            sx={passwordInputStyle}
            onChange={handlePasswordChange}
            label={password ? "Senha" : ""}
            placeholder={"Senha"}
            variant="outlined"
            InputLabelProps={{
              style: { color: colors.neutralsColors.N50, fontWeight: "300" },
            }}
            inputProps={{
              style: {
                WebkitTextFillColor: themeStyles.inputTextColor,
              },
            }}
            InputProps={{
              style: {
                width: "100%",
                height: "48px",
                borderWidth: "1px",
                borderColor: colors.primaryColors.BLUE.default,
                color: themeStyles.inputTextColor,
                fontSize: "16px",
                fontWeight: "300",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{
                      color: colors.primaryColors.BLUE.default,
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      tabIndex={-1}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{
                            color: colors.primaryColors.BLUE.default,
                            width: "18px",
                            height: "16px",
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{
                            color: colors.primaryColors.BLUE.default,
                            width: "18px",
                            height: "16px",
                          }}
                        />
                      )}
                    </IconButton>
                    {passwordInputError && password.length === 0 && (
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        style={{
                          color: colors.secondaryColors.YELLOW.default,
                          width: "18px",
                          height: "16px",
                        }}
                      />
                    )}
                    {password.length > 0 &&
                      validPassword &&
                      !passwordInputError && (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          style={{
                            color: colors.primaryColors.GREEN.default,
                            width: "18px",
                            height: "16px",
                          }}
                        />
                      )}
                    {password.length > 0 &&
                      (!validPassword || passwordInputError) && (
                        <FontAwesomeIcon
                          icon={faCircleX}
                          style={{
                            color: colors.secondaryColors.RED.default,
                            width: "18px",
                            height: "16px",
                          }}
                        />
                      )}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "grid", gap: "10px" }}>
            <TextField
              error={confirmationPassword.length > 0 && !equalPasswords}
              value={confirmationPassword}
              name="confirmationPassword"
              type={showConfirmationPassword ? "text" : "password"}
              sx={confirmPasswordInputStyle}
              onChange={handleConfirmationPasswordChange}
              label={confirmationPassword ? "Confirmar Senha" : ""}
              placeholder={"Confirmar Senha"}
              variant="outlined"
              InputLabelProps={{
                style: { color: colors.neutralsColors.N50, fontWeight: "300" },
              }}
              inputProps={{
                style: {
                  WebkitTextFillColor: themeStyles.inputTextColor,
                },
              }}
              InputProps={{
                style: {
                  width: "100%",
                  height: "48px",
                  borderWidth: "1px",
                  borderColor: colors.primaryColors.BLUE.default,
                  color: themeStyles.inputTextColor,
                  fontSize: "16px",
                  fontWeight: "300",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{
                        color: colors.primaryColors.BLUE.default,
                        width: "18px",
                        height: "18px",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmationPassword}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showConfirmationPassword ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            style={{
                              color: colors.primaryColors.BLUE.default,
                              width: "18px",
                              height: "16px",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{
                              color: colors.primaryColors.BLUE.default,
                              width: "18px",
                              height: "16px",
                            }}
                          />
                        )}
                      </IconButton>
                      {confirmationPasswordInputError &&
                        confirmationPassword.length === 0 && (
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{
                              color: colors.secondaryColors.YELLOW.default,
                              width: "18px",
                              height: "16px",
                            }}
                          />
                        )}
                      {confirmationPassword.length > 0 &&
                        equalPasswords &&
                        !confirmationPasswordInputError && (
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{
                              color: colors.primaryColors.GREEN.default,
                              width: "18px",
                              height: "16px",
                            }}
                          />
                        )}
                      {confirmationPassword.length > 0 &&
                        (!equalPasswords || confirmationPasswordInputError) && (
                          <FontAwesomeIcon
                            icon={faCircleX}
                            style={{
                              color: colors.secondaryColors.RED.default,
                              width: "18px",
                              height: "16px",
                            }}
                          />
                        )}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            {(password.length > 0 || confirmationPassword.length > 0) && (
              <Box sx={{ display: "grid", gap: "10px" }}>
                <Typography
                  variant={"body2"}
                  sx={{
                    color: themeStyles.upperValidationColor,
                    fontSize: "12px",
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleCheckValid(passwordHasOneUpper)}&nbsp;Deve conter pelo
                  menos 1 caractere maiúsculo;
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{
                    color: themeStyles.lowerValidationColor,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleCheckValid(passwordHasOneLower)}&nbsp;Deve conter pelo
                  menos 1 caractere minúsculo;
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{
                    color: themeStyles.specialValidationColor,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleCheckValid(passwordHasOneSpecial)}&nbsp;Deve conter
                  pelo menos 1 caractere especial ?!@#$%^&*();
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{
                    color: themeStyles.numberValidationColor,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleCheckValid(passwordHasOneNumber)}&nbsp;Deve conter pelo
                  menos 1 caractere numérico;
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{
                    color: themeStyles.lengthValidationColor,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleCheckValid(passwordHasEightChar)}&nbsp;Deve conter pelo
                  menos 8 caracteres;
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{
                    color: themeStyles.equalValidationColor,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleCheckValid(equalPasswords)}&nbsp;As senhas devem ser
                  iguais.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Button
          id="submit"
          type="submit"
          fullWidth
          style={{ padding: 0, marginBottom: adjustedMarginBottom }}
        >
          <Box
            sx={{
              width: "100%",
              borderRadius: "4px",
              height: "56px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: themeStyles.buttonBackground,
              boxShadow: themeStyles.buttonBoxShadow,
              ":hover": {
                background: themeStyles.buttonHoverBackground,
                boxShadow: themeStyles.buttonHoverBoxShadow,
              },
              ":active": {
                background: themeStyles.buttonActiveBackground,
                boxShadow: themeStyles.buttonActiveBoxShadow,
              },
            }}
          >
            <Typography
              sx={{
                color: themeStyles.buttonColor,
                fontSize: "18px",
                textTransform: "none",
              }}
            >
              Confirmar
            </Typography>
          </Box>
        </Button>
      </Box>
      <ToastContainer />
    </form>
  );
};

export default ChangePasswordStep;
