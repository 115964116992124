import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    center: boolean;
}

const LabelW300H20 = ({ text, center }: props) => {
    const [centerStyle, centerStyleSet] = useState<string>('');

    useEffect(() => {
        let text = center ? 'center' : '';
        centerStyleSet(text);
    }, [center]);

    return (
        <div className={`labelW300H20 ${centerStyle}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW300H20;