export enum Pages {
  home,
  media,
  personalities,
  serviceTalent,
  talent,
  aboutUs,
  policy,
  terms,
  termsToken,
  contact,
  contactToken,
  serviceToken,
  serviceTokenLogin,
  none,
  ourservices,
  couses,
}

export enum Stores {
  apple,
  google,
}
