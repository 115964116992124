import React from "react";

import "./policy.scss";
import PolicyTerm from "../../components/Navigation/policyTermToken";
import { Pages } from "../../types/enums";
import ContactPolicy from "../../components/contracts/contactPolicy";

const contactToken = () => {
  return (
    <div className="policy">
      <PolicyTerm page={Pages.contactToken} />
      <ContactPolicy />
    </div>
  );
};

export default contactToken;
