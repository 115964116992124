import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Content = styled.section`
  display: flex;
  height: 34.69rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fdfdfd;
  /* padding: 0px 120px; */
  padding: 0 8%;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
    height: 30rem;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
    height: 22rem;
  }

  padding-right: 0;
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (${devices.lg}) {
    margin-top: 6rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: start;
  margin: 0;
  margin-bottom: 2.5rem;

  @media only screen and (${devices.sm}) {
    font-size: 1.5rem;
  }
`;

export const TitleCard = styled.h1`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.7);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-top: 1.2rem;
`;

export const TextBlue = styled.span`
  color: #2c1dfe;
`;

export const DescriptionCard = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.4);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
  margin-top: 1.5rem;
`;

export const AreaCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 20.625rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 27.185rem;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;

  @media only screen and (${devices.sm}) {
    width: 22.625rem;
  }
`;

export const Space = styled.div`
  display: flex;
  width: 6.56rem;

  @media only screen and (${devices.sm}) {
    width: 2rem;
  }
`;

export const AreaDescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  margin-left: 1.06rem;
`;

export const Icon = styled.img`
  margin-top: 1.5rem;
`;
