import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Content = styled.div`
  display: flex;
  height: 40rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fdfdfd;
  /* padding: 0px 120px; */
  padding: 0 8%;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 1277px) {
    height: 64rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: start;
  margin: 0;
  margin-bottom: 1rem;
`;

export const SubTitle = styled(Title)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 3rem;
`;

export const TitleCard = styled.h1`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.8);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
`;

export const DescriptionCard = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.6);
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;

export const AreaCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 12rem;

  @media only screen and (max-width: 1277px) {
    width: 100%;
    max-width: 30rem;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 1277px) {
    width: 100%;
    max-width: 40rem;
  }
`;

export const AreaDescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  padding-right: 3%;
`;

export const Icon = styled.img``;

export const AreaButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;

  #component-button {
    max-width: 24rem;
  }
`;

export const CardContext = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1277px) {
    gap: 2rem;
  }

  @media only screen and (max-width: 620px) {
    margin-left: 2rem;
  }
`;

export const AreaIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 1277px) {
    display: none;
  }
`;

export const AreaIconMobile = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 0;
  margin-right: 1.5rem;

  @media only screen and (max-width: 1277px) {
    display: flex;
  }
`;
