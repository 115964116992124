import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    color?: string;
}

const LabelW500H12 = ({ text, color = ''}: props) => {
    const [colorClass, colorClassSet] = useState<string>('');

    useEffect(() => {
        colorClassSet(color)
    }, [color]);

    return (
        <div className={`labelW500H12 ${colorClass}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW500H12;