import React, { useEffect, useState } from "react";

import './labelBG.scss';

interface props {
    text: string;
    isBlack: boolean;
}

const LabelW500H24 = ({ text, isBlack }: props) => {
    const [textColor, textColorSet] = useState<string>('');

    useEffect(() => {
        let color = isBlack ? 'black' : '';
        textColorSet(color);

    }, [isBlack]);

    return (
        <div className={`labelW500H24 ${textColor}`}>
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW500H24;