import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 7.5rem;
  flex-shrink: 0;
  border-bottom: 1px solid #fff;
  background: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin: 0 8%;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1362px) {
    margin: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    margin: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    margin: 0 4%;
  }

  @media only screen and (max-width: 700px) {
    justify-content: center;
  }

  @media only screen and (${devices.sm}) {
    margin: 0 3%;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const ContainerNavBarFixed = styled.div`
  display: flex;
  background-color: #f8f8f8;
`;

export const BtnHeaderText = styled.h1`
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2rem;
`;

export const BtnHeader = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const AreaTextButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Img = styled.img`
  height: 1.5rem;
  cursor: pointer;
`;
