import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img71 from "../../../assets/images/71.webp";
import img72 from "../../../assets/images/72.webp";
import img73 from "../../../assets/images/73.webp";
import img74 from "../../../assets/images/74.webp";
import matchIcon from "../../../assets/icons/match-icon.svg";
import { Content, ContentImage, Image, MatchIcon, Space } from "./styles";

const SocialMedia = () => {
  const responsive = {
    0: {
      items: 10,
      itemsFit: "contain",
    },
  };

  const items = [
    <ContentImage key={1}>
      <Image url={img71}>
        <MatchIcon id="imageMatch1" src={matchIcon} />
      </Image>
      <Space />
    </ContentImage>,
    <ContentImage key={2}>
      <Image url={img72}>
        <MatchIcon id="imageMatch2" src={matchIcon} />
      </Image>
      <Space />
    </ContentImage>,
    <ContentImage key={3}>
      <Image url={img73}>
        <MatchIcon id="imageMatch3" src={matchIcon} />
      </Image>
      <Space />
    </ContentImage>,
    <ContentImage key={4}>
      <Image url={img74}>
        <MatchIcon id="imageMatch4" src={matchIcon} />
      </Image>
    </ContentImage>,
  ];

  return (
    <Content>
      <AliceCarousel
        mouseTracking
        disableButtonsControls
        disableDotsControls
        autoWidth
        responsive={responsive}
        items={items}
      />
    </Content>
  );
};

export default SocialMedia;
