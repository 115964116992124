import styled from "styled-components";
import { devices } from "../../utils/constants";

export const Container = styled.input`
  display: flex;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  border-radius: 0.25rem;
  border: 0.5px solid #262539;
  background: #FFF;

  ::placeholder {
    color: #B4AFBF;
    font-family: Lexend;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
  }

  @media only screen and (${devices.xs}) {
    height: 2.25rem;
  }
`;
