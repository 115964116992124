import React from 'react';
import SubNavigation from '../../components/SubNavigation';
import Advertise from './Advertise';
import Benefits from './Benefits';
import Enrollment from './Enrollment';
import YourBrand from './YourBrand';
import Opportunities from './Opportunities';
import Contact from './Contact';

const Media = () => (
  <>
    <SubNavigation headerPage={'NOSSOS SERVIÇOS'} isAboutUs={false} pageSelected='media' isTalent={false} />
    <Advertise />
    <Benefits />
    <Enrollment />
    <YourBrand />
    <Opportunities />
    <Contact />
  </>
);

export default Media
