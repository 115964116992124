import React from 'react';
import SubNavigation from '../../components/SubNavigation';
import HirePersonalities from './HirePersonalities';
import OutStars from './OutStars';
import Enrollment from './Enrollment';
import Budget from './Budget';

const Personalities = () => (
  <>
    <SubNavigation headerPage={'NOSSOS SERVIÇOS'} isAboutUs={false} isTalent={false} pageSelected='personalities' />
    <HirePersonalities />
    <OutStars />
    <Enrollment />
    <Budget />
  </>
);

export default Personalities
