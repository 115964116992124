import React from "react";
import ButtonNavBar from "./components/ButtonNavBar";
import {
  ContentNavBar,
  ContainerNavBarFixed,
  Title,
  AreaButtons,
} from "./styles";
import { buttonsNavBar } from ".";

const NavigaitonHome = () => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  return (
    <>
      {!isMobile && (
        <ContainerNavBarFixed>
          <ContentNavBar>
            <Title>Conheça a Oceano</Title>
            <AreaButtons>
              {buttonsNavBar.map(({ id, title }) => (
                <ButtonNavBar
                  key={id}
                  id={id}
                  title={title}
                  status={false}
                  scrollingDown
                />
              ))}
            </AreaButtons>
          </ContentNavBar>
        </ContainerNavBarFixed>
      )}
    </>
  );
};

export default NavigaitonHome;
