import styled from "styled-components";
import { devices } from "../../../utils/constants";
import backgroundWaves from "../../../assets/icons/background-waves-midia.svg";

interface ImageProps {
  url: string;
}

interface RectangleProps {
  color: string;
}

export const Content = styled.div`
  height: 35rem;
  flex-shrink: 0;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8%;
  padding-right: 8%;
  background-image: url(${backgroundWaves});
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: auto;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    height: 60rem;
    justify-content: space-evenly;
  }

  @media only screen and (max-width: 642px) {
    height: 76rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 36.875rem;
  gap: 1.25rem;

  @media only screen and (max-width: 642px) {
    flex-direction: column;
    max-width: 100%;
    width: auto;
    gap: 0.5rem;
  }
`;

export const ContextDescription = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 32.875rem;

  @media only screen and (max-width: 1270px) {
    width: 25.875rem;
  }

  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  flex: 1 0 0;
  align-self: stretch;

  @media only screen and (max-width: 642px) {
    gap: 0.5rem;
  }
`;

export const ColumnTwo = styled(Column)`
  @media only screen and (max-width: 642px) {
    flex-direction: column-reverse;
  }
`;

export const ImageContainer = styled.div`
  height: 18.8125rem;
  width: 17.8125rem;
  overflow: hidden;
  border-radius: 0.25rem;

  @media only screen and (max-width: 642px) {
    height: 13.8125rem;
    width: 19rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainer}:hover & {
    transform: scale(1.2);
  }
`;

export const Rectangle = styled.div<RectangleProps>`
  width: 17.8125rem;
  height: 10.4375rem;
  border-radius: 0.25rem;
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${devices.sm}) {
    width: 17rem;
  }

  @media only screen and (max-width: 490px) {
    width: 14rem;
  }

  @media only screen and (max-width: 642px) {
    width: 19rem;
    height: 5.625rem;
  }
`;

export const TextRectangle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  margin-left: 3rem;
  margin-right: 3rem;

  @media only screen and (${devices.xs}) {
    font-size: 0.625rem;
    font-weight: 500;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const Title = styled.h1`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media only screen and (${devices.xs}) {
    font-size: 1.5rem;
  }
`;

export const TextBlue = styled.span`
  color: #2c1dfe;
`;

export const Description = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.4);
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;

export const AreaDescription = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;

  @media only screen and (max-width: 490px) {
    flex-direction: column;
  }
`;
