import styled from "styled-components";
import { devices } from "../../../utils/constants";
import timelineIcon from "../../../assets/icons/timeline-icon.svg";
import timelineMdIcon from "../../../assets/icons/timeline-tablet-icon.svg";
import timelineSmIcon from "../../../assets/icons/timeline-mobile-icon.svg";

export const Content = styled.section`
  display: flex;
  height: 26.87rem;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fdfdfd;

  @media only screen and (max-width: 1423px) {
    justify-content: center;
  }

  @media only screen and (max-width: 1045px) {
    align-items: center;
    height: 37.87rem;
  }

  @media only screen and (${devices.xs}) {
    /* justify-content: flex-start; */
    align-items: center;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7.56rem;
  width: 100%;

  @media only screen and (max-width: 1423px) {
    margin-left: 0;
  }

  @media only screen and (max-width: 1045px) {
    margin-top: 7rem;
  }

  @media only screen and (${devices.xs}) {
    margin-bottom: 7.5rem;
    margin-top: 7.5rem;
  }
`;

export const ContextTimeline = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: 1423px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Text = styled.h1`
  color: rgba(0, 0, 0, 0.7);
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (max-width: 1423px) {
    margin-inline: 7rem;
  }

  @media only screen and (${devices.sm}) {
    font-size: 1.5rem;
  }

  @media only screen and (${devices.xs}) {
    margin-inline: none;
  }
`;

export const Timeline = styled.div`
  background-image: url(${timelineIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 75.75rem;
  height: 12.5rem;
  flex-shrink: 0;
  margin-top: 2rem;
  max-width: 100%;

  @media only screen and (${devices.xl}) {
    width: 57.75rem;
    height: 9.5rem;
  }

  @media only screen and (${devices.lg}) {
    width: 43rem;
    height: 10rem;
    background-image: url(${timelineMdIcon});
  }

  @media only screen and (${devices.md}) {
    width: 35rem;
    height: 9rem;
  }

  @media only screen and (${devices.sm}) {
    background-image: url(${timelineSmIcon});
    width: 23rem;
    height: 20rem;
  }

  @media only screen and (${devices.xs}) {
    width: 26rem;
    height: 19rem;
  }
`;
