import React, { useEffect, useState } from "react";
import './labelBG.scss';
import error from '../../assets/icons/errorIcon.svg';
import correct from '../../assets/icons/correctIcon.svg';

interface props {
    text: string;
    color?: string;
    hasIcon?: boolean;
}

const LabelW400H12 = ({ text, color = '', hasIcon = false }: props) => {
    const [textColor, textColorSet] = useState('');
    const [icon, iconSet] = useState('');

    useEffect(() => {
        textColorSet(color);
        iconSet(color == "red" ? error : correct);
    }, [color]);

    return (
        <div className={`labelW400H10 ${color}`}>
            {
                hasIcon ? (
                    <img src={icon} alt="icon" />
                ) : null
            }
            <p className="labelContent">
                {text}
            </p>
        </div>
    );

}

export default LabelW400H12;