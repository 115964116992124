import styled from "styled-components";
import { devices } from "../../../utils/constants";
import backgroundElements from "../../../assets/icons/background-elements-media.svg";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  background: linear-gradient(to left, #090633 70%, #2c1dfe 30%);
a{
  width:100%;
  text-decoration: none;
}
  @media only screen and (${devices.lg}) {
    background: linear-gradient(to top, #090633 70%, #2c1dfe 30%);
  }
`;

export const BackgroundElements = styled(Container)`
  display: flex;
  height: 35rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  padding: 0 8%;
  background-image: url(${backgroundElements});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
    height: 60rem;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.lg}) {
    flex-direction: column;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;

  @media only screen and (${devices.lg}) {
    align-items: center;
    gap: 2rem;
    margin-top: 5rem;
  }

  @media only screen and (max-width: 535px) {
    width: 16.44144rem;
  }
`;

export const Title = styled.h1`
  color: #fdfdfd;
  font-family: Lexend;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;

  @media only screen and (${devices.lg}) {
    font-size: 2rem;
    text-align: center;
  }

  @media only screen and (max-width: 535px) {
    font-size: 1.25rem;
  }
`;

export const ImageContainer = styled.div`
  width: 24.125rem;
  height: 24.125rem;
  overflow: hidden;
  border-radius: 0.25rem;

  @media only screen and (max-width: 535px) {
    width: 16.44144rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  transition: transform 0.8s ease;

  ${ImageContainer}:hover & {
    transform: scale(1.2);
  }
`;
