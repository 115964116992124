import React, { useState } from "react";
import { Container, Context, Title, AreaTitle, AreaButton } from "./styles";
import Button from "../../../components/Button";
import Register from "../../../components/Register";

const PlugItIn = () => {
  const [toggleRegisterModal, setToggleRegisterModal] =
    useState<boolean>(false);

  return (
    <Container>
      <Context>
        <AreaTitle>
          <Title>
            Profissionais de qualidade para todo tipo de campanha...
          </Title>
        </AreaTitle>
        <AreaButton>
            <a href="https://cadastro.plataformaoceano.com.br/register/contratante" target="_blank">
          <Button
            text="Conecte-se agora"
            color="#0F0E17"
            backgroundColor="#F8F8F8"
            onClick={() => setToggleRegisterModal(true)}
          />
          </a>
        </AreaButton>
      </Context>
      <Register
        toggleModal={toggleRegisterModal}
        setToggleModal={setToggleRegisterModal}
      />
    </Container>
  );
};

export default PlugItIn;
