import React from 'react';
import SubNavigation from '../../components/SubNavigation';
import Advertise from './Advertise';
import DiscoverThePlatform from './DiscoverThePlatform';
import Warnings from './Warnings';
import FindTalent from './FindTalent';
import HowItWorks from './HowItWorks';
import SocialMedia from './SocialMedia';
import PlugItIn from './PlugItIn';
import Mission from './Mission';
import Enrollment from './Enrollment';


const ServiceTalent = () => (
  <>
    <SubNavigation headerPage={'NOSSOS SERVIÇOS'} isAboutUs={false} pageSelected='service-talent' isTalent={false} />
    <Advertise />
    <DiscoverThePlatform />
    <Warnings />
    <FindTalent />
    <HowItWorks />
    <SocialMedia />
    <PlugItIn />
    <Mission />
    <Enrollment />
  </>
);

export default ServiceTalent
