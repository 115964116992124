import React, { useEffect, useState } from "react";

import './labelBG.scss';
import error from '../../assets/icons/errorIcon.svg';

interface props {
    text: string;
    color: string;
    hasError?: boolean;
}


const LabelW500H10 = ({ text, color, hasError = false }: props) => {
    const [colorClass, colorClassSet] = useState<string>('');

    useEffect(() => {
        colorClassSet(color)
    }, [color]);

    return (
        <div className={`labelW500H10 ${colorClass}`} >
            {
                hasError ? (
                    <img src={error} alt="icon" />
                ) : null
            }
            <p className="labelContent">
                {text}
            </p>
        </div>
    );
}

export default LabelW500H10;