import styled from "styled-components";
import { devices } from "../../../utils/constants";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 7.4371rem;
  background-color: #05031a;
  padding-left: 7.5rem;
  padding-right: 7.5rem;

  @media only screen and (max-width: 1423px) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  @media only screen and (${devices.md}) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    height: 14.4371rem;
  }

  @media only screen and (max-width: 535px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    cursor: pointer;
  }

  @media only screen and (${devices.sm}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  @media only screen and (${devices.xs}) {
    img {
      height: 30px;
    }
  }

  @media only screen and (max-width: 353px) {
    img {
      height: 28px;
    }
  }
`;

export const DescriptionButton = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

export const ImageArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  @media only screen and (max-width: 353px) {
    gap: 2rem;
  }
`;
