import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Container = styled.div`
  display: flex;
  height: 48rem;
  background-color: #fdfdfd;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  padding: 0 8%;

  @media only screen and (max-width: 1362px) {
    padding: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    padding: 0 5%;
    height: 64rem;
  }

  @media only screen and (${devices.lg}) {
    padding: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    padding: 0 3%;
  }

  @media only screen and (max-width: 485px) {
    height: 50rem;
  }

  @media only screen and (${devices.xs}) {
    height: 45rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 1170px) {
    flex-direction: column;
    gap: 5rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 30.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;

  @media only screen and (max-width: 1170px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  font-family: Lexend;
  font-size: 5.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin: 0;

  @media only screen and (max-width: 1170px) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 485px) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2rem;
  margin: 0;

  @media only screen and (max-width: 485px) {
    font-size: 1rem;
    line-height: 120%;
  }
`;

export const ImageContainer = styled.div`
  width: 33.125rem;
  height: 32.5rem;
  overflow: hidden;

  @media only screen and (max-width: 570px) {
    width: 26.125rem;
    height: 25.5rem;
  }

  @media only screen and (max-width: 450px) {
    width: 21.125rem;
    height: 20.5rem;
  }

  @media only screen and (max-width: 353px) {
    width: 18.125rem;
    height: 17.5rem;
  }
`;

export const AreaImage = styled.div<ImageProps>`
  max-width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
`;
