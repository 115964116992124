import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Context,
  AreaText,
  Title,
  Description,
  ButtonArea,
} from "./styles";
import Button from "../../../components/Button";
import icon from "../../../assets/icons/chevron-right-icon.svg";

const Solutions = () => {
  const navigate = useNavigate();

  const navigateTo = (route: "media" | "personalities" | "service-talent") => {
    navigate(`/${route}`);
  };

  return (
    <Container>
      <Context>
        <AreaText>
          <Title>
            Explore nossas soluções e leve seu negócio para além das suas
            expectativas.
          </Title>
          <Description>Dê o primeiro passo...</Description>
          <ButtonArea>
            <Button
              text="Anuncie no Rádio e TV"
              backgroundColor="#F8F8F8"
              color="#0F0E17"
              icon={icon}
              width="16.25rem"
              onClick={() => navigateTo("media")}
            />
            <Button
              text="Vitrine de Personalidades"
              backgroundColor="#F8F8F8"
              color="#0F0E17"
              icon={icon}
              width="16.25rem"
              onClick={() => navigateTo("personalities")}
            />
            <Button
              text="Contratar Talentos"
              backgroundColor="#F8F8F8"
              color="#0F0E17"
              icon={icon}
              width="16.25rem"
              onClick={() => navigateTo("service-talent")}
            />
          </ButtonArea>
        </AreaText>
      </Context>
    </Container>
  );
};

export default Solutions;
