import styled from "styled-components";
import circlesBackground from "../../../assets/icons/circles-background-personalities.svg";
import { devices } from "../../../utils/constants";

export const Section = styled.section``;

export const Container = styled.div`
  display: flex;
  height: 22.5rem;
  /* padding-left: 120px;
  padding-right: 120px; */
  background-image: url(${circlesBackground});
  background-color: #1f0633;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 8%;
  padding-right: 8%;

  @media only screen and (max-width: 1362px) {
    padding-left: 6%;
    padding-right: 6%;
  }

  @media only screen and (${devices.xl}) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media only screen and (${devices.lg}) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media only screen and (${devices.sm}) {
    padding-left: 3%;
    padding-right: 3%;
  }

  @media only screen and (${devices.lg}) {
    height: 35rem;
  }
`;

export const Context = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (${devices.lg}) {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  color: #fdfdfd;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;

  span {
    font-weight: 500;
  }

  @media only screen and (${devices.sm}) {
    font-size: 1.25rem;
  }
`;

export const AreaText = styled.div`
  display: flex;
  width: 22.8125rem;

  @media only screen and (${devices.lg}) {
    width: 100%;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  width: 100%;
  max-width: 30.72917rem;
  a{
    width:100%;
    text-decoration: none;
  }

  @media only screen and (${devices.lg}) {
    margin-top: 4rem;
  }
`;

export const AreaInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
`;

export const AreaModal = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 20rem;
`;

export const TitleModal = styled.h1`
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
`;

export const DescriptionModal = styled.p`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;
`;
