import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  ImageContainer,
  AreaImage,
  Description,
  CardArea,
  Card,
  CardDescriptionArea,
  TitleCard,
  DescriptionCard,
  ImageCard,
  ButtonArea,
} from "./styles";
import img76 from "../../../assets/images/76.webp";
import cameraIcon from "../../../assets/icons/camera-token-icon.svg";
import heartIcon from "../../../assets/icons/heart-token-icon.svg";
import targetIcon from "../../../assets/icons/target-token-icon.svg";
import Button from "../../../components/Button";

const GetToKnowGabi = () => {
  const openLink = () => {
    window.open("https://www.instagram.com/gabimatiis/", "_blank");
  };

  return (
    <Container id="get-to-know-gabi-page">
      <Context>
        <AreaText>
          <Title>
            Conheça <span>Gabi Matis</span>
          </Title>
          <Description>
            Conheça Gabi, nossa modelo cuja jornada você está ajudando a
            impulsionar. Descubra os trabalhos inspiradores que ela fez e veja
            como você pode apoiá-la ainda mais.
          </Description>
        </AreaText>
        <ImageContainer>
          <AreaImage url={img76} />
        </ImageContainer>
      </Context>
      <CardArea>
        <Card>
          <ImageCard src={cameraIcon} />
          <CardDescriptionArea>
            <TitleCard>Dicas profissionais</TitleCard>
            <DescriptionCard>
              Traga dicas para a a modelo. Sua experiência conta muito.
            </DescriptionCard>
          </CardDescriptionArea>
        </Card>
        <Card>
          <ImageCard src={heartIcon} />
          <CardDescriptionArea>
            <TitleCard>Engaje a Modelo</TitleCard>
            <DescriptionCard>
              Siga Gabi no Instagram e participe das conversas para apoiar sua
              jornada.
            </DescriptionCard>
          </CardDescriptionArea>
        </Card>
        <Card>
          <ImageCard src={targetIcon} />
          <CardDescriptionArea>
            <TitleCard>Faça parcerias</TitleCard>
            <DescriptionCard>
              Tem algum serviço que pode ajudar a modelo? Vamos amar esta
              parceria!
            </DescriptionCard>
          </CardDescriptionArea>
        </Card>
      </CardArea>
      <ButtonArea>
        <Button
          text="Siga Gabi no Instagram"
          width="16.375rem"
          height="1rem"
          onClick={openLink}
        />
      </ButtonArea>
    </Container>
  );
};

export default GetToKnowGabi;
