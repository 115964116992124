import React from "react";

import './labelBG.scss';

interface props {
    text: string;
    text2: string;
    span: string;
    isSameLane: boolean
}

const LabelTitleW400H40 = ({ text, span, text2, isSameLane }: props) => {
    return (
        <div className="labelTitleW400H40">
            <p className="labelTitleContent">
                {text}
                {
                    !isSameLane ? (
                        <br />
                    ) : null
                }
                <span className="labelTitleDetail">{span}</span>
                {text2}
            </p>
        </div>
    );

}

export default LabelTitleW400H40;