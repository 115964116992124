import React from "react";

import './new.scss';
import LabelTitle from "../label/LabelTitle";
import LabelW300H16 from "../label/labelW300H16";
import Badge from "../Badge";
import noticies from "../../assets/icons/noticias.svg";

const News = () => {
  return (
    <div className="news">
      <div className="leftContent">
        <Badge text="SOMOS NOTÍCIA" color="#114C98" backgroundColor="#EDF5FF" />
        <LabelTitle text={"Saiu "} span={"na Mídia"} isSameLane={true} text2={""} />
        <div className="leftContentText" >
          <LabelW300H16 text={"Nossa empresa tem o orgulho de estar em evidência nos principais veículos de comunicação, onde nossa missão, visão e inovações estão sendo reconhecidas. Confira as matérias e reportagens que destacam nosso trabalho e compromisso com a excelência."} isSelect={false} />
        </div>
      </div>
      <div className="rightContent">
        <img src={noticies} alt="noticies" />
      </div>
    </div>
  );
}

export default News;
