import React from "react";
import {
  Section,
  Container,
  Context,
  AreaText,
  Title,
  Description
} from './styles';
import Line from "../../../components/Line";
import mapIcon from "../../../assets/icons/map-location-icon.svg";
import checkIcon from "../../../assets/icons/badge-check-icon.svg";

const Warnings = () => {
  return (
    <Section>
      <Container>
        <Context>
          <AreaText>
            <img src={mapIcon} alt="map-icon" />
            <Title>
              O perfil ideal com alguns cliques
            </Title>
            <Description>
              A Oceano oferece uma ampla gama de opções, todas cuidadosamente filtradas para corresponder às preferências de cada cliente. Além disso, a plataforma se adapta a diferentes orçamentos, garantindo que todos os usuários encontrem soluções adequadas às suas finanças.
            </Description>
          </AreaText>
          <AreaText>
            <img src={checkIcon} alt="check-icon" />
            <Title>
              Precisa de ajuda com o briefing e investimento?
            </Title>
            <Description>
              Muitas vezes, calcular o investimento necessário pode ser desafiador. Não se preocupe! Estamos aqui para ajudar. Entre em contato conosco se você precisa de orientação com o briefing ou com o planejamento financeiro da sua campanha.
            </Description>
          </AreaText>
        </Context>
      </Container>
      <Line color="#1D7EFE" backgroundColor="#090633" />
    </Section>
  );
};

export default Warnings;
