import React, { useState } from "react";
import axios from  'axios';
import {
  Section,
  Container,
  Context,
  AreaText,
  Title,
  Form,
  AreaInput,
  AreaModal,
  TitleModal,
  DescriptionModal
} from './styles';
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Line from "../../../components/Line";
import Modal from "../../../components/Modal";
import iconModal from '../../../assets/icons/envelope-open-icon.svg';
import { URL } from "../../../utils/constants";

const Enrollment = () => {
  const [name, setName] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setName('');
    setNameCompany('');
    setEmail('');
    setPhone('');
  };

  const handleSendForm = async () => {
    if (!name || !email || !phone) {
      return alert('É necessário preencher o Nome, E-mail e Telefone!');
    }

    axios.post(
      URL,
      {
        name,
        companyName: nameCompany,
        email,
        mobile: phone,
        page: window.location.pathname.replace('/', ''),
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then(() => {
    })
    .catch(() => {
      alert('Ocorreu um erro inesperado. Tente novamente!');
    });

    setTitleModal('O seu contato foi enviado!');
    handleOpenModal();
  };

  return (
    <>
      <Section id="section-enrollment-media">
        <Container>
          <Context>
            <AreaText>
              <Title>
                Desperte o potencial da sua marca: anuncie agora no rádio e TV
              </Title>
            </AreaText>
            <Form>
              <AreaInput>
                <Input placeholder="Nome" onChange={(e) => setName(e.target.value)} />
                <Input placeholder="Nome da Empresa" onChange={(e) => setNameCompany(e.target.value)} />
                <Input placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
                <Input placeholder="Telefone" onChange={(e) => setPhone(e.target.value)} type="number" />
              </AreaInput>
              <a target="_blank" href={`${process.env.REACT_APP_BASE_URL_WEB}register/contratante`}>

              <Button text="Cadastrar agora" />
              </a>
            </Form>
          </Context>
        </Container>
        <Line color="#2C1DFE" backgroundColor="#1A1198" />
      </Section>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        isButtonClose
      >
        <AreaModal>
          <img src={iconModal} alt="icon" />
          <TitleModal>{titleModal}</TitleModal>
          <DescriptionModal>
            Nossa equipe de atendimento irá entrar em contato o quanto antes para te auxiliar nos primeiros passos da Oceano.
          </DescriptionModal>
        </AreaModal>
      </Modal>
    </>
  );
};

export default Enrollment;
