import React from "react";

import './owner.scss';

import LabelW500H24 from "../label/labelW500H24";
import LabelW300H12 from "../label/labelW300H12";
import LabelW300H32Write from "../label/labelW300H32Write";

interface props {
  text: string;
  name: string;
  description: string;
}

const OwnerDescription = ({ text, name, description }: props) => {
  return (
    <div className="ownerDescription">
      <LabelW300H32Write text={text} />
      <div className="h48"></div>
      <LabelW500H24 text={name} isBlack={false} />
      <div className="h24"></div>
      <LabelW300H12 text={description} isWhite={true} />
    </div>
  );
}

export default OwnerDescription;
