import styled from 'styled-components';
import { devices } from '../../utils/constants';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
  max-width: 80%;
  max-height: 84%;
  overflow-y: auto;
  margin-top: 4%;
  overflow-x: hidden;

  @media only screen and (${devices.xs}) {
    max-width: 98%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  z-index: 100;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #090633;
  leading-trim: both;
  text-edge: cap;
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;
