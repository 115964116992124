import { useNavigate } from "react-router-dom";
import TextButton from "../../../components/TextButton";
import Badge from "../../../components/Badge";
import arrowRightIcon from '../../../assets/icons/circle-arrow-right.svg'
import ellipseIcon from "../../../assets/images/bannerConnections.png";
import {
  Content,
  Context,
  AreaTitle,
  Title,
  TextPurple,
  Description
} from './styles';

const SuccessfulCampaigns = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };
  return (
    <Content id="section-4">
      <Context>
        <AreaTitle>
          <Badge text="CAMPANHAS DE SUCESSO" backgroundColor="#F7EDFF" color="#5E1198" />
          <Title>O que podemos <TextPurple>fazer juntos</TextPurple></Title>
          <Description>
            Amplie a visibilidade de suas campanhas na TV e no rádio de maneira descomplicada, e crie  conexões com talentos e personalidades da mídia para fortalecer sua marca.
          </Description>
          <TextButton color="#EEEDFF" icon={arrowRightIcon} text="SAIBA MAIS" style={{ marginTop: '2.5rem' }} onClick={() => handleNavigate('/media')} />
        </AreaTitle>
        <img className="banner" src={ellipseIcon}/>
      </Context>
    </Content>
  );
};

export default SuccessfulCampaigns;
