import React from "react";

import './labelBG.scss';

interface props {
    text: string;
}

const LabelBG = ({text}: props) => {
    return (
        <div className="label">
            <p className="labelAboutUs">{text}</p>
        </div>
    );

}

export default LabelBG;