import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../../components/Navigation";
import Footer from "./Footer";
import LoadingProvider from "../../context/loadingContext";

const Layout = () => {
  return (
    <>
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
