import React from "react";
import {
  Container,
  Context,
  AreaText,
  Title,
  ImageContainer,
  AreaImage,
  Description,
} from "./styles";
import img79 from "../../../assets/images/79.webp";
import Badge from "../../../components/Badge";
import TextButton from "../../../components/TextButton";
import arrowRightIcon from "../../../assets/icons/circle-arrow-right-primary-color.svg";
import { useNavigate } from "react-router-dom";

const Talent = () => {
  const navigate = useNavigate();

  const navigateTo = (route: "media" | "personalities" | "service-talent") => {
    navigate(`/${route}`);
  };

  return (
    <Container>
      <Context>
        <AreaText>
          <Badge
            text="CONTRATE TALENTOS"
            color="#fff"
            backgroundColor="#2C1DFE"
          />
          <Title>Encontre o profissional ideal para a sua campanha</Title>
          <Description>
            A nossa plataforma oferece uma maneira simplificada de conectar-se
            com milhares de talentos, incluindo modelos, influenciadores e
            especialistas em várias áreas.
            <br /> <br />
            Com apenas um cadastro, facilitamos o contato entre marcas e
            pessoas, com sistema de busca personalizada de perfil e verba,
            possibilitando uma contratação diversificada e segura.
          </Description>
          <TextButton
            color="#2C1DFE"
            icon={arrowRightIcon}
            text="SAIBA MAIS"
            onClick={() => navigateTo("service-talent")}
          />
        </AreaText>
        <ImageContainer>
          <AreaImage url={img79} />
        </ImageContainer>
      </Context>
    </Container>
  );
};

export default Talent;
