import React from "react";
import Radio from '../../../assets/icons/radio-nav-bar.svg';
import RadioSelected from '../../../assets/icons/radio-selected-nav-bar.svg';
import RadioSelectedPurble from '../../../assets/icons/radio-selected-nav-bar-purble.svg';
import {
  Button,
  Space,
  AreaTextButton,
  TextButton,
  LineSlidOutRight,
  LineSlidInLeft,
  IconNavBar
} from '../styles';

interface ButtonNavBarProps {
  id: number;
  title: string;
  status: boolean;
  scrollingDown: boolean;
}

const ButtonNavBar = ({ id, title, status, scrollingDown } : ButtonNavBarProps) => {
  const selectedColor = id !== 7 ? '#2C1DFE' : '#9C1DFE';

  const scrollToSection = (id: number) => {
    const sectionId = `section-${id + 1}`;
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  return (
    <Button href={`#section-${id + 1}`} status={status} onClick={() => scrollToSection(id)}>
      {status && <Space />}
      <AreaTextButton>
        {status && <IconNavBar src={id !== 7 ? RadioSelected : RadioSelectedPurble} />}
        {!status && <IconNavBar src={Radio} />}
        <TextButton color={status ? selectedColor : 'rgba(0, 0, 0, 0.60)'}>{title}</TextButton>
      </AreaTextButton>
      {status && (
        scrollingDown
          ? <LineSlidInLeft color={selectedColor} status={status} />
          : <LineSlidOutRight color={selectedColor} status={status} />
      )}
    </Button>
  );
};

export default ButtonNavBar;
