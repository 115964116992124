import React from "react";
import SubNavigation from "../../components/SubNavigation";
import Banner from "./Banner";
import Services from "./Services";
import Cashback from "./Cashback";
import Midia from "./Midia";
import Personalties from "./Personalits";
import Talent from "./Talent";
import Solutions from "./Solutions";

const Personalities = () => (
  <>
    <SubNavigation
      headerPage={"NOSSOS SERVIÇOS"}
      isAboutUs={false}
      isTalent={false}
      pageSelected="personalities"
    />
    <Banner />
    <Services />
    <div style={{ backgroundColor: "#090633" }}>
      <Cashback />
      <Midia />
    </div>
    <Personalties />
    <Talent />
    <Solutions />
  </>
);

export default Personalities;
