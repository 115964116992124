import React from 'react';
import { useNavigate } from "react-router";
import TokenService from "../../services/tokenService";
import { Pages } from "../../types/enums";
import Oceano from "../../types/oceano";
import { ForgotPasswordRequest, ForgotPasswordResponse, NewPasswordRequest, NewPasswordResponse, Token, TokenGetRequest, TokenGetResponse, User } from "../../types/types";
import UserService from '../../services/userService';

export default class ServiceTokenLoginController {
    constructor(
        private navigate = useNavigate(),
        private oceano = new Oceano(),
        private tokenService = new TokenService(),
        private userService = new UserService(),
    ) {
    }

    public tokenGet(user: User, remember: boolean): Promise<boolean> {
        const self = this;

        return new Promise<boolean>((resolve) => {
            if (!self.oceano.isValidPassword(user.password) && self.oceano.isValidEmail(user.email))
                return resolve(false);
    
            let request: TokenGetRequest = new TokenGetRequest();
            request.item = user;
    
            self.oceano.showLoader();
            self.tokenService.TokenGet(request)
                .then((response: TokenGetResponse) => {
                    if (!response.success) {
                        console.log(response.message);
                        return;
                    }
    
                    let token: Token = response.item == null ? new Token() : response.item;
                    self.oceano.saveToken(token.tokenCode, remember);
                    self.oceano.goToPage(Pages.serviceToken, self.navigate);
                }).catch((error: TokenGetResponse) => {
                    console.log(error.message);
                    resolve(false);
                }).finally(() => {
                    self.oceano.hideLoader();
                    resolve(true);
                });
        });
    }

    public ForgotPassword(user: User): Promise<boolean> {
        const self = this;

        let request: ForgotPasswordRequest = new ForgotPasswordRequest();
        request.item = user;

        self.oceano.showLoader();
        return new Promise<boolean>((resolve, reject) => {
            self.userService.ForgotPassword(request)
                .then((response: ForgotPasswordResponse) => {
                    if (!response.success) {
                        console.log(response.message);
                        return;
                    }

                    resolve(response.success);
                }).catch((error: ForgotPasswordResponse) => {
                    console.log(error.message);
                    resolve(error.success);
                }).finally(() => {
                    self.oceano.hideLoader();
                });
        });
    }

    public NewPassword(user: User, confirmPassword: string, code: string): Promise<boolean> {
        const self = this;

        if (user.password != confirmPassword)
            alert("Senhas diferentes!");

        let request: NewPasswordRequest = new NewPasswordRequest();
        request.item = user;
        request.code = code;

        self.oceano.showLoader();

        return new Promise<boolean>((resolve, reject) => {
            self.userService.NewPassword(request)
                .then((response: NewPasswordResponse) => {
                    if (!response.success) {
                        console.log(response.message);
                        return;
                    }

                    resolve(true);
                }).catch((error: NewPasswordResponse) => {
                    console.log(error.message);
                    resolve(false);
                }).finally(() => {
                    self.oceano.hideLoader();
                });
        });
    }

    public validatePassword(password: string, confirmPassword: string): Array<boolean> {
        const self = this;
        return self.oceano.isValidFullPassword(password, confirmPassword);;
    }
}