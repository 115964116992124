import { useContext } from "react";
import { Pages, Stores } from "./enums";
import { LoadingContext } from "../context/loadingContext";

export default class Oceano {
  constructor(private contexLoading = useContext(LoadingContext)) { }

  public showLoader(): void {
    const self = this;
    self.contexLoading.loading(true);
  }

  public hideLoader(): void {
    const self = this;
    self.contexLoading.loading(false);
  }

  //#region Validations
  public isEmpityString(text: string): boolean {
    if (text === "" || text === undefined || text === null) return true;

    return false;
  }

  public isValidEmail(text: string): boolean {
    if (text.split("@").length == 2 && text.split("@")[1] != "") return true;

    return false;
  }

  public isValidPassword(text: string): boolean {
    let newText = text.replace(/[A-Z]/g, "");
    if (newText.length == text.length) return false;

    newText = text.replace(/[a-z]/g, "");
    if (newText.length == text.length) return false;

    newText = text.replace(/\d/g, "");
    if (newText.length == text.length) return false;

    newText = text.replace(/\W/g, "");
    if (newText.length == text.length) return false;

    return true;
  }

  public isValidFullPassword(
    password: string,
    passwordConfirm: string
  ): Array<boolean> {
    let validations: Array<boolean> = [
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    let newText = password.replace(/[A-Z]/g, "");
    if (newText.length != password.length) validations[0] = true;

    newText = password.replace(/[a-z]/g, "");
    if (newText.length != password.length) validations[1] = true;

    newText = password.replace(/\W/g, "");
    if (newText.length != password.length) validations[2] = true;

    newText = password.replace(/\d/g, "");
    if (newText.length != password.length) validations[3] = true;

    if (newText.length >= 8) validations[4] = true;

    if (password == passwordConfirm) validations[5] = true;

    return validations;
  }
  //#endregion Validations

  //#region Navigation
  public goToPage(page: Pages, navigate: any): void {
    const self = this;
    self.contexLoading.zapShowing(true);

    switch (page) {
      case Pages.home:
        navigate("/");
        break;
      case Pages.media:
        navigate("/media");
        break;
      case Pages.personalities:
        navigate("/personalities");
        break;
      case Pages.serviceTalent:
        navigate("/service-talent");
        break;
      case Pages.talent:
        navigate("/talent");
        break;
      case Pages.aboutUs:
        navigate("/aboutUs");
        break;
      case Pages.policy:
        navigate("/policy");
        break;
      case Pages.terms:
        navigate("/terms");
        break;
      case Pages.termsToken:
        navigate("/serviceToken/termsToken");
        break;
      case Pages.contactToken:
        navigate("/serviceToken/contactToken");
        break;
      case Pages.contact:
        navigate("/contact");
        break;
      case Pages.ourservices:
        navigate("/our-services");
        break;
      case Pages.serviceToken:
      case Pages.serviceTokenLogin:
        let token = self.tokenGet();
        if (self.isEmpityString(token)) {
          navigate("/serviceTokenLogin");
          break;
        }
        navigate("/serviceToken");
        break;
      case Pages.couses:
        self.contexLoading.zapShowing(false);
        navigate("/cursos");
        break;
      default:
        break;
    }
  }

  public goToStores(store: Stores): void {
    switch (store) {
      case Stores.apple:
        window.open(
          "https://apps.apple.com/br/app/oceano/id1629763551",
          "_blank"
        );
        break;
      case Stores.google:
        window.open(
          "https://play.google.com/store/apps/details?id=oceano.mobile1233",
          "_blank"
        );
        break;
      default:
        break;
    }
  }
  //#endregion Navigation

  //#region Token
  public saveToken(token: string, remember: boolean): void {
    localStorage.setItem("@token", token);
    localStorage.setItem("@remember", remember ? "true" : "false");
  }

  public tokenGet(): string {
    const self = this;

    let token = localStorage.getItem("@token") || "";
    if (localStorage.getItem("@remember") == "false") self.tokenDelete();

    return token;
  }

  private tokenDelete(): void {
    localStorage.clear();
  }
  //#endregion Token
}
