import React from "react";
import "./policy.scss";

const TermsTokenText = () => {
  return (
    <div className="policyText">
      <div className="informations">
        <p className="content">
          Olá! Tudo bem? Bem-vindo(a) regulamento (“<u>Regulamento</u>”) dos
          tokens utilidades da Oceano (“<u>Tokens</u>”).
        </p>
        <p className="content">
          Ficamos felizes por você estar aqui e esperamos que você aproveite
          tudo o que temos para oferecer.
        </p>
        <p className="content">
          Por favor leia este Regulamento com atenção, pois é um contrato
          vinculativo entre você e a Oceano.
        </p>
        <p className="content">
          A seguir apresentamos a você os termos e condições de uso com as
          principais regras a serem observadas por todos que adquirem os Tokens.
        </p>
        <p className="content">
          Por este Regulamento e por estarem de acordo com os termos que se
          seguem, tendo por Partes, de um lado,{" "}
          <strong>PLATAFORMA OCEANO LTDA</strong>, inscrita no CNPJ sob o nº
          44.576.640/0001-10, com sede na Rod. José Carlos Daux, nº 4150, Salas
          01 e 02, Bairro do Saco Grande, Florianópolis/SC, CEP 88.032-005,
          chamada de “<u>Oceano</u>”; e de outro lado, você, o (“<u>Usuário</u>
          ”), qualquer pessoa física ou jurídica, que contrate os serviços do
          Programa Cashback Tokenizado (“<u>Programa</u>” ou “
          <u>
            Programa <i>Cashback</i> Tokenizado
          </u>
          ”), desenvolvido e provido pela Oceano.
        </p>
        <p className="content">
          Caso queira nos dar algum <i>feedback</i> sobre os Tokens, tenha
          dúvidas ou precise tratar de qualquer assunto relacionado a este
          Regulamento, entre em contato conosco de segunda à sexta-feira, das
          09h às 18h, pelo nosso WhatsApp: (11) 4580-2353 ou pelo nosso e-mail:{" "}
          <a
            href="dados@plataformaoceano.com.br"
            target="_blank"
            className="link"
          >
            dados@plataformaoceano.com.br
          </a>
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">1. ACEITAÇÃO</p>
        <p className="content">
          1.1.{" "}
          <b>
            O Usuário declara a sua ciência de que a aceitou, a utilizar e ser
            cadastrado para o recebimento do(s) Token(s), e está de acordo
            Regulamento e todas as demais Políticas, Termos de Uso e princípios
            da Oceano.
          </b>
        </p>
        <p className="content">
          1.2. A aceitação deste Regulamento é indispensável ao recebimento
          do(s) Token(s). Caso o Usuário não concorde com quaisquer dos termos e
          condições abaixo estabelecidos, estes não devem receber o(s) Token(s).
        </p>
        <p className="content">
          1.3. Se você, na qualidade de Usuário, não concordar com Regulamento,
          você não está autorizado a acessar ou utilizar o(s) Token(s) oferecido
          pela Oceano. A utilização do(s) Token(s) está expressamente
          condicionada ao consentimento do Usuário às regras deste Regulamento.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">2. DISPOSIÇÕES GERAIS</p>
        <p className="content">
          2.1. O presente Regulamento tem por intuito informar, regular e
          formalizar a utilização do(s) Token(s) para o Programa Cashback
          Tokenizado e pelo Usuário, no que se refere à sua funcionalidade, a
          sua origem, ao seu acesso e utilização dos serviços e recursos
          disponíveis. Este Regulamento está disponível para acesso, a qualquer
          momento, pelo Usuário, por meio do endereço eletrônico{" "}
          <a
            href="https://www.plataformaoceano.com.br/serviceToken"
            target="_blank"
            className="link"
          >
            https://www.plataformaoceano.com.br/serviceToken
          </a>
        </p>
        <p className="content">
          2.2. As definições dos termos com iniciais em maiúscula empregados
          neste Regulamento constam nos documentos oficiais, disponíveis para
          acesso, a qualquer momento, pelo Usuário, por meio do endereço
          eletrônico{" "}
          <a
            href="https://www.plataformaoceano.com.br/serviceToken"
            target="_blank"
            className="link"
          >
            https://www.plataformaoceano.com.br/serviceToken
          </a>
        </p>
        <p className="content">
          2.3. Ao acessar o(s) Token(s), o Usuário manifesta sua plena, total,
          irrevogável e irretratável concordância aos direitos e obrigações
          dispostos neste Regulamento, declarando ter lido, compreendido e dado
          seu aceite em todas as condições aqui dispostas.
        </p>
        <p className="content">
          2.4. A Oceano reserva-se ao direito de, a qualquer tempo e
          circunstância, sem necessidade de comunicação prévia ao Usuário,
          modificar e/ou atualizar, parcialmente ou em sua totalidade, o
          presente Regulamento. Em referida hipótese, a versão atualizada será
          disponibilizada no endereço eletrônico da Oceano{" "}
          <a
            href="https://www.plataformaoceano.com.br/serviceToken"
            target="_blank"
            className="link"
          >
            https://www.plataformaoceano.com.br/serviceToken
          </a>
          , e entrará em vigência na data de sua publicação – se outra data para
          início de vigência não for definida no próprio Regulamento
          alterado/atualizado.
        </p>
        <p className="content">
          2.4.1.{" "}
          <b>
            O USUÁRIO QUE NÃO CONCORDAR COM FUTURAS ALTERAÇÕES DESTE REGULAMENTO
            DEVERÁ COMUNICAR À OCEANO, ATRAVÉS DO ENDEREÇO DE E-MAIL
            dados@plataformaoceano.com.br, SUA OBJEÇÃO ANTES DE UTILIZAR O(S)
            TOKEN(S) APÓS A ALTERAÇÃO. NESSE CASO, A OCEANO TOMARÁ AS MEDIDAS
            CABÍVEIS PARA EXCLUIR AS CONTAS DE ACESSO DO USUÁRIO, CONFORME
            PREVISTO NA CLÁUSULA 4 (QUATRO) DESTE REGULAMENTO.
          </b>
        </p>
        <p className="content">
          2.4.1.1.{" "}
          <b>
            NO CASO DE O USUÁRIO ACESSAR SUAS CONTAS DE ACESSO OU UTILIZAR O(S)
            TOKEN(S) APÓS QUALQUER ALTERAÇÃO DESTE REGULAMENTO, ELE RECONHECE E
            CONCORDA QUE ESSE ATO REPRESENTARÁ EXPRESSAMENTE SUA CONCORD NCIA
            COM A NOVA VERSÃO DESTE REGULAMENTO.
          </b>
        </p>
        <p className="content">
          2.5.{" "}
          <b>
            A OCEANO NÃO SE RESPONSABILIZA POR NENHUMA COMPRA, TRANSAÇÃO OU
            QUALQUER OUTRA FORMA DE RELAÇÃO COMERCIAL E/OU OBRIGAÇÃO, INCLUSIVE
            DE NATUREZA JURÍDICA OU TRIBUTÁRIA, ASSIM COMO SEUS EVENTUAIS
            PREJUÍZOS RESULTANTES, QUE VENHAM A SER ESTABELECIDAS ENTRE O
            USUÁRIO E TERCEIROS – INCLUINDO PARCEIROS E DEMAIS USUÁRIOS DO
            PROGRAMA CASHBACK TOKENIZADO –, AINDA QUE PROMOVIDAS POR INTERMÉDIO
            DOS RECURSOS E CONTEÚDOS DISPONIBILIZADOS NO PROGRAMA.
          </b>
        </p>
        <p className="content">
          2.6. O Usuário declara a sua expressa ciência e concordância de que a
          Oceano não se responsabiliza – de nenhuma forma – por nenhuma
          informação mantida e disponibilizada por terceiros e seus parceiros
          operacionais, inclusive aquelas veiculadas nos anúncios, ofertas e
          promoções disponíveis no Programa, bem como pelo funcionamento e
          conteúdo de seus websites e sistemas de comércio eletrônico. Na
          hipótese de divergência entre a informação publicada no Programa e o
          informado por terceiros em seus canais de comunicação e sistemas
          comerciais, prevalecerá sempre o disposto nestes últimos.
        </p>
        <p className="content">
          2.7.{" "}
          <b>
            Os termos aqui descritos não anulam nem revogam outros termos
            disponíveis ao Usuário em qualquer aplicação da Oceano e são
            complementares às condições específicas de eventual outra
            contratação entre o Usuário e a Oceano.
          </b>
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">3. DOS TOKENS</p>
        <p className="content">
          3.1. Programa Cash Back Tokenizado. Como forma de fidelizar os
          Usuários, a Oceano desenvolveu o Programa Cash Back Tokenizado,
          regulado pelos Termos de Uso, acessíveis através do endereço
          eletrônico{" "}
          <a
            href="https://cashback.plataformaoceano.com.br/login"
            target="_blank"
            className="link"
          >
            https://cashback.plataformaoceano.com.br/login
          </a>
          . Este Programa permite que os Usuários acumulem pontos e os troquem
          por produtos e serviços, fornecidos pela Oceano ou por terceiros,
          acessíveis através do endereço eletrônico{" "}
          <a
            href="https://cashback.plataformaoceano.com.br/login"
            target="_blank"
            className="link"
          >
            https://cashback.plataformaoceano.com.br/login
          </a>
          .
        </p>
        <p className="content">
          3.2. <u>Tokens para Maior Segurança</u>. Para assegurar o Programa
          Cash Back Tokenizado com a máxima segurança, a Oceano desenvolveu os
          Tokens para representar os pontos que serão trocados por produtos ou
          serviços dentro da plataforma da Oceano. Esses Tokens são ativos
          digitais que utilizam criptografia avançada, garantindo autenticidade
          e eliminando possibilidades de falsificação ou gastos duplicados dos
          pontos no Programa.
        </p>
        <p className="content">
          3.3. <u>Natureza dos Tokens</u>. Os Tokens são ativos digitais de
          natureza utilitária, fornecendo acesso exclusivo aos benefícios do
          Programa Cash Back Tokenizado da Oceano para os Usuários. Não possuem
          valor mobiliário ou monetário, sendo impossível a conversão total ou
          parcial em dinheiro.
        </p>
        <p className="content">
          3.3.1. <u>Autenticidade Registrada na Blockchain</u>. Cada Token
          emitido possui um código único (“<u>hash</u>”) registrado em uma
          infraestrutura baseada em blockchain, garantindo sua autenticidade e
          representado por uma imagem 3D de um(a) agenciado(a) da Oceano (“
          <u>Artista</u>”).
        </p>
        <p className="content">
          3.2. <u>Acesso à Wallet e Conta de Acesso</u>. O(s) Token(s)
          adquirido(s) pelos Usuários, exclusivamente selecionados pela Oceano,
          são acessados na plataforma a NFtrend, acessíveis através do endereço
          eletrônico{" "}
          <a
            href="https://carteira.nftrend.io/"
            target="_blank"
            className="link"
          >
            https://carteira.nftrend.io/
          </a>
          . O Usuário utiliza sua conta de acesso (“<u>Conta de Acesso</u>”),
          com login e senha fornecidos pela Oceano, para entrar na carteira
          digital (“<u>Wallet</u>”), onde encontrará seus Tokens.
        </p>
        <p className="content">
          3.2.1. O Usuário utiliza duas contas de acesso (“
          <u>Contas de Acesso</u>”), uma na Plataforma Oceano (“
          <u>Conta Oceano</u>”) e uma na NFtrend (“<u>Conta NFtrend</u>”), com
          login e senha fornecidos pela Oceano, para entrar na Wallet e acessar
          o(s) Token(s).
        </p>
        <p className="content">
          3.2.2. As Contas de Acesso e as senhas criada para acesso do Usuário à
          Wallet são únicas, pessoais e intransferíveis.
        </p>
        <p className="content">
          3.2.2.1. No primeiro acesso do Usuário nas Contas de Acesso, é sua
          obrigação alterar as respectivas senhas para uma nova e distinta da
          que foi fornecida pela Oceano.
        </p>
        <p className="content">
          3.2.2.1.1. Para alterar a senha da Conta Oceano, o Usuário deverá
          clicar na opção “esqueci a senha” na Plataforma Oceano. A Oceano
          enviará para o e-mail do Usuário cadastrado na Oceano e ele poderá
          alterar a senha.
        </p>
        <p className="content">
          3.2.2.1.2. Para alterar a senha da Conta NFtrend, o Usuário deverá
          efetuar o login na Conta de Acesso e seguir as orientações da
          plataforma NFtrend. 3.2.2.2. O Usuário é responsável pela guarda e
          sigilo das suas próprias senhas. Caso venha a esquecer sua(s)
          senha(s), o Usuário poderá criar uma nova na plataforma
          correspondente, utilizando a opção "esqueci a senha". Uma senha antiga
          não poderá ser recuperada por solicitação do Usuário.
        </p>
        <p className="content">
          <b>
            3.2.2.2.1. A OCEANO NÃO SERÁ RESPONSÁVEL POR ACESSOS ÀS CONTAS DE
            ACESSO REALIZADOS POR TERCEIROS COM AS INFORMAÇÕES OU SENHA DO
            USUÁRIO, BEM COMO POR QUALQUER DANO DIRETO OU INDIRETO, RESULTADO DO
            MAU USO OU DA INABILIDADE DO USO DO PELO USUÁRIO OU POR QUAISQUER
            TERCEIROS. TAL RESPONSABILIDADE É APLICÁVEL APENAS AO USUÁRIO.
          </b>
        </p>
        <p className="content">
          3.2.2.3. <u>Medidas em Caso de Perda ou Uso Indevido</u>. A Oceano
          deverá ser prontamente contatada em caso de perda, extravio ou
          suspeita de utilização indevida das Contas de Acesso, para que as
          medidas adequadas sejam adotadas. Este contato deverá ocorrer através
          do e-mail: dados@plataformaoceano.com.br
        </p>
        <p className="content">
          3.3. <u>Tokens Vinculados a Contratos</u>. Os Tokens emitidos pela
          Oceano estão vinculados a contratos de agenciamento celebrados entre a
          Oceano e seus Talentos que possuem vigência de 02 (dois) anos (“
          <u>Vigência</u>”). Desses contratos, a Oceano seleciona alguns (“
          <u>Contratos Selecionados</u>”) para a emissão de Tokens destinados
          aos Usuários, os quais serão transformados em Pontos e utilizados no
          Programa Cashback Tokenizado.
        </p>
        <p className="content">
          3.3.1. <u>Comissão e Emissão de Tokens</u>. De cada Contrato
          Selecionado, a Oceano recebe 35% (trinta e cinco por cento) de
          comissão. Destes, ela destina 20% (vinte por cento) para emitir 2.000
          (dois mil) Tokens para cada Talento.
        </p>
        <p className="content">
          3.3.2. Cada Token emitido pela Oceano corresponde a 0,01% (zero
          vírgula zero um porcento) do valor de cada contrato intermediado pela
          Oceano e assinado pelo Talento com terceiros (“<u>Trabalho</u>”), ao
          qual o Token esteja vinculado. Esse percentual será expresso
          numericamente como $AL (“<u>Sal</u>”) na Programa Cashback Tokenizado
          na Plataforma Oceano, sem equivalência em valor monetário. Para o
          Usuário, todo quantitativo em Sal será convertido em Pontos para serem
          utilizados do Programa.
        </p>
        <p className="content">
          3.3.2.1. A Oceano garante 1.500 (mil e quinhentos) Sal para cada
          Token, caso, ao término da Vigência, o Talento vinculado ao respectivo
          Token não tenha concretizado nenhum Trabalho (“<u>Garantia</u>”). Essa
          eventual Garantia só estará disponível para o Usuário em 60 (sessenta)
          dias após o término da Vigência.
        </p>
        <p className="content">
          3.3.2.1.1. A Garantia obedece ao prazo previsto na cláusula 3.3.1.1.
          abaixo para realizar o Resgate.
        </p>
        <p className="content">
          3.3.2.2. No dia 15 (quinze) do mês subsequente à conclusão Trabalho do
          Talento vinculado ao Token, o Usuário poderá entrar na Plataforma
          Oceano, especificamente na área designada “Oceano Cashback”, onde
          poderá visualizar a quantidade de Pontos disponíveis para resgate.
        </p>
        <p className="content">
          3.3.3. <u>Resgate dos Tokens – Troca dos Pontos</u>. Apenas a partir
          de 01 de outubro de 2024, o Usuário terá a prerrogativa de utilizar os
          Pontos no Programa para trocar por benefícios exclusivos na Plataforma
          Oceano (“<u>Resgate</u>”). Estes benefícios incluem, mas não se
          limitam a, ingressos para shows, produtos de diversas categorias e
          outros serviços de entretenimento e beleza, os quais podem ser
          oferecidos pela Oceano ou por terceiros.
        </p>
        <p className="content">
          3.3.3.1. <u>Contratos Selecionados vencidos</u>. Na eventualidade de
          não renovação do(s) Contrato(s) Selecionado(s) vinculado(s) ao(s)
          respectivo(s) Token(s) com o Talento e a Oceano, o Usuário terá um
          prazo de 02 (dois) meses para realizar o Resgate.
        </p>
        <p className="content">
          3.3.3.2. <u>Contratos Selecionados renovados</u>. No caso de renovação
          do(s) Contrato(s) Selecionado(s) associado(s) ao(s) respectivo(s)
          Token(s), o Usuário manterá sua elegibilidade para participar do
          Programa durante o prazo de vigência renovado.
        </p>
        <p className="content">
          3.3.4. <u>Restrições de Uso</u>. A venda, transferência e cessão dos
          Tokens a terceiros são proibidas para os Usuários e/ou Terceiros.
        </p>
        <p className="content">
          3.4. A utilização do(s) Token(s) só será(ão) permitida(s) para
          Usuários pessoas físicas maiores de 18 (dezoito) anos e plenamente
          capaz no ambiente civil. Caso o Usuário não esteja de acordo com o
          exigido, não deverá prosseguir com o acesso ao(s) Token(s), sob pena
          de ser caracterizada má-fé.
        </p>
        <p className="content">
          3.4.1. Todos aqueles que não possuírem plena capacidade civil -
          menores de 18 anos, pródigos, ébrios habituais ou viciados em tóxicos
          e pessoas que não puderem exprimir sua vontade, por motivo transitório
          ou permanente - não poderão participar desse Programa.
        </p>
        <p className="content">
          3.4.2. Caso a Oceano tome conhecimento de que um Usuário é menor de
          idade ou forneceu informações falsas sobre a idade, a Conta de Acesso
          será sujeita a cancelamento imediato, sem qualquer notificação ao
          Usuário. A Oceano reserva-se do direito de adotar medidas necessárias
          para conformidade com as leis aplicáveis e proteção dos menores,
          conforme previsto dos Termos de Uso da Oceano.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">
          4. CANCELAMENTO E SUSPENSÃO DA CONTA DE ACESSO
        </p>
        <p className="content">
          4.1. O Usuário tem o direito de efetuar o cancelamento de sua Conta de
          Acesso a qualquer momento, com renúncia imediata e completa aos Tokens
          e demais benefícios vinculados ao Programa. Neste caso, o Usuário
          deverá encaminhar sua solicitação formal de cancelamento e
          desvinculação do serviço, pelos canais de suporte da Plataforma Oceano
          e aguardar as orientações.
        </p>
        <p className="content">
          4.1.1. O Usuário está ciente e concorda que o cancelamento da Conta de
          Acesso implica no cancelamento da utilização dos Tokens no Programa.
        </p>
        <p className="content">
          4.1.2. A solicitação de cancelamento é irreversível e importa na
          imediata exclusão de todas as informações disponibilizadas na conta do
          Usuário, conforme o caso, incluindo histórico de navegação e dados
          pessoais, mas não se limitando a esses, exceto as informações legais
          necessárias que a Oceano seja obrigada a guardar por força de Lei ou
          por força dos contratos assinados junto aos seus parceiros de negócio.
        </p>
        <p className="content">
          4.1.3. A Oceano não se responsabiliza por qualquer dano ao Usuário
          oriundo do cancelamento da Conta de Acesso.
        </p>
        <p className="content">
          4.1.4. Após o Cancelamento das Contas de Acesso, o Usuário está ciente
          e concorda que todos os Tokens presentes em sua Wallet serão
          transferidos imediatamente para à Oceano, que terá total liberdade e
          autorização para conceder o(s) Token(s) a outro(s) Usuário(s) sem
          restrições.
        </p>
        <p className="content">
          4.2. A Oceano também se reserva o direito de suspender ou cancelar, a
          qualquer momento, a Conta de Acesso do Usuário, em caso de suspeita de
          fraude, falsidade no conteúdo das informações, obtenção de benefício
          ou vantagem de forma ilícita, má utilização ou uso inadequado dos
          serviços ou para fins ilícitos, bem como pelo não cumprimento de
          quaisquer condições previstas neste Regulamento ou na legislação
          aplicável.
        </p>
        <p className="content">
          4.3. Nesses casos, não será devida qualquer indenização ao Usuário, e
          a Oceano poderá promover a competente ação de regresso (ação judicial
          com o fim de pedir a restituição dos custos assumidos pela Oceano), se
          necessário, bem como tomar quaisquer outras medidas necessárias para
          perseguir e resguardar seus interesses.
        </p>
        <p className="content">
          4.4. A Oceano reserva-se o direito de, a seu critério, a qualquer
          tempo e sem qualquer comunicação prévia ao Usuário, descontinuar de
          forma definitiva ou temporária os serviços disponibilizados por meio
          do Programa da Oceano. Nesses casos, nenhuma indenização será devida
          ao Usuário.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">5. PROPRIEDADE INTELECTUAL</p>
        <p className="content">
          5.1. Todos os direitos de propriedade intelectual relativos ao(s)
          Token(s) da Oceano, bem como todas as suas funcionalidades, são de
          propriedade exclusiva da Oceano, inclusive no que diz respeito aos
          seus textos, imagens, layouts, software, códigos, bases de dados,
          gráficos, artigos, fotografias e demais conteúdos análogos produzidos
          direta ou indiretamente pela Oceano (“
          <u>Conteúdo da Oceano</u>”).
        </p>
        <p className="content">
          5.1.1. Inclui-se nesse dispositivo o uso da marca “Oceano”, ou
          qualquer outra marca a ela relacionada, nome empresarial ou nome de
          domínio, além das telas na plataforma, assim como os programas, banco
          de dados, redes, arquivos são de propriedade da Oceano e estão
          protegidos pelas leis e tratados internacionais de direito autoral e
          propriedade industrial.
        </p>
        <p className="content">
          5.1.2. O Conteúdo da Oceano é protegido pelas leis de direitos
          autorais e de propriedade industrial. É proibido usar, copiar,
          reproduzir, modificar, traduzir, publicar, transmitir, distribuir,
          executar, fazer o upload, exibir, licenciar, vender ou explorar e
          fazer engenharia reversa do Conteúdo da Oceano, para qualquer
          finalidade, sem o consentimento prévio e expresso da Oceano. Qualquer
          uso não autorizado do Conteúdo da Oceano será considerado como
          violação dos direitos autorais e de propriedade industrial da Oceano.
        </p>
        <p className="content">
          5.2. Todos os feedbacks, opiniões, sugestões de melhoria ou outras
          ideias fornecidas pelo Usuário à Oceano, não conferirão qualquer
          titularidade sobre os direitos de propriedade intelectual ao Usuário,
          mesmo que as sugestões sejam eventualmente implementadas. Assim, todas
          as melhorias, opiniões, sugestões, ideias, comentário, feedbacks são
          desde já cedidos pelo Usuário à Oceano, de forma gratuita,
          irrevogável, irretratável, total, perpétua, sem que seja devido ao
          Usuário qualquer remuneração, reconhecimento, pagamento ou
          indenização.
        </p>
        <p className="content">
          5.3. A Oceano reserva-se o direito de, a seu critério e a qualquer
          tempo, alterar ou remover funcionalidades da plataforma da Oceano que
          não estejam alinhadas com seus interesses, bem como adicionar novas
          funcionalidades que tragam benefícios à utilização da plataforma da
          Oceano pelo Usuário, sem qualquer comunicação prévia e sem que seja
          devido ao Usuário qualquer indenização.
        </p>
        <p className="content">
          5.4. A Oceano poderá, a seu exclusivo critério, durante a vigência
          deste Regulamento, atualizar a sua plataforma com o objetivo de
          preservar ou aperfeiçoar suas características funcionais.
        </p>
        <p className="content">
          5.4.1. Nesse caso, suspensões temporárias nos serviços poderão
          acontecer, sem que seja devida qualquer indenização ao Usuário. Todo e
          qualquer desenvolvimento, arranjo, melhoria ou alteração da plataforma
          ou de suas funcionalidades, realizados pela Oceano, ainda que por
          sugestão ou solicitação do Usuário, serão considerados de titularidade
          da Oceano de forma que esta poderá empregá-los livremente e
          adaptá-los, aperfeiçoá-los, transformá-los, distribuí-los,
          comercializá-los, levá-los a registro, cedê-los e licenciá-los, a
          qualquer título e a seu exclusivo critério, sem limitação.
        </p>
        <p className="content">
          5.5. É vedado ao Usuário incluir na plataforma da Oceano dados que
          possam modificar o seu conteúdo ou sua aparência.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">6. RESPONSABILIDADES DA OCEANO</p>
        <p className="content">
          6.1. Tendo em vista as características inerentes ao ambiente da
          internet, o Usuário reconhece que a Oceano não se responsabiliza pelas
          falhas no(s) Token(s) dentro da plataforma utilizada decorrentes de
          circunstâncias alheias à sua vontade e controle, sejam ou não
          ocasionadas por caso fortuito ou força maior, como por exemplo,
          intervenções de hackers e softwares maliciosos; falhas técnicas de
          qualquer tipo, incluindo, falhas no acesso ou na navegação na
          plataforma decorrentes de falhas na internet em geral, quedas de
          energia, mau funcionamento eletrônico e/ou físico de qualquer rede,
          interrupções ou suspensões de conexão e falhas de software e/ou
          hardware do Usuário; paralisações programadas para manutenção,
          atualização e ajustes de configuração da plataforma, qualquer falha
          humana de qualquer outro tipo, que possa ocorrer durante o
          processamento das informações. O Usuário exime a Oceano de qualquer
          responsabilidade proveniente de tais fatos e/ou atos.
        </p>
        <p className="content">
          6.2. A Oceano oferecerá suporte ao Usuário com relação aos Tokens, o
          que implica no esclarecimento de dúvidas com relação ao seu uso por
          meio da Central de Atendimento, de segunda à sexta-feira, das 09h às
          18h e através dos canais disponibilizados pela Oceano. A Oceano deverá
          minimamente disponibilizar atendimento via e-mail:
          dados@plataformaoceano.com.br
        </p>
        <p className="content">
          6.3. A Oceano reserva-se o direito de modificar, suspender ou
          descontinuar temporariamente as funcionalidades disponibilizadas na
          plataforma para realizar a manutenção, atualização e ajustes de
          configuração.
        </p>
        <p className="content">
          6.4. A Oceano não se responsabiliza pelo recebimento de e-mails falsos
          e mensagens, enviados por terceiros sem qualquer relação direta ou
          indireta com a Oceano e/ou empresas do grupo.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">7. RESPONSABILIDADE DO USUÁRIO</p>
        <p className="content">
          7.1. O Usuário fica ciente e concorda que, na utilização do(s)
          Token(s), é terminantemente proibido:
        </p>
        <p className="content">
          i. distribuir, modificar, vender, alugar ou de qualquer forma explorar
          economicamente o(s) Token(s), os dados e informações a ele
          relacionados, bem como utilizá-los para finalidade que não seja para
          uso próprio;
        </p>
        <p className="content">
          ii. disseminar ou instalar vírus ou qualquer outro código, arquivo ou
          software com o propósito de interromper, destruir, acessar
          indevidamente, limitar ou interferir no funcionamento ou segurança do
          serviço oferecido pela Oceano, bem como nas informações, dados e
          equipamentos da Oceano, de seus usuários ou de terceiros, ou, ainda,
          para qualquer outra finalidade ilícita; e
        </p>
        <p className="content">
          iii. praticar qualquer ato contrário à legislação em vigor.
        </p>
        <p className="content">
          7.2. Usuário é exclusivamente responsável pelo uso do(s) Token(s)
          plataforma e deverá respeitar as regras deste Regulamento, bem como a
          legislação aplicável ao uso da plataforma e todos os demais termos e
          políticas da Oceano e da Plataforma NFtrend{" "}
          <a
            href="https://nftrend.com.br/termos-e-condicoes/"
            target="_blank"
            className="link"
          >
            https://nftrend.com.br/termos-e-condicoes/
          </a>
          .
        </p>
        <p className="content">7.3. São responsabilidades do Usuário:</p>
        <p className="content">
          i. Utilizar a plataforma de forma adequada e diligente, em
          conformidade com o presente Regulamento e os termos de uso da NFtrende
          acessíveis através do endereço eletrônico{" "}
          <a
            href="https://nftrend.com.br/termos-e-condicoes/"
            target="_blank"
            className="link"
          >
            https://nftrend.com.br/termos-e-condicoes/
          </a>
          , com a lei, a moral, os bons costumes e a ordem pública;
        </p>
        <p className="content">
          ii. Manter seguro o ambiente de seus dispositivos de acesso à
          plataforma, valendo-se de ferramentas específicas para tanto, tais
          como antivírus, firewall, entre outras, de modo a contribuir para a
          prevenção de riscos eletrônicos;
        </p>
        <p className="content">
          iii. Utilizar navegadores e sistemas operacionais atualizados e
          eficientes para a plena utilização da plataforma; e
        </p>
        <p className="content">
          iv. Equipar-se e responsabilizar-se pelos dispositivos de hardware
          necessários para o acesso à plataforma, bem como pelo acesso desses à
          internet.
        </p>
        <p className="content">
          7.4. Qualquer dano causado pelo Usuário à Oceano ou a terceiros em
          virtude do não cumprimento das obrigações aqui dispostas ou da não
          veracidade das garantias aqui declaradas serão reparados
          exclusivamente pelo Usuário causador do dano, não havendo que se falar
          em subsidiariedade da obrigação, tampouco em solidariedade da Oceano.
        </p>
        <p className="content">
          7.5. No caso de infração às regras acima, o Usuário poderá ser
          suspenso ou banido do serviço da Oceano, sem prejuízo da
          responsabilidade legal quando aplicável, respondendo inteiramente por
          sua conduta.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">8. SERVIÇO DE ATENDIMENTO AO USUÁRIO</p>
        <p className="content">
          8.1. O Usuário poderá entrar em contato direto com a Oceano através do
          nosso e-mail: dados@plataformaoceano.com.br
        </p>
        <p className="content">
          8.2. Todas as comunicações da Oceano com o Usuário serão conduzidas
          por meio do endereço de e-mail, todos fornecidos pelo Usuário durante
          o cadastro na Plataforma Oceano. Adicionalmente, a Oceano poderá
          utilizar os canais de comunicação disponibilizados na própria
          Plataforma.
        </p>
        <p className="content">
          8.2.1. O Usuário obriga-se a informar imediatamente quaisquer mudanças
          de seu endereço de e-mail, a fim de que possa receber regularmente
          comunicações importantes enviadas pela Oceano. A Oceano não poderá ser
          responsabilizada pela falta ou erro em suas comunicações nos casos em
          que o e-mail do Usuário esteja desatualizado.
        </p>
        <p className="content">
          8.2.2. É dever do usuário deixar os sistemas AntiSpam de seu e-mail
          configurados de modo que não interfiram no recebimento dos comunicados
          enviados pela Oceano. A responsabilidade pelo recebimento e
          visualização dos comunicados é exclusiva do Usuário.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">9. DOS LINKS EXTERNOS</p>
        <p className="content">
          9.1. A plataforma pode conter links para portais e aplicativos de
          terceiros que não pertencem e não são controlados pela Oceano. Apesar
          das verificações prévias e regulares realizadas pela Oceano, esta não
          endossa ou garante ou possui qualquer ligação com os proprietários
          desses portais ou aplicativos detidos por terceiros, não sendo
          responsável pelo seu conteúdo, precisão, políticas, práticas ou
          opiniões expressas em qualquer desses portais e aplicativos de
          terceiros com os quais o Usuário interaja através do Plataforma. A
          Oceano recomenda que o Usuário leia os Termos de Uso e as Políticas de
          Privacidade de cada site, plataforma e aplicativo de terceiros ou
          serviço que o Usuário vier a visitar ou utilizar, pois a Oceano se
          isenta de qualquer responsabilidade sobre o conteúdo encontrado nestas
          plataformas e serviços.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">10. ALTERAÇÃO DESTE REGULAMENTO</p>
        <p className="content">
          10.1. A Oceano está sempre fazendo atualizações para melhorar a
          prestação dos Serviços. Por esse motivo, este Regulamento pode ser
          alterado, a qualquer tempo, a fim de refletir os ajustes realizados.
        </p>
        <p className="content">
          10.2. Sempre que ocorrer qualquer modificação neste Regulamento, a
          Oceano e notificará na Plataforma Oceano ao Usuário, que os termos
          foram modificados. Devendo o Usuário concordar com eles para continuar
          acessando o(s) Token(s).
        </p>
        <p className="content">
          10.2.1. O Usuário concorda que fornecer um aviso de alteração na
          plataforma é uma forma adequada de comunicação e que após verificar o
          aviso irá revisar os termos para ter certeza de que está ciente com as
          alterações realizadas e de acordo.
        </p>
        <p className="content">
          10.2.2. Caso o Usuário não concorde com os novos termos do
          Regulamento, ele poderá rejeitá-los, mas isso significa que o uso
          do(s) Token(s) não será mais permitido. Se de qualquer maneira o
          Usuário utilizar o(s) Token(s), mesmo após a alteração deste
          Regulamento, isso significa que este concorda com todas as
          modificações.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">11. DISPOSIÇÕES FINAIS</p>
        <p className="content">
          11.1. A Oceano poderá, a qualquer momento, ceder quaisquer de seus
          direitos e obrigações previstos neste Regulamento a qualquer pessoa,
          física ou jurídica, mediante simples notificação prévia ao Usuário,
          ficando desde já ressalvado que a cessionária continuará a cumprir com
          todas as obrigações assumidas pela Oceano, conforme o caso.
        </p>
        <p className="content">
          11.2. Este Regulamento não cria qualquer outra modalidade de vínculo
          entre o Usuário e a Oceano, inclusive, sem limitação, sociedade,
          mandato, parceria, associação, joint-venture, consórcio, grupo
          econômico, vínculo empregatício ou similar. A Oceano permanecerá uma
          entidade independente e autônoma.
        </p>
        <p className="content">
          11.3. Ocasionalmente, pode haver informações no(s) Token(s) que
          contenham erros. A Oceano se reserva no direito de corrigir quaisquer
          erros.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">12. LEI APLICÁVEL E FORO</p>
        <p className="content">
          12.1. Esse Regulamento é regido pelas leis da República Federativa do
          Brasil.
        </p>
        <p className="content">
          12.2. Todas as controvérsias deste Regulamento serão solucionadas pelo
          foro da Comarca de São Paulo/SP – Brasil, com exclusão de qualquer
          outro, por mais privilegiado que seja ou venha a ser.
        </p>
      </div>
      <div className="informations items">
        <p className="content emphasis">DADOS PARA CONTATO</p>
        <p className="content">
          PLATAFORMA OCEANO LTDA – CNPJ sob o nº 44.576.640/0001-10.
          <br />
          Endereço para correspondência na Avenida Ibirapuera, nº 2907, Conj.
          1211,
          <br />
          Moema/SP, CEP: 04029-902
          <br />
          <br />
          Telefone/WhatsApp: (11) 4580-2353
          <br />
          E-mail: dados@plataformaoceano.com.br
          <br />
          Horários de Atendimento: Segunda à Sexta: Das 09h às 18h.
        </p>
      </div>
    </div>
  );
};

export default TermsTokenText;
