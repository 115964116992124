import styled from "styled-components";
import { devices } from "../../../utils/constants";

interface ImageProps {
  url: string;
}

export const Content = styled.div`
  display: flex;
  height: 22rem;
  /* margin: 0px 120px; */
  margin: 0 8%;

  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: #fdfdfd;
  flex-direction: column;

  @media only screen and (max-width: 1362px) {
    margin: 0 6%;
  }

  @media only screen and (${devices.xl}) {
    margin: 0 5%;
  }

  @media only screen and (${devices.lg}) {
    margin: 0 4%;
  }

  @media only screen and (${devices.sm}) {
    margin: 0 3%;
  }

  @media only screen and (min-width: 1194px) {
    .alice-carousel {
      width: 100%;
      .alice-carousel__stage {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media only screen and (max-width: 860px) {
    height: 14rem;
  }

  @media only screen and (max-width: 500px) {
    height: 12rem;
  }
`;

export const Image = styled.div<ImageProps>`
  height: 21.875rem;
  width: 16.75rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.25rem;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: lightgray;
  position: relative;

  #imageMatch1 {
    display: none;
  }
  #imageMatch2 {
    display: none;
  }
  #imageMatch3 {
    display: none;
  }
  #imageMatch4 {
    display: flex;
  }

  @media only screen and (max-width: 1277px) {
    #imageMatch3 {
      display: flex;
    }
    #imageMatch4 {
      display: none;
    }
  }

  @media only screen and (max-width: 860px) {
    width: 12.5rem;
    height: 12.5rem;
  }

  @media only screen and (max-width: 640px) {
    #imageMatch2 {
      display: flex;
    }
    #imageMatch3 {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 10rem;
    height: 10rem;
  }

  @media only screen and (max-width: 353px) {
    #imageMatch1 {
      display: flex;
    }
    #imageMatch2 {
      display: none;
    }
  }
`;

export const MatchIcon = styled.img`
  position: absolute;
  right: 4%;
  height: 32%;
`;

export const ContentImage = styled.div`
  width: 18rem;
  height: 22.875rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 860px) {
    width: 13.5rem;
    height: 12.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 10.5rem;
    height: 10rem;
  }
`;

export const Space = styled.div`
  width: 1.25rem;
  height: 21.875rem;

  @media only screen and (max-width: 860px) {
    width: 0.5rem;
    height: 12.5rem;
  }

  @media only screen and (max-width: 500px) {
    width: 0.5rem;
    height: 10rem;
  }
`;
